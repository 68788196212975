import axios, { AxiosInstance } from "axios";
import { Auth } from "aws-amplify";
import { logout } from "containers/Auth/authSlice";
import { store } from "store";

export class BaseService {
  private baseUrl;
  private withToken;
  public instance: AxiosInstance;

  constructor(baseUrl?: string, withToken: boolean = true) {
    this.baseUrl = baseUrl;
    this.withToken = withToken;

    this.instance = axios.create({
      timeout: 30000,
      timeoutErrorMessage: "Request Timeout",
      baseURL: this.baseUrl,
    });

    const isProd = process.env.NODE_ENV !== "development";

    this.instance.interceptors.request.use(
      async (config: any) => {
        if (config.url.includes('get-for-signup') || config.url === "/admin/register") {
            return config;
        }

        let token: string | null
        try {
          let session = await Auth.currentSession()
          token = session.getAccessToken().getJwtToken()
        } catch (error) {
          const searchParams = new URLSearchParams(window.location.search);
          token = searchParams.get("token");

          if (!token) {
            console.error(error)
            store.dispatch(logout());
            return Promise.reject(error);
          }
        }

        if (token && this.withToken) {
          config.headers.common["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        if (!isProd) {
          console.log(error);
        }
        return Promise.reject(error);
      }
    );

    this.instance.interceptors.response.use(
      (response) => {
        if (!isProd) {
          console.log("SUC Resp: ", response.data);
        }

        return response;
      },
      (error) => {
        if (error.response) {
          if (error.response?.status === 401) {
            console.log(`Error: 401`)
            store.dispatch(logout());
          }

          if (!isProd) {
            console.log("ERR Resp: ", error.response);
          }
          return Promise.reject(error.response);
        }

        if (!isProd) {
          console.log("Err: ", error);
        }

        return Promise.reject(error);
      }
    );
  }
}
