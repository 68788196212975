import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Editor from "@draft-js-plugins/editor";
import { convertToRaw, EditorState } from "draft-js";
import createMentionPlugin, {
  defaultSuggestionsFilter,
  MentionData,
} from "@draft-js-plugins/mention";
import "@draft-js-plugins/mention/lib/plugin.css";
import styled from "styled-components";
import {Edit2, Send2} from "iconsax-react";
import SpaceView from "components/Space";
import { ContentSection8 } from "../styled";
import {Button} from "antd";

const EditorWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 7px 20px;
  border-radius: 2px;
  margin-bottom: 2em;
  background: #fefefe;
  border-radius: 16px;
  height: 80px;

  & .public-DraftEditorPlaceholder-root {
    position: absolute;
    color: #727c8a;
  }

  & .public-DraftEditor-content {
    height: 66px;
    padding-right: 33px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const IconWrapper = styled.span`
  position: absolute;
  right: 24px;
  top: 33%;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

const ButtonSubmit = styled(Button)`
  position: absolute;
  right: 24px;
  top: 33%;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

type Props = {
  onSubmit?: (name: string) => void;
  mentions: MentionData[];
};

export default function SimpleMentionEditor({
  onSubmit,
  mentions,
}: Props): ReactElement {
  const [isOpenEditor, setIsOpenEditor] = useState<boolean>(false);
  const ref = useRef<Editor>(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(mentions);
  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin();
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(false);

  useEffect(() => {
    setSuggestions(mentions);
  }, [mentions]);
  // const handleFocus = () => {
  //   const placeholder: any = document.querySelector("#placeholder-editor");
  //   if (placeholder) placeholder.style.display = "none";
  // };

  const handleBlur = () => {
    const placeholder: any = document.querySelector("#placeholder-editor");
    if (placeholder) placeholder.style.display = "unset";
  };

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(({ value }: { value: string }) => {
    setSuggestions(defaultSuggestionsFilter(value, mentions));
  }, [mentions]);

  const onSend = async () => {
    setDisabledSubmit(true);
    setTimeout(() => {
      setDisabledSubmit(false);
    }, 500);
    const contentState = editorState.getCurrentContent();
    const raw = convertToRaw(contentState);

    const content = raw.blocks.map(block => {
      let {entityRanges, text} = block; // get conten end ranges mention
      let contentDraft = text;
      entityRanges.forEach((item: { offset: number; length: number }, index: number) => {
        const mentionText = text.slice(item.offset, item.offset + item.length);
        contentDraft = contentDraft.substring(0, item.offset + index * 8) + `<a>@${mentionText}</a>` + contentDraft.substring(item.offset + index * 8 + item.length, contentDraft.length);
      });
      return contentDraft;
    }).join("\n");
    
    if (onSubmit) {
      if (!content.trim()) return;
      await onSubmit(content);
      setEditorState(EditorState.createEmpty());
    }
  };

  return (
    <>
      {!isOpenEditor ? (
        <div onClick={() => setIsOpenEditor(true)}>
          <ContentSection8 color="#00C1D5">Add comment</ContentSection8>
        </div>
      ) : (
        <>
          <SpaceView size={24} />
          <EditorWrapper
            onClick={() => {
              ref.current!.focus();
            }}
          >
            <Editor
              placeholder="Comment"
              editorKey={"editor"}
              // onFocus={handleFocus}
              onBlur={handleBlur}
              editorState={editorState}
              onChange={setEditorState}
              plugins={plugins}
              ref={ref}
            />
            <MentionSuggestions
              open={open}
              onOpenChange={onOpenChange}
              suggestions={suggestions}
              onSearchChange={onSearchChange}
              onAddMention={() => {
                // get the mention object selected
              }}
            />
            <ButtonSubmit
              className="btn-edit"
              type="link"
              disabled={disabledSubmit}
              style={{padding: 0}}
              onClick={() => onSend()}
            >
              <Send2
                size="32"
                color="#00C1D5"
                variant="Bold"
              />
            </ButtonSubmit>
          </EditorWrapper>
        </>
      )}
    </>
  );
}
