import { Button, Modal, ModalProps, Switch, Table, Drawer } from "antd";
import { CustomModalProps } from "components/Common/Modal";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  background-color: #edfbfc;
  color: #03B4CE;
  border-color: #03B4CE;

  &:hover {
    background-color: #cbd3d4;
  }

  span:nth-child(2) {
    margin-left: 10px;
  }

  &.ant-btn-text {
    border: 0;
  }

  &.ant-btn-default {
    background: #fff;

    &:hover {
      background: #edfbfc;
    }
  }

  &.ant-btn-primary {
    background: linear-gradient(90deg, #55bed2 0%, #70eaf0 100%);
    color: #fff;
    border: 0;

    &:hover {
      background: linear-gradient(90deg, #4194a3 0%, #5dc4c9 100%);
    }

    &[disabled] {
      border-color: #d9d9d9;
      background: #ccc;
    }
  }
  &.assigned {
    background: #BDC3CB !important;
  }
`;

export const StyledTable = styled(Table)`
  .ant-table {
    background: transparent;
  }

  .ant-table-row:nth-child(2n + 1) {
    background: #fff;
  }

  .ant-table-thead > tr > th {
    background: transparent;
  }
`;

export const StyledModal = styled(Modal)<CustomModalProps & ModalProps>`
  .ant-modal-title {
    text-align: center;
    font-size: 24px;
  }

  .ant-modal-close {
    top: -35px;
    right: 0;

    .ant-modal-close-x {
      width: 32px;
      height: 32px;
      color: #fff;

      &:hover {
        color: #00c1d5;
      }
    }
  }
`;

export const StyledSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: #edfbfc;

    .ant-switch-handle::before {
      background-color: #00c1d5;
    }
  }
`;

export const StyledDrawer = styled(Drawer)`
  .ant-form-item-label {
    font-size: 18px;
    font-weight: 600;
  }
  .btn-filter-cancel {
    background-color: #fff;
    border-radius: 16px;
    &:hover {
      color: #24d6e3;
      border-color: #24d6e3;
    }
  }

  .btn-filter-apply {
    background-color: #00c1d5;
    border-radius: 16px;
    &:hover {
      background-color: #24d6e3;
      border-color: #24d6e3;
      color: #fff;
    }
  }
`;
export const StyledScroll = styled.div`
  overflow-x: auto;
  .list-content {
    min-width: 2000px;
  }
`;