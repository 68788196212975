import { Avatar, Button, Col, Modal, ModalProps, Popconfirm, Row, Tooltip } from "antd";
import { DeleteIcon } from "components/icons/deleteIcon";
import SpaceView from "components/Space";
import React, { useEffect, useRef, useState } from "react";
import DAService from "services/DAService";
import { getNameFromLink, formatDate } from "utils/helper";
import {
  Center,
  ContentSection1,
  ContentSection2,
  ContentSection4,
  ContentSection8,
} from "../styled";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { selectUserSelector } from "containers/Auth/authSlice";
import { COMMENT_MODE, MAP_COLOR_ROLE_COMMENT, Role } from "../../../constants";
import { DocumentIcon } from "components/icons/documentIcon";
import UploadFile from "components/UploadFile";
import CoreService from "services/CoreService";
import DocumentFileComment from "./DocumentFileComment";
import { v4 as uuidv4 } from "uuid";
import { MAX_SIZE_UPLOAD } from "constants/epac.constans";
const styleNameRole = {
  margin: "2px 6px",
  display: "inline-block",
  width: "6px",
  height: "6px",
  borderRadius: "50%",
  background: "rgba(0, 0, 0, 0.45)",
};

const styles = {
  iconWrapper: {
    marginLeft: "8px",
    width: "24px",
    height: "24px",
    borderRadius: "8px",
    backgroundColor: "#F3F7FC",
    cursor: "pointer",
  },
};

type Props = {
  content?: string;
  username?: string;
  time?: string;
  id: string;
  callback?: () => void;
  comment: any;
  isDeleteComment?: boolean;
  form_id: string;
  className?: string;
};

const searchParams = new URLSearchParams(window.location.search);
const forPrint = !!searchParams.get("token");

export const CommentItem = ({
  content = "",
  username = "",
  id,
  callback,
  time,
  comment,
  isDeleteComment = true,
  form_id,
  className
}: Props) => {
  const [isViewMore, setIsViewMore] = useState<boolean>(!forPrint);
  const [height, setHeight] = useState<number | "unset">();
  const caption = useRef<HTMLElement>(null);
  const user = useSelector(selectUserSelector);
  const [isOpenDocument, setIsOpenDocument] = useState<boolean>(false);

  const __html = content.replaceAll("\n", "</br>");
  const body = <ContentSection1
    ref={caption}
    style={{
      display: "inline-block",
      overflow: "hidden",
      height,
    }}
    dangerouslySetInnerHTML={{ __html }}
  />

  useEffect(() => {
    if (forPrint) return;
    if (caption.current?.clientHeight && caption.current?.clientHeight >= 48) {
      setIsViewMore(true);
      setHeight(48);
    }
    return () => {
      setIsViewMore(false);
      setHeight("unset");
    };
  }, [content, caption.current, forPrint]);

  const handleClickViewMore = () => {
    setIsViewMore(!isViewMore);
    setHeight("unset");
  };

  const handleDelete = async () => {
    await DAService.assessor.deleteComment(id);
    if (callback) callback();
  };

  const handleOpenDocument = () => {
    setIsOpenDocument(true);
  };

  const handleCloseDocument = (value: boolean) => {

    setIsOpenDocument(value);
  };

  const handleUploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append("files", file);
    const { data } = await CoreService.upload(formData, {
      folder: `sessionB/Comment/${comment?.id}`,
    });
    return data.data;
  };

  const handlePushFiles = async (files: Array<any>) => {
    if (!comment.id || !form_id || !files.length) return;
    const filesUrl = files.map((file) => ({
      id: uuidv4(),
      url: file.response.url[0],
      type: file.type,
    }));
    const dataSubmit = {
      documents: filesUrl || [],
    };
    await DAService.assessor.sendDocumentComment(
      form_id,
      comment.id,
      dataSubmit
    );
    if (callback) callback();
  };
  const maxSizeUpload = MAX_SIZE_UPLOAD * 1024;
  let _comment:any = { ...comment, role:comment.role === Role.CUSTOMER_SERVICE ? "Customer Service":comment.role === Role.PROVIDER_ADMIN? "Provider Admin":comment.role }
  return (
    <>
      <Center style={{ justifyContent: "space-between" }} className={className}>
        <Center style={{ justifyContent: "start" }}>
          <ContentSection2 style={{ marginLeft: "16px", width: "unset" }}>
            <span>{username}</span>
            {_comment?.role && (
              <span
                style={{
                  ...styleNameRole,
                  backgroundColor: `${
                    MAP_COLOR_ROLE_COMMENT[
                      _comment?.role?.toLowerCase() || "assessor"
                    ]
                  }`,
                }}
              ></span>
            )}
            <span
              style={{
                color: `${
                  MAP_COLOR_ROLE_COMMENT[
                    _comment?.role?.toLowerCase() || "assessor"
                  ]
                }`,
                textTransform: "capitalize",
              }}
            >
              {_comment?.role ? _comment?.role : ""}
            </span>
            {_comment?.doctor_name && (
              <span
                style={{
                  ...styleNameRole,
                  backgroundColor: `${
                    MAP_COLOR_ROLE_COMMENT[
                      _comment?.doctor_name?.toLowerCase() || "doctor"
                    ]
                  }`,
                }}
              ></span>
            )}
              <span>{_comment?.doctor_name}</span>
          </ContentSection2>

          <Center
            style={{ ...styles.iconWrapper, position: "relative" }}
            onClick={handleOpenDocument}
          >
            <DocumentIcon />
            {comment?.documents?.length && (
              <Center
                style={{
                  position: "absolute",
                  left: "14px",
                  top: "-8px",
                  width: 25,
                  height: 15,
                  background: "#FE8833",
                  borderRadius: "16px",
                }}
              >
                <span
                  style={{
                    fontSize: "10px",
                    fontWeight: "500",
                    color: "#ffffff",
                  }}
                >
                  {comment?.documents?.length}
                </span>
              </Center>
            )}
          </Center>

          {isOpenDocument && (<UploadFile
              maximum={maxSizeUpload}
              style={{ height: "40px", width: "150px" }}
              multiple
              callback={callback}
              formId={form_id}
              user={user}
              comment={comment}
              isDeleteComment={isDeleteComment}
              handleUpload={handleUploadFile}
              accept={[".jpeg", ".pdf", ".png", ".jpg"]}
              onDone={handlePushFiles}
              closePopUp={handleCloseDocument}
            />)
          }
        </Center>
        <Center style={{ justifyContent: "start" }}>
          <ContentSection4>
            {time && formatDate(time, "DD-MMM-YYYY HH:mm:ss", "-")}
          </ContentSection4>
          {isDeleteComment &&
            user?.role?.toLocaleLowerCase() ===
              comment?.role?.toLocaleLowerCase() && (
              <StyledCenter
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "8px",
                  backgroundColor: "#F3F7FC",
                  marginLeft: "24px",
                }}
              >
                <Popconfirm title="Are you sure?" onConfirm={handleDelete}>
                  <Button
                    style={{
                      width: 14,
                      height: 18,
                      backgroundColor: "transparent",
                      border: "none",
                      bottom: "10px",
                      right: "4px",
                      cursor: "pointer",
                    }}
                    icon={<DeleteIcon />}
                  ></Button>
                </Popconfirm>
              </StyledCenter>
            )}
        </Center>
      </Center>
      <SpaceView size={8} />
      <div style={{ paddingLeft: "48px", paddingRight: "48px" }}>
        { (comment?.mode == COMMENT_MODE.DRAFT)
          ? <Tooltip
            title="This comment will only be visible to clinic after the case is re-assigned to them"
          >
            <span style={{
              display: 'inline-block',
              verticalAlign: 'top',
              margin: '5px 0 0 -36px',
              fontSize: '10px',
              background: 'rgb(243, 248, 252)',
              borderRadius: '50vh',
              padding: '0 0.5em',
            }}>draft</span>            
            {body}   
          </Tooltip>
          : body
        }
        {isViewMore && (
          <>
            <SpaceView size={8} />
            <div onClick={handleClickViewMore}>
              <ContentSection8 color="#101820">View all</ContentSection8>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const StyledCenter = styled(Center)`
  &:hover {
    background-color: #ffeeee !important;
    svg {
      &:hover {
        color: #f04646 !important;
      }
    }
  }
`;
