import React from "react";
import styled from "styled-components";

interface IProps {
  type: "active" | "inactive" | "info" | "warning" | "red" | "tur" | "pink" | "dark-pink" | "mintt" | "purple" | "yellow" | "blue" | "orange" | "green";
  children: React.ReactNode;
}

const getColor = (type: string) => {
  switch (type) {
    case "active":
      return {
        color: "#7EC820",
        backgroundColor: "#EFF8E5",
      };
    case "inactive":
      return {
        color: "#F04646",
        backgroundColor: "#FFEEEE",
      };
    case "info":
      return {
        color: "#2AC3FF",
        backgroundColor: "#E4F3FC",
      };
    case "warning":
      return {
        color: "#FE8833",
        backgroundColor: "#FFF0E6",
      };
    case "green":
      return {
        color: "#7EC820",
        backgroundColor: "#EFF8E5",
      };
    case "orange":
      return {
        color: "#FE6B00",
        backgroundColor: "#FFF0E6",
      };
    case "blue":
      return {
        color: "#01A2FF",
        backgroundColor: "#F3F8FC",
      };
    case "yellow":
      return {
        color: "#F0AD02",
        backgroundColor: "#FFF8E6",
      };
    case "purple":
      return {
        color: "#6A57FC",
        backgroundColor: "#F5F4FF",
      };
    case "mintt":
      return {
        color: "#009B95",
        backgroundColor: "#EBFAF9",
      };
    case "pink":
      return {
        color: "#F3007D",
        backgroundColor: "#FFEDFA",
      };
    case "dark-pink":
      return {
        color: "#9B0080",
        backgroundColor: "#FFE5FB",
      };
    case "tur":
      return {
        color: "#03B4CE",
        backgroundColor: "#EDFBFC",
      };
    case "red":
      return {
        color: "#F04646",
        backgroundColor: "#FFEEEE",
      };
    default:
      return {};
  }
};

const Status = ({ type, children }: IProps) => {
  return <StyledStatus type={type}>{children}</StyledStatus>;
};

const StyledStatus = styled.span<{
  type: "active" | "inactive" | "info" | "warning" | "red" | "tur" | "pink" | "dark-pink" | "mintt" | "purple" | "yellow" | "blue" | "orange" | "green";
}>`
  color: ${(prop) => getColor(prop.type)?.color};
  background-color: ${(prop) => getColor(prop.type)?.backgroundColor};
  padding: 4px 8px;
  border-radius: 15px;
  width: 100%;
`;

export default Status;
