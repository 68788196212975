import jwt_decode from "jwt-decode";
export default function jwtDecoded(token) {
    return new Promise((resolve, reject) => {
        try {
            var decoded = jwt_decode(token);
            localStorage.setItem(`token`, token)
            resolve(decoded)
        } catch {
            reject(`Error: Token is invalid`)
        }
    })
}