import React from "react";

type Props = {
  type: any
}

export const DotIcon = (props: Props) => {
  
  const getColor = (type: string) => {
    switch (type) {
      case "Master":
        return {
          color: "#62AC03",
        };
      case "Doctor":
        return {
          color: "#FE6B00",
        };
      case "Assessor":
        return {
          color: "#01A2FF",
        };
      case "Patient":
        return {
          color: "#6A57FC",
        };
      case "Supervisor":
        return {
          color: "#F0AD02",
        };
      default:
        return {
          color: "#727C8A",
        };
    }
  };
  
  const color:any = () => getColor(props.type).color;
  return (
    <svg className={'inline-block'} fill={color()} xmlns="http://www.w3.org/2000/svg" version="1.1" width="12" height="12">
      <circle r="4" cx="6" cy="6" />
    </svg>
  );
};


