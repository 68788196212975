import { Button, Select, Tag } from "antd";
import { ArrowDown2, ArrowLeft, ArrowRight } from "iconsax-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

export interface PaginationConfig {
  limit: number;
  current: number;
  previousToken?: string | null;
  nextToken: string | null;
  total: number | 0;
  data?: [];
}

interface IProps {
  pagination: PaginationConfig;
  isHideSelectPage?: Boolean;
  onChange?: (page: number, data?: []) => void;
}

const Pagination = ({
  pagination,
  onChange,
  isHideSelectPage = false,
}: IProps) => {
  const [total, setTotal] = useState(1);
  const [listPage, setListPage] = useState([1]);
  const [dataObj, setDataObj] = useState<any>({});

  useEffect(() => {
    dataObj[pagination?.current] = pagination?.data || [];
    setDataObj({ ...dataObj });
  }, [pagination?.data]);

  useEffect(() => {
    let limit = pagination.limit;
    let total = pagination.total;
    if (total) {
      let n = !(total % limit)
        ? total / limit
        : parseInt((total / limit).toString()) + 1;
      setListPage(new Array(n).fill(null).map((_, i) => i + 1));
    } else {
      setListPage(new Array(1).fill(null).map((_, i) => i + 1));
    }
  }, [pagination?.total]);

  const handleChange = (next: number) => {
    let nextPage = pagination?.current + next;

    if (nextPage < 1) nextPage = 1;
    if (next > 0 && pagination.nextToken) setTotal(total + 1);

    onChange && onChange(nextPage, dataObj[nextPage] || []);
  };

  const handleSelect = (nextPage: any) => {
    onChange && onChange(nextPage, dataObj[nextPage] || []);
  };

  return (
    <StyledPagination className="flex items-center justify-center">
      {!isHideSelectPage ? (
        <div className="flex items-center justify-center">
          <p>The page you’re on</p>
          <StyledSelect
            value={pagination?.current}
            suffixIcon={<ArrowDown2 />}
            onSelect={handleSelect}
          >
            {listPage.map((item: number) => (
              <Select.Option value={item} key={item}>
                {item}
              </Select.Option>
            ))}
          </StyledSelect>
        </div>
      ) : (
        <Tag
          style={{
            fontSize: "15px",
            padding: "10px",
            border: "none",
          }}
        >
          You’re on page {"  "}
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {pagination?.current}
          </span>
        </Tag>
      )}
      <StyledButton
        type="primary"
        disabled={pagination?.current === 1}
        icon={<ArrowLeft />}
        onClick={() => handleChange(-1)}
      />
      <StyledButton
        type="primary"
        disabled={
          pagination?.total
            ? pagination?.current === listPage.length
            : !pagination?.nextToken
        }
        icon={<ArrowRight />}
        onClick={() => handleChange(1)}
      />
    </StyledPagination>
  );
};

const StyledPagination = styled.div`
  margin: 5px 0;
  p {
    margin: 0;
    margin-right: 10px;
  }
`;

const StyledSelect = styled(Select)`
  margin-right: 24px;

  &:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-selector {
    margin-right: 20px;
    border-radius: 16px;
    border: 0;
    background-color: #f3f7fc;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border-radius: 16px;
  border: 0;

  &.ant-btn-default {
    background-color: #f3f7fc;
  }

  &.ant-btn-primary[disabled] {
    border-color: #f3f7fc;
    background: #f3f7fc;
  }
`;

export default Pagination;