import { Button, Select, Space } from "antd";
import { ArrowDown2, ArrowLeft, ArrowRight } from "iconsax-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

export interface PaginationConfig {
  limit: number;
  total: number | 0;
  page: number;
  reset?: any;
}

interface IProps {
  pagination: PaginationConfig;
  onChange?: (page: number, data?: []) => void;
}

const PaginationOpenSearch = ({ pagination, onChange }: IProps) => {
  const [listPage, setListPage] = useState([1]);
  const [lastPage, setLastPage] = useState(1);

  useEffect(() => {
    let limit = pagination.limit;
    let total = pagination.total;
    if (total) {
      let n = !(total % limit)
        ? total / limit
        : parseInt((total / limit).toString()) + 1;
      setListPage(new Array(n).fill(null).map((_, i) => i + 1));
    } else {
      setListPage(new Array(1).fill(null).map((_, i) => i + 1));
    }

    setLastPage(Math.ceil(total / limit));
  }, [pagination?.total]);

  const handleChange = (next: number) => {
    onChange && onChange(pagination.page + next);
  };

  const handleSelect = (nextPage: any) => {
    onChange && onChange(nextPage);
  };

  return (
    <StyledPagination className="flex items-center justify-center">
      <p>The page you’re on</p>
      <StyledSelect
        value={pagination?.page}
        suffixIcon={<ArrowDown2 />}
        onSelect={handleSelect}
      >
        {listPage.map((item: number) => (
          <Select.Option value={item} key={item}>
            {item}
          </Select.Option>
        ))}
      </StyledSelect>
      <StyledButton
        type="primary"
        disabled={pagination?.page === 1}
        icon={<ArrowLeft />}
        onClick={() => handleChange(-1)}
      />
      <StyledButton
        type="primary"
        icon={<ArrowRight />}
        disabled={pagination?.page >= lastPage}
        onClick={() => handleChange(1)}
      />
    </StyledPagination>
  );
};

const StyledPagination = styled.div`
  margin: 5px 0;
  p {
    margin: 0;
    margin-right: 10px;
  }
`;

const StyledSelect = styled(Select)`
  margin-right: 24px;

  &:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-selector {
    margin-right: 20px;
    border-radius: 16px;
    border: 0;
    background-color: #f3f7fc;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border-radius: 16px;
  border: 0;

  &.ant-btn-default {
    background-color: #f3f7fc;
  }

  &.ant-btn-primary[disabled] {
    border-color: #f3f7fc;
    background: #f3f7fc;
  }
`;

export default PaginationOpenSearch;
