import { Col, notification, Row } from "antd";
import SpaceView from "components/Space";
import "./tablestyles.css"
import React, { useEffect, useState } from "react";
import {
  Center,
  ContentSection1,
  ContentSection2,
  ContentSection5,
  LabelSection1,
  UploadButton,
} from "../../styled";
import fileIcon from "assets/images/file.png";
import DAService from "services/DAService";
import { useLocation, useParams } from "react-router-dom";
import InfoFileItem from "./InfoFileItem";
import CoreService from "services/CoreService";
import UploadFile from "components/UploadFile";
import { Role } from "../../../../constants";
import { useSelector } from "react-redux";
import { selectUserSelector } from "containers/Auth/authSlice";
import { usePermissionClaim } from "hooks/usePermissionClaim";
import { MAX_SIZE_UPLOAD } from "constants/epac.constans";
type Props = {
  claimStatus: any;
  approvedName: string;
  assessorName: string;
  setClaimStatus: any;
  role?: Role;
  dataCase?: any;
  isHistory?: boolean;
};

const Document = ({ assessorName, approvedName, claimStatus, setClaimStatus, role, dataCase, isHistory = false }: Props) => {
  const [files, setFiles] = useState([]);
  const { id } = useParams();
  const search = useLocation().search;
  const version_id = new URLSearchParams(search).get("versionId")?.toString() ?? '';
  const user = useSelector(selectUserSelector);
  const { isBlockEdit } = usePermissionClaim({
    claimStatus: claimStatus,
    user: user,
    isHistory
  });
  const [isOpenDocument, setIsOpenDocument] = useState<boolean>(false);

  const handleCloseDocument = (value: boolean) => {
    setIsOpenDocument(value);
  };

  const handleOpenDocument = () => {
    setIsOpenDocument(true);
  };

  const getNameFromLink = (link: string): string => {
    if (!link || typeof link !== "string") return "";
    const splitPath =
      decodeURIComponent(link)?.split("/")[link?.split("/")?.length - 1];
    const nameFiles = splitPath?.split("_");
    nameFiles?.shift();
    const nameFile = nameFiles?.join("_");
    return nameFile;
  };

  useEffect(() => {
    handleCallAPI();
  }, []);

  const handlePushFiles = async (files: Array<any>) => {
    if (!id || !files.length) return;
    const filesUrl = files.map((file) => file.response.url[0]);
    const dataSubmit = {
      case_id: id,
      files: filesUrl || [],
    };
    await DAService.assessor.postFiles(id, dataSubmit);
    handleCallAPI();
  };

  const handleUploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append("files", file);
    const { data } = await CoreService.upload(formData, {
      folder: "sessionB",
    });
    return data.data;
  };

  const handleCallAPI = async () => {
    try {
      if (!id) return;
      // if(isHistory && !version_id) return;
      const response = /*!isHistory ?*/ await DAService.assessor.getListFilesUpload(id) //: await DAService.historyPAC.getHistoryCaseListFiles(version_id, id);
      const filesCurrent = response?.data?.data?.sort(
        (a: any, b: any) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      );
      setFiles(filesCurrent);
    } catch (error) {
      console.log(error);
    }
  };

  const maxSizeUpload = MAX_SIZE_UPLOAD * 1024;
  
  return (
    <div><Row justify="space-between">
      <SpaceView size={36} />
    </Row>
      <SpaceView size={36} />
      <Row>
        <LabelSection1>
          Upfront docs (PARF, MIF, Checklist + Diag report)
        </LabelSection1>
        <SpaceView size={6} />
        <span
          style={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#828282",
            lineHeight: "20px",
          }}
        >
          *New MIF, New checklist
        </span>
        <SpaceView size={6} />
        <span
          style={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#828282",
            lineHeight: "20px",
          }}
        >
          {`* Diag report -> Only if clinic ticked "Yes" in new checklist`}{" "}
        </span>
      </Row>
      <SpaceView size={20} />
      {!isBlockEdit && isOpenDocument && (
        <Row>
          <UploadFile
            isCloseAfterUpload={true}
            maximum={maxSizeUpload}
            multiple
            handleUpload={handleUploadFile}
            accept={[".jpeg", ".pnj", ".pdf", ".png", ".jpg"]}
            onDone={handlePushFiles}
            closePopUp={handleCloseDocument}
          />
        </Row>
      )}
      {!isBlockEdit && (
        <UploadButton
          type="primary"
          icon={<img src={fileIcon} alt="" />}
          style={{ marginRight: "12px", height: 66 }}
          onClick={handleOpenDocument}>Document</UploadButton>
      )}
      <SpaceView size={24} />
      <table style={{ margin: "auto" }} className="flat-table flat-table-1">
        <thead className="head-line">
          <tr style={{ borderRadius: 25, border: "none" }}>
            <th style={{ color: "#6e6e6e", fontSize:"15px", width:"40%" }}className="th-name"><span className="table-head-text">Name</span></th>
            <th style={{ color: "#6e6e6e", fontSize:"15px", width:"30%" }}className="th-date"><span className="table-head-text">Recd date</span></th>
            <th style={{ color: "#6e6e6e", fontSize:"15px", width:"30%" }}className="th-upload"><span className="table-head-text">Uploaded by</span></th>
          </tr>
        </thead>
        <tbody>
          {files?.map((item: any, index: number) => {
            return <tr key={index}>
              <td style={{ color: "#6e6e6e", margin: "auto", fontSize:"17px", textAlign:"center" }}>{getNameFromLink(item?.file_name)}</td>
              <td style={{ color: "#6e6e6e", margin: "auto", fontSize:"17px", textAlign:"center" }}>{item?.updatedAt}</td>
              <td style={{ color: "#6e6e6e", margin: "auto", fontSize:"17px", textAlign:"center" }}>{item?.uploaded_by}</td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Document;
