import {
  DataRecomendationOptionDocument,
  RECOMMENDATION_OPTIONS_DEFAULT_CONTENT_DOCUMENT,
} from "../../constants";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import DAService from "services/DAService";
import moment from "moment";
import "./style.css"
type Props = {};

const PdfOptionNo = (props: Props) => {
  const { id } = useParams();
  const [data, setData] = useState<DataRecomendationOptionDocument>({
    amount: 0,
    patient_name: "",
    policy_no: "",
    hospital_name: "",
    date_of_admission: "",
    doctor_name: "",
    assessor_name: "",
    tosp: [],
    diagnoses: [],
    symptoms: [],
  });
  const [html, setHtml] = useState("");
  const search = useLocation().search;
  const type = new URLSearchParams(search).get("type");
  const default_no = new URLSearchParams(search).get("default_no");
  const target: "patient" | "clinic" = new URLSearchParams(search).get(
    "target"
  ) as "patient" | "clinic";
  const content: string | undefined =
    RECOMMENDATION_OPTIONS_DEFAULT_CONTENT_DOCUMENT(data).find(
      (item: any) => item.value === type
    )?.document[target || "patient"].content;

  useEffect(() => {
    const root: any = document?.querySelector("#root");
    if (root) {
      root.style.background = "#fff";
      root.style.overflowY = "hidden";
    }
    if (!id) return;
    (async () => {
      const data = await DAService.assessor.getDataFileNonClinic(
        id,
        parseInt(default_no ?? "5")
      );
      const originData = {
        ...(data?.data?.data ?? {}),
      };
      const { formA } = originData;
      setHtml(originData?.html);
      setData({
        amount: originData?.final_bill_size ?? originData?.total_bill_size ?? 0,
        patient_name: originData?.patient_name,
        policy_no:
          originData?.gsh_info_policy_no ?? originData?.gtc_info_policy_no,
        hospital_name: formA.hospital_name,
        date_of_admission: formA.date_of_admission,
        doctor_name: formA.doctor_name,
        assessor_name: originData?.assessor_name ?? "",
        tosp: originData?.professional_fees
          ?.filter((it: any) => !it?.discarded)
          ?.map((it: any) => ({
            id: it?.tosp_id,
            value: it.tosp_code + " - " + (it?.tosp_description ?? ""),
          })),
        diagnoses: formA.diagnoses.map((item: any) => ({
          name: item.name,
          icd_10: item.icd_10,
        })),

        symptoms: handleInitSymtomTospTemplateEmail(formA),
      });
    })();
  }, [id]);
  const handleInitSymtomTospTemplateEmail = (dataFormA: any) => {
    if (dataFormA) {
      const symptoms = dataFormA?.symptoms?.map((item: any) => {
        const startDate = item?.date_of_first_consultation
          ? moment(item?.date_of_first_consultation || "", "DD-MMM-YYYY")
          : moment();
        const endDate = item?.date_of_onset_or_duration_symptom
          ? moment(item?.date_of_onset_or_duration_symptom || "", "DD-MMM-YYYY")
          : moment();
        const duration = moment.duration(
          Math.abs(
            startDate.endOf("days").toDate().getTime() -
              endDate.startOf("days").toDate().getTime()
          ) + 1,
          "milliseconds"
        );
        const dateRange = [
          {
            value: Math.round(duration.years()),
            name: Math.round(duration.years()) > 1 ? "Years" : "Year",
          },
          {
            value: Math.round(duration.months()),
            name: Math.round(duration.months()) > 1 ? "Months" : "Month",
          },
          {
            value: duration.days(),
            name: duration.days() > 1 ? "Days" : "Day",
          },
        ]
          .map((item) => `${item.value} ${item.name}`)
          .join(" ");

        return {
          ...item,
          duration: dateRange,
        };
      });
      return symptoms;
    }
  };

  return (
    <div
      style={{
        background: "#fff",
        width: 800,
        padding: "80px 78px 0 78px",
        margin: "0 auto",
      }}
      dangerouslySetInnerHTML={{
        __html: (html || content || "")?.replaceAll('"', '"'),
      }}
    />
  );
};
export default PdfOptionNo;
