export const AnnouncementIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                opacity="0.4"
                d="M9.63073 15.8656V20.0504C9.63073 20.575 9.20656 21.0004 8.68336 21.0004H7.26231C6.73911 21.0004 6.31494 20.575 6.31494 20.0504V15.7754H9.42711L9.63073 15.8656Z"
                fill="#727c8a"
            />
            <path
                opacity="0.4"
                d="M8.68323 14.8248H5.36744C4.05932 14.8248 2.99902 13.7616 2.99902 12.4498V8.1748C2.99902 6.86304 4.05932 5.7998 5.36744 5.7998H8.68323V14.8248Z"
                fill="#727c8a"
            />
            <path
                opacity="0.6"
                d="M17.6812 2.20165L9.63037 5.79999V14.825L17.6812 18.4233C19.246 19.1096 20.9988 17.96 20.9988 16.2475V4.37753C20.9988 2.66499 19.246 1.51537 17.6812 2.20165Z"
                fill="#727c8a"
            />
        </svg>
    );
};
