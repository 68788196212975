/* eslint-disable react-hooks/exhaustive-deps */
import { SearchOutlined } from '@ant-design/icons';
import { Input, notification, Space } from 'antd';
import Pagination, { PaginationConfig } from 'components/Common/Pagination';
import Layout from 'components/Layout';
import Loading from 'components/Loading';
import AllocationItem from 'components/Allocation/AllocationItem';
import withAuth from 'helpers/withAuth';
import { AddCircle } from 'iconsax-react';
import { useEffect, useState } from 'react';
import DAService from 'services/DAService';
import { StyledButton } from 'styles/styled';
import { PAGINATION, ROLE_PAGE } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUserSelector } from 'containers/Auth/authSlice';

const Allocation = () => {
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [list, setList] = useState([]);
    const [tosp, setTosp] = useState([]);
    const navigate = useNavigate();
    const [pagination, setPagination] = useState<PaginationConfig>({
        limit: PAGINATION.LIMIT_100,
        current: 1,
        total: 0,
        nextToken: null,
        data: [],
    });
    const user = useSelector(selectUserSelector);

    const role: any = user?.role;
    if (role && !ROLE_PAGE.ALLOCATION.includes(role)) {
        navigate(-1);
    }

    useEffect(() => {
        fetchTOSP();
    }, []);

    useEffect(() => {
        fetchAllocations();
    }, []);

    const fetchAllocations = async (isSearching = false, pageNumber = pagination.current) => {
        setLoading(true);
        try {
            const nextToken = isSearching ? null : pagination.nextToken;
            const { data } = await DAService.allocation.getList(keyword, pagination.limit, nextToken);
            setList(data?.data?.items.length ? data?.data?.items : []);
            setPagination({
                ...pagination,
                current: pageNumber,
                nextToken: data?.data?.nextToken ?? null,
                data: data?.data?.items || [],
            });
        } catch (error: any) {
            notification.error({
                message: 'Error',
                description: error.data
                    ? Array.isArray(error.data.message)
                        ? error.data.message[0]
                        : error.data.message
                    : error.message,
            });
        } finally {
            setLoading(false);
        }
    };

    const fetchTOSP = async () => {
        try {
            const { data } = await DAService.tosp.getListWithoutPagination('', 5);
            setTosp(data?.data?.items);
        } catch (error) {
            console.log(error);
        }
    };

    const handleCreateAllocation = () => {
        navigate('/allocations/create');
    };

    const handleDetailAllocation = (item: any) => {
        navigate(`/allocations/detail/${item?.id}`);
    };

    const onPageChange = (page: number, data: any = []) => {
        fetchAllocations(false, page);
    };

    const handleSearch = () => {
        fetchAllocations(true, 1);
    };

    return (
        <Layout title="Allocation">
            {loading ? <Loading /> : null}
            <div className="flex justify-between mb-10">
                {user?.permission?.allocation_execute && (
                    <Space size={10} align="center">
                        <StyledButton
                            shape="round"
                            type="primary"
                            icon={<AddCircle size={18} />}
                            onClick={handleCreateAllocation}
                        >
                            Create New
                        </StyledButton>
                    </Space>
                )}
                <div className="ml-auto">
                    <Space size={15}>
                        <Input
                            prefix={<SearchOutlined size={18} />}
                            style={{ borderRadius: 16 }}
                            value={keyword || ''}
                            placeholder="Search"
                            onChange={(event) => setKeyword(event.target.value)}
                        />
                        <StyledButton shape="round" onClick={handleSearch}>
                            Search
                        </StyledButton>
                    </Space>
                </div>
            </div>
            <div className="mt-10">
                {list?.length ? (
                    list.map((item, key) => (
                        <AllocationItem
                            key={key}
                            item={item}
                            tosp={tosp}
                            shouldAllowEdit={user?.permission?.allocation_execute}
                            onEdit={() => {
                                handleDetailAllocation(item);
                            }}
                            className="mb-5"
                        />
                    ))
                ) : (
                    <p className="text-center">No data found</p>
                )}
            </div>
            <div className="flex justify-end mt-5">
                <Pagination onChange={onPageChange} pagination={pagination} isHideSelectPage />
            </div>
        </Layout>
    );
};

export default withAuth(Allocation);
