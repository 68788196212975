import React, { useEffect, useState } from "react";
import "./styles.css";
import { Modal, notification } from "antd";
import fileIcon from "../../assets/images/file.png";
import { UploadButton } from "./styles";
import CustomDragger from "./CustomDragger";
import { getNameFromLink, formatDate } from "utils/helper";
import DocumentFileComment from "components/assessor/claim/DocumentFileComment";
import SpaceView from "components/Space";
interface UploadFileValue {
  files?: string[];
}

interface UploadFile {
  value?: UploadFileValue;
  onChange?: (value: { files: any[] }) => void;
  id?: (value: { files: any[] }) => void;
}

interface Props {
  multiple?: boolean;
  limit?: number;
  value?: any;
  caseId?: string;
  onDone: (data: any) => void;
  onChange?: (value: any) => void;
  handleUpload: any;
  closePopUp?: any;
  comment?: any;
  callback?: any;
  formId?: any;
  user?: any;
  isDeleteComment?: boolean;
  accept: Array<string>;
  disabled?: boolean;
  style?: any;
  title?: string;
  descriptionFileUpload?: string;
  textDone?: string;
  componentAbove?: any;
  maximum?: number;
  isRequired?: boolean;
  isCloseAfterUpload?: boolean;
}

const UploadFile = ({
  multiple,
  limit,
  value,
  onDone,
  caseId,
  handleUpload,
  accept,
  disabled,
  closePopUp,
  callback,
  comment,
  isDeleteComment,
  formId,
  user,
  style,
  title = "Upload file",
  descriptionFileUpload,
  textDone,
  componentAbove,
  maximum,
  isRequired = false,
  isCloseAfterUpload,
}: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [files, setFiles] = useState<any[]>();
  const [filesUploaded, setFilesUploaded] = useState<any[]>([]);
  const id: string = caseId || "";
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsModalVisible(true);
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = async () => {
    if (!files?.length) {
      setIsModalVisible(false);
      closePopUp(false);
      return;
    }
    try {
      setIsLoading(true);
      await onDone(files);
      setFiles([]);
      setFilesUploaded([...(filesUploaded as any), files]);
      setIsLoading(false);
      if (isCloseAfterUpload) {
        setIsModalVisible(false);
        closePopUp(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      notification.error({
        message: "Error",
        description: error?.data?.message,
      });
      console.log(error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    closePopUp(false);
  };

  return (
    <Modal
      closeIcon={
        <div
          style={{ position: "relative", left: 48, top: -22 }}
          className={"close-button"}
          onClick={(e) => {
            handleCancel();
          }}
        >
          <ImgSvg color="#FFFFFF" />
        </div>
      }
      wrapClassName={"modal-upload"}
      footer={
        <UploadButton
          disabled={isRequired && !files?.length}
          loading={isLoading}
          style={{ margin: "auto", height: 56 }}
          type="primary"
          onClick={(e) => {
            handleOk();
          }}
        >
          {textDone ? textDone : files?.length ? "Upload" : "Done"}
        </UploadButton>
      }
      title={
        <p
          style={{
            margin: "0",
            textAlign: "center",
            fontWeight: 600,
            fontSize: "24px",
            lineHeight: "36px",
            color: "#22313F",
          }}
        >
          {title}
        </p>
      }
      visible={isModalVisible}
    >
      {componentAbove && componentAbove}
      <CustomDragger
        disabled={
          (limit === 1 && files?.length === 1) ||
          (comment || isDeleteComment
            ? !isDeleteComment || comment?.user_id !== user?.id
            : false)
        }
        multiple={multiple || false}
        limit={limit || 99}
        id={id}
        setFilesUpload={setFiles}
        filesUpload={files}
        filesUploaded={filesUploaded}
        handleUpload={handleUpload}
        accept={accept}
        description={descriptionFileUpload}
        maximum={maximum}
      />
      <SpaceView size={16} />
      <div
        style={
          comment?.documents?.length >= 4
            ? { height: "auto", maxHeight: 160, overflowY: "auto" }
            : {}
        }
      >
        {comment?.documents?.map((doc: any, index: number) => (
          <DocumentFileComment
            key={index}
            callback={callback}
            formId={formId}
            commentId={comment.id}
            documentId={doc.id}
            url={doc?.url || doc}
            isDelete={
              comment?.user_id === user?.attributes?.sub && isDeleteComment
            }
            name={getNameFromLink(doc?.url || "")}
            type={doc?.type || "image/jpeg"}
          />
        ))}
      </div>
    </Modal>
  );
};

export default UploadFile;

const ImgSvg = ({ color = "#00000073" }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.4 7.61329C23.88 7.09329 23.04 7.09329 22.52 7.61329L16 14.12L9.47996 7.59996C8.95996 7.07996 8.11996 7.07996 7.59996 7.59996C7.07996 8.11996 7.07996 8.95996 7.59996 9.47996L14.12 16L7.59996 22.52C7.07996 23.04 7.07996 23.88 7.59996 24.4C8.11996 24.92 8.95996 24.92 9.47996 24.4L16 17.88L22.52 24.4C23.04 24.92 23.88 24.92 24.4 24.4C24.92 23.88 24.92 23.04 24.4 22.52L17.88 16L24.4 9.47996C24.9066 8.97329 24.9066 8.11996 24.4 7.61329Z"
        fill={color}
      />
    </svg>
  );
};
