import { Menu } from "antd";
import logo from "assets/logo.svg";
import "./layout.css";
import {
  Book,
  Book1,
  Box1,
  Data,
  Element3,
  Folder2,
  People,
  PresentionChart,
  Setting2,
  User,
  UserOctagon,
  Calendar
} from "iconsax-react";
import { useEffect, useMemo, useState } from "react";
import { BiChevronsLeft, BiChevronsRight } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "./Header";
import {
  StyledCollapsed,
  StyledContent,
  StyledLayout,
  StyledScroll,
  StyledSider,
} from "./style";
import { selectUserSelector, PermissionData } from "containers/Auth/authSlice";
import { ROLE_PAGE } from "../../constants/index";
import { AnnouncementIcon } from "components/icons/announcementIcon";

interface IProps {
  title?: any;
  children?: any;
  isHasHeader?: boolean;
}

const Layout = (props: IProps) => {
  const { isHasHeader = true } = props;
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("collapsed") === "1"
  );
  const [role, setRole] = useState("");
  const [permission, setPermission] = useState<PermissionData>();
  const location = useLocation();

  const toggle = () => {
    localStorage.setItem("collapsed", collapsed ? "0" : "1");
    setCollapsed(!collapsed);
  };

  const openKeys = useMemo(() => {
    const list = location.pathname.split("/").filter(Boolean);

    return list.map((item, index) => `${item}-submenu`);
  }, [location.pathname]);

  const user = useSelector(selectUserSelector);

  useEffect(() => {
    if (user) {
      setRole(user?.role);
      let newUser = JSON.parse(JSON.stringify(user));
      if (user.role === "Master") {
        newUser.permission.doctor_provider_view = false;
      }
      setPermission(newUser?.permission);
    }
  }, [user]);

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const semiColor = "#727C8A";

  return (
    <StyledLayout>
      <StyledSider
        theme="light"
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={250}
      >
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <StyledScroll>
          <Menu
            mode="inline"
            selectedKeys={["/" + location.pathname.split("/")[1]]}
            defaultOpenKeys={openKeys}
          >
            <Menu.Item
              key="/"
              icon={
                <Element3
                  variant={location.pathname === "/" ? "Bulk" : "Outline"}
                  size={24}
                />
              }
            >
              <Link to="/">Dashboard</Link>
            </Menu.Item>

            {permission?.archive_view && (
              <Menu.Item key="/archive" icon={<Folder2 size={24} />}>
                <Link to="/archive">Archive</Link>
              </Menu.Item>
            )}

            {permission?.allocation_view && (
              <Menu.Item
                key="/allocations"
                icon={<Data className="rotate-90" size={24} />}
              >
                <Link to="/allocations">Allocation</Link>
              </Menu.Item>
            )}
            {ROLE_PAGE.ANNOUNCEMENT.includes(role) && (
              <Menu.Item
                className="custom-menu-item"
                key="/announcement"
                icon={<AnnouncementIcon />}
              >
                <Link to="/announcement">Announcement</Link>
              </Menu.Item>
            )}
            {permission?.provider_view && (
              <Menu.Item
                key="/providers"
                icon={
                  <Box1
                    variant={
                      location.pathname === "/providers" ? "Bulk" : "Outline"
                    }
                    size={24}
                  />
                }
              >
                <Link to="/providers">Provider</Link>
              </Menu.Item>
            )}

            {permission?.holiday_view && (
              <Menu.Item
                key="/holiday"
                icon={
                  <Calendar
                    variant={
                      location.pathname === "/holiday" ? "Bulk" : "Linear"
                    }
                    size={24}
                  />
                }
              >
                <Link to="/holiday">Holiday</Link>
              </Menu.Item>
            )}

            {permission?.team_view && (
              <Menu.Item
                key="/teams"
                icon={
                  <People
                    variant={
                      location.pathname === "/teams" ? "Bulk" : "Outline"
                    }
                    size={24}
                  />
                }
              >
                <Link to="/teams">Team</Link>
              </Menu.Item>
            )}

            {permission?.policy_view && (
              <Menu.Item
                key="/policy"
                icon={
                  location.pathname === "/policy" ? (
                    <Book1 size={24} variant="Bulk" />
                  ) : (
                    <Book size={24} />
                  )
                }
              >
                <Link to="/policy">Policy</Link>
              </Menu.Item>
            )}
            {/* <Menu.SubMenu
            key="users-submenu"
            icon={<RiUserLine size={24} />}
            title="USERS MANAGEMENT"
          >
            <Menu.Item key="/users" icon={<RiUserLine size={24} />}>
              <Link to="/users">Users</Link>
            </Menu.Item>
            <Menu.Item key="/users/groups" icon={<RiGroupLine size={24} />}>
              <Link to="/users/groups">Groups</Link>
            </Menu.Item>
          </Menu.SubMenu> */}

            {permission?.configuration_view && (
              <Menu.Item
                key="/configuration"
                icon={
                  <Setting2
                    size={24}
                    variant={
                      location.pathname === "/configuration"
                        ? "Bulk"
                        : "Outline"
                    }
                  />
                }
              >
                <Link to="/configuration">Configuration</Link>
              </Menu.Item>
            )}

            {permission?.analytics_view && (
              <Menu.Item key="/analytics" icon={<PresentionChart size={24} />}>
                <Link to="/analytics" target="_blank">Analytics</Link>
              </Menu.Item>
            )}
          </Menu>
        </StyledScroll>
        <StyledCollapsed onClick={toggle}>
          {collapsed ? <BiChevronsRight /> : <BiChevronsLeft />}
        </StyledCollapsed>
      </StyledSider>
      <StyledLayout
        style={{
          marginLeft: collapsed ? 130 : 300,
        }}
      >
        {isHasHeader ? (
          <>
            <Header title={props.title} />
            <StyledContent>{props.children}</StyledContent>
          </>
        ) : (
          <StyledContent className="content-noheader">
            {props.children}
          </StyledContent>
        )}
      </StyledLayout>
    </StyledLayout>
  );
};

export default Layout;
