import { Col, Row, Space } from "antd";
import Checkbox from "components/Checkbox";
import SpaceView from "components/Space";
import React from "react";
import { handleSum } from "utils/helper";
import {
  LabelSection1,
  TableBody,
  TableBodyRow,
  TableBodyRowTitle,
  TableBodyRowValue,
  TableFooter,
  TableFooterTitle,
  TableFooterValue,
  TableHead,
  TableHeadItem,
} from "../styled";
import { PriceView } from "./EstimateCost";

type Props = {
  color: string;
  data: any;
  title: string;
  rate?: any
};

const ItemStayStandard = ({ color = "F3F7FC", data, title, rate }: Props) => {
  const exacTotal = (total: number, checked: boolean) => {
    if (checked) {
      return total*(1 + rate?.gst / 100);
    }
    return total;
  };

  if (!data) return null;

  return (
    <Row
      style={{
        backgroundColor: `${color}`,
        padding: "16px 24px 24px 24px",
        borderRadius: "24px",
      }}
    >
      <Col span={24}>
        <Row>
          <Space>
            <LabelSection1>{title}</LabelSection1>
          </Space>
        </Row>
        <SpaceView size={25} />
        <Row>
          <Col span={24}>
            <div>
              <TableHead>
                <TableHeadItem />
                <TableHeadItem>Total No. of Visits</TableHeadItem>
                <TableHeadItem>Panel Fee</TableHeadItem>
                <TableHeadItem>Total No. of Days</TableHeadItem>
                <TableHeadItem>Total No. of Nights</TableHeadItem>
                <TableHeadItem>Is GST included?</TableHeadItem>
                <TableHeadItem>Total</TableHeadItem>
              </TableHead>
              <SpaceView size={16} />
              <TableBody>
                <TableBodyRow>
                  <TableBodyRowTitle>General Ward</TableBodyRowTitle>
                  <TableBodyRowValue>
                    {data?.general_ward?.no_of_days}
                  </TableBodyRowValue>
                  <TableBodyRowValue>
                    {data?.general_ward?.agreed_daily_fee}
                  </TableBodyRowValue>
                  <TableBodyRowValue>
                    {data?.general_ward?.total_no_of_days}
                  </TableBodyRowValue>
                  <TableBodyRowValue>
                    {data?.general_ward?.total_no_of_nights}
                  </TableBodyRowValue>
                  <TableBodyRowValue>
                    <Checkbox
                      disable
                      checked={data?.general_ward?.gst_included}
                    />
                  </TableBodyRowValue>
                  <TableBodyRowValue>
                    <PriceView>
                      {exacTotal(
                        (data?.general_ward?.agreed_daily_fee || 0) *
                          (data?.general_ward?.no_of_days || 0),
                        data?.general_ward?.gst_included
                      )}
                    </PriceView>
                  </TableBodyRowValue>
                </TableBodyRow>
                <TableBodyRow style={{ paddingTop: 0, paddingBottom: "16px" }}>
                  <TableBodyRowTitle>ICU / HDU Ward</TableBodyRowTitle>
                  <TableBodyRowValue>
                    {data?.icu_ward?.no_of_days}
                  </TableBodyRowValue>
                  <TableBodyRowValue>
                    {data?.icu_ward?.agreed_daily_fee}
                  </TableBodyRowValue>
                  <TableBodyRowValue>
                    {data?.icu_ward?.total_no_of_days}
                  </TableBodyRowValue>
                  <TableBodyRowValue>
                    {data?.icu_ward?.total_no_of_nights}
                  </TableBodyRowValue>
                  <TableBodyRowValue>
                    <Checkbox disable checked={data?.icu_ward?.gst_included} />
                  </TableBodyRowValue>
                  <TableBodyRowValue>
                    <PriceView>
                      {exacTotal(
                        (data?.icu_ward?.agreed_daily_fee || 0) *
                          (data?.icu_ward?.no_of_days || 0),
                        data?.icu_ward?.gst_included
                      )}
                    </PriceView>
                  </TableBodyRowValue>
                </TableBodyRow>

                <TableBodyRow style={{ paddingTop: 0, paddingBottom: "16px" }}>
                  <TableBodyRowTitle>Day Surgery</TableBodyRowTitle>
                  <TableBodyRowValue>
                    {data?.day_surgery?.no_of_days}
                  </TableBodyRowValue>
                  <TableBodyRowValue>
                    {data?.day_surgery?.agreed_daily_fee}
                  </TableBodyRowValue>
                  <TableBodyRowValue>
                    {data?.day_surgery?.total_no_of_days}
                  </TableBodyRowValue>
                  <TableBodyRowValue>
                    {data?.day_surgery?.total_no_of_nights}
                  </TableBodyRowValue>
                  <TableBodyRowValue>
                    <Checkbox disable checked={data?.day_surgery?.gst_included} />
                  </TableBodyRowValue>
                  <TableBodyRowValue>
                    <PriceView>
                      {exacTotal(
                        (data?.day_surgery?.agreed_daily_fee || 0) *
                          (data?.day_surgery?.no_of_days || 0),
                        data?.day_surgery?.gst_included
                      )}
                    </PriceView>
                  </TableBodyRowValue>
                </TableBodyRow>
              </TableBody>
              <SpaceView size={16} />
              <TableFooter>
                <TableFooterTitle>Total Length of Stay</TableFooterTitle>
                <TableFooterValue>
                  <span>{data?.total_no_of_days}</span>
                </TableFooterValue>
                <TableFooterValue>
                  <span>{data?.total_no_of_nights}</span>
                </TableFooterValue>
                <TableFooterValue>
                  <PriceView>
                    {handleSum([
                      {
                        value: data?.icu_ward?.agreed_daily_fee,
                        checked: true,
                      },
                      {
                        value: data?.general_ward?.agreed_daily_fee,
                        checked: true,
                      },
                      {
                        value: data?.day_surgery?.agreed_daily_fee,
                        checked: true,
                      },
                    ])}
                  </PriceView>
                </TableFooterValue>
                <TableFooterValue></TableFooterValue>
                <TableFooterValue></TableFooterValue>
                <TableFooterValue>
                  <PriceView>{data?.total_fee}</PriceView>
                </TableFooterValue>
              </TableFooter>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ItemStayStandard;
