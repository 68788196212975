import styled from "styled-components";
import { Layout, Menu, Tabs, Row, Button } from "antd";
import * as antd from "antd";

export const ItemOtherForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

export const UploadButton = styled(Button)`
  width: 194px;
  height: 64px;
  background: linear-gradient(45, #55bed2, #70eaf0);
  box-sizing: border-box;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > span {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
  }

  & img {
    width: 24px;
    margin-right: 12px;
  }
`;

export const Cell = styled.div`
  min-height: 1px;
  width: ${(props: { size: string }) => props.size};
`;

export const StText = styled.button`
  width: 48px;
  height: 48px;
  border: 1px solid #55bed2;
  box-sizing: border-box;
  border-radius: 24px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #55bed2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0);

  & span {
    font-size: 14px;
    line-height: 10px;
    margin-bottom: 9px;
    display: inline-block;
    font-weight: 400;
    margin-left: 2px;
  }
`;

export const TableHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  margin-bottom: 16px;
`;

export const TableHeadItem = styled.div`
  width: calc(100% / 4);
  text-align: right;
  padding-right: 16px;
  //font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${(props: any) => (props.color ? `${props.color}` : "#101820")};
`;

export const TableBody = styled.div`
  min-height: 68px;
  background: #ffffff;
  border-radius: 16px;
  flex: 1;
  width: 100%;
`;

export const TableBodyRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  margin-bottom: 16px;
  padding-top: 18px;
  padding-bottom: 0px;
`;

export const TableBodyRowTitle = styled.div`
  width: calc(100% / 4);
  text-align: left;
  padding-left: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #101820;
`;
export const TableBodyRowTitle2 = styled.div`
  width: calc(100% / 4);
  text-align: left;
  padding-left: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #101820;
`;
export const TableBodyRowValue = styled.div`
  width: calc(100% / 4);
  text-align: right;
  padding-right: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #101820;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const TableBodyRowValue2 = styled.div`
  width: calc(100% / 4);
  text-align: right;
  padding-right: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props: any) => (props.color ? `${props.color}` : "#101820")};
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const TableBodyRowValueReason = styled(antd.Input.TextArea)`
  margin: 0 16px;
  width: 100%;
  margin-top: -12px;
  font-weight: 400;
  font-size: 14px;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 16px;
  line-height: 50px;
`;

export const TableFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px;
  width: 100%;
  height: 75px !important;
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  flex: none;
  box-sizing: border-box;
  margin-top: 16px;
  border: ;
  border: ${(props) => (props.color ? `1px solid ${props.color}` : "unset")};
`;

export const TableFooterTitle = styled.div`
  width: calc(100% / 4);
  text-align: left;
  padding-left: 24px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #1b1b1b;
`;
export const TableFooterValue = styled.div`
  width: calc(100% / 4);
  text-align: right;
  padding-right: 24px;
  min-width: 1px;
  min-height: 1px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #1b1b1b;
  &.total-hospital {
    .price-icon {
      top: -2px !important;
    }
  }
`;
export const TableFooterValue3 = styled.div`
  width: calc(100% / 3);
  text-align: right;
  padding-right: 24px;
  min-width: 1px;
  min-height: 1px;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #1b1b1b;
  &.total-hospital {
    .price-icon {
      top: -2px !important;
    }
  }
`;

export const ContentReason = styled.div`
  border: 1px solid #dddddd;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  padding: 13px 20px;
`;

export const TitleSession = styled.h3`
  font-size: 30px;
  font-weight: 500;
  line-height: 44px;
  color: #1b1b1b;
  text-align: start;
  margin-bottom: 0;
`;

export const LabelSection1 = styled.span`
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: ${(props: any) => (props.color ? `${props.color}` : "#1b1b1b")};
`;

export const LabelButton1 = styled.div`
  border: 1px solid #55bed2;
  color: ${(props: any) => (props.color ? `${props.color}` : "#55bed2")};
  padding: 13px 20px;
  border-radius: 16px;
  min-width: 215px;
  margin-top: 20px;
  margin-right: 20px;
  max-height: 50px;
`;

export const LabelSection2 = styled.span`
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #1b1b1b !important;
`;

export const ContentSection1 = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${(props: any) => (props.color ? `${props.color}` : "#1b1b1b")};
`;

export const ContentSection2 = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${(props: any) => (props.color ? `${props.color}` : "#2a2f36")};
  text-align: center;
  display: inline-block;
  width: 100%;
`;
export const ContentSection3 = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #727c8a;
  text-align: center;
  display: inline-block;
  width: 100%;
`;

export const ContentSection6 = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${(props: any) => (props.color ? `${props.color}` : "#1b1b1b")};
  text-align: center;
  display: inline-block;
  width: 100%;
`;

export const ContentSection8 = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  color: ${(props: any) => (props.color ? `${props.color}` : "#1b1b1b")};
`;

export const ContentSection4 = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${(props: any) => (props.color ? `${props.color}` : "#727c8a")};
`;

export const ContentSection5 = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #101820;
`;

export const ContentSection7 = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: ${(props: any) => (props.color ? `${props.color}` : "#101820")};
`;

export const ContentTag = styled.div`
  min-height: 64px;
  padding: 20px 32px;
  background: ${(props: any) => (props.color ? `${props.color}` : "#f3f7fc")};
  border-radius: 24px;
`;

export const ContentTagActive = styled.div`
  min-height: 64px;
  padding: 20px 32px;
  color: #55BED2;
  background-color: ${(props: any) => (props.color ? `${props.color}` : "#EDFBFC")};
  border-radius: 24px;
`;

export const ContentTag2 = styled.div`
  min-height: 48px;
  border-radius: 40px;
  padding: 0 16px;
  background-color: ${(props: any) => (props.color ? `${props.color}` : "#f3f8fc")};
`;

export const CustomRow = styled(Row)`
  height: 72px;
  padding-left: 24px;
  align-items: center;
  background-color: ${(props: any) => `${props.color}` || "white"};
`;

export const ImageAsign = styled.img`
  width: 262px;
  height: 142px;
  margin: 0 auto;
`;

export const TextBadge = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #ffffff;
`;
export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SessionContainer = styled.div`
  margin: 0 32px 0 127px;
`;

export const Badge = styled.div`
  position: absolute;
  top: 10px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fe8833;
`;

export const MenuItem = styled(Menu.Item)`
  position: relative;
  height: 72px !important;
  width: 73px !important;
  border-radius: 16px;
  background: ${(props: any) =>
    props.color === "active" ? "#fff" : "transparent"} !important;
  &::after {
    content: "" !important;
    position: absolute !important;
    left: 0 !important;
    top: 12px !important;
    height: 44px !important;
    width: 3px !important;
    background: #4dd4e2 !important;
    opacity: ${(props: any) => (props.color === "active" ? 1 : 0)} !important;
    transform: unset !important;
  }
`;
export const StyledLayout = styled(Layout)`
  position: relative;
  min-height: 100vh;
  padding: 24px;
  background: transparent;
  transition: all 0.2s;
`;

export const StyledContent = styled(Layout.Content)`
  background: #fff;
  padding: 20px;
  padding-left: 0;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
`;
export const Back = styled.p`
  display: flex;
  color: #727c8a;
  align-items: center;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  position: relative;
  top: 24px;
  left: 24px;

  &:hover {
    color: #394451;
  }
`;

export const StyledSider = styled(Layout.Sider)`
  position: fixed;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  box-shadow: 0px 4px 30px rgba(0, 149, 153, 0.1);
  padding-bottom: 40px;
  left: 24px;
  top: 24px;
  bottom: 24px;
  overflow: hidden;
  background-color: #fcf7f5;
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }

  .logo {
    display: flex;
    height: 54px;
    margin: 10px;
    align-items: center;
    justify-content: center;
  }

  .ant-menu-item-selected {
    border-radius: 16px;

    &::after {
      display: none;
    }
  }

  .ant-menu-vertical {
    border-right: 0;
  }

  .ant-menu-inline {
    border-right: 0;

    & .ant-menu-item {
      height: 48px;
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  .ant-menu-inline-collapsed {
    & > .ant-menu-item {
      padding: 0 18px;
    }
  }
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    padding: 16px 20px;
    border-radius: 40px;
    margin: 0;
    &:first-child {
      padding-left: 0;
      &.ant-tabs-tab-active {
        &::after {
          left: 42%;
        }
      }
    }
    &-btn {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: #494949;
    }
  }
  .ant-tabs-extra-content {
    min-width: 400px;
    text-align: right;
    .ant-row {
      span {
        color: #000000;
        font-size: 16px;
        line-height: 24px;
        &.title {
          font-weight: 400;
        }
        &.value {
          font-weight: 600;
        }
      }
    }
  }

  .ant-tabs-tab-active {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      height: 4px !important;
      width: 30px !important;
      border-radius: 8px;
      left: 50%;
      transform: translateX(-50%);
      background: #00c1d5;
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }
`;

export const FieldSet = styled(Row)<{
  active?: boolean
  first?: boolean
  last?: boolean
}>`
  ${({ first, last }) => {
    if (!first && !last) first = last = true

    return `
      ${first ? `
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        
        ${last ? '' : `
          border-bottom: none !important;
          padding-bottom: 16px !important;
          margin-bottom: -16px !important;
        `}
      `: ''}

      ${last ? `
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        ${first ? '' : `
          border-top: none !important;
          margin-top: -16px !important;
          padding-top: 16px !important;  
        `}
      `: ''}
    `
  }}
 
  ${({ active }) => active ? 'border: 1px solid black;' : ''};

  margin: -5px -5px;
  padding: 5px 5px;
`
export const StyledCaseCommonInfo = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: rgb(255, 255, 255);
  bottom: 0;
  z-index: 1000;
  border-bottom: 1px solid rgb(221, 221, 221);
  max-width: 500px;
  background: #fcf7f5;
  border: 1px solid #4dd4e2;
  margin-left: auto;
  z-index: 1000;
  box-shadow: 0 12px 28px 0 rgb(221, 221, 221), 0 2px 4px 0 rgb(221, 221, 221);
  width: 100%;
  right: 40px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 0;
`;

export const StyledCaseCommonInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: #edfbfc;
  font-weight: 500;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px, rgba(0, 0, 0, 0.1) 0px -1px 0px 0px inset, rgba(255, 255, 255, 0.5) 0px 2px 1px -1px inset;
`;