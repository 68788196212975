import { useDispatch, useSelector } from "react-redux";
import {
  setNotifications as setNotificationsAction,
  notificationsSelector,
} from "../store/slices/notificationsSlice";
const useNotifications = () => {
  const dispatch = useDispatch();
  const setNotifications = (options: any) =>
    dispatch(setNotificationsAction(options));
  const notifications = useSelector(notificationsSelector);

  return { notifications, setNotifications };
};

export default useNotifications;
