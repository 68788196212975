import { RootState } from "../../store/index";
import { createAction, createSlice } from "@reduxjs/toolkit";

export interface ConfigurationState {
  isCurrentImporting?: boolean;
  processed?: boolean;
}

const initialState: ConfigurationState = {
  isCurrentImporting: false,
  processed: false,
};

export const configSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setProcessed: (state, action) => {
      state.processed = action.payload;
    },
    setIsCurrentImporting: (state, action) => {
      state.isCurrentImporting = action.payload;
    },
  },
});

export const { setProcessed, setIsCurrentImporting } = configSlice.actions;

export const selectConfigSelector = (state: RootState) => state?.config;

export default configSlice.reducer;
