import moment from "moment";
import DAService from "services/DAService";

export const formatCurrency = (value: number): string | null => {
  if (value == -1) return null;
  return value
    ? value.toLocaleString("en-US", {style: "currency", currency: "USD"})
    : null;
};

export const convertKeyItemOrtherFee = (value: string) => {
  if (!value || typeof value !== "string") return;
  return value.toLowerCase().replaceAll(" ", "_");
};

export const formatCurrency2 = (value: number): string | null => {
  if (value == -1) return null;
  let val: number = 0;
  return value
    ? value.toLocaleString("en-US", {style: "currency", currency: "USD"})
    : val.toLocaleString("en-US", {style: "currency", currency: "USD"});
};

export const dowloadFile = (
  urlFile: string,
  type = "image/jpeg",
  name = "file"
) => {
  fetch(urlFile, {
      method: "GET",
      headers: {
          "Content-Type": type,
      },
  })
      .then((response) => response.blob())
      .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();
          link?.parentNode?.removeChild(link);
      });
};

export const formatPercent = (value: number): string | null => {
  return value
    ? value.toLocaleString("en-US", {
      style: "percent",
    })
    : "0%";
};

export const formatNumber = (value: number): string | null => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const wait = async (time: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
};

export const getNameFromLink = (link: string): string => {
  if (!link || typeof link !== "string") return "";
  const splitPath =
      decodeURIComponent(link).split("/")[link.split("/").length - 1];
  const nameFiles = splitPath.split("_");
  nameFiles.shift();
  const nameFile = nameFiles.join("_");
  return nameFile;
};

export const waitUpload = async (
  lastError: any,
  retry: number = 5,
  callback?: any
): Promise<any> => {
  if (retry === 0) {
    throw new Error();
  }

  const {create_date, type} = lastError;
  try {
    await wait(10000);
    const {data} = await DAService.errors.getLogs(type);
    const nextError = data?.data?.items[0];

    if (nextError && moment(create_date) > moment(nextError.create_date)) {
      return waitUpload(nextError, retry - 1, callback);
    }

    callback && callback(nextError || lastError);
    return nextError;
  } catch (error) {
    callback && callback(lastError);
    return lastError;
  }
};
export const showEllipsis = (value: any): string => {
  let dot: string = ",...";

  if (value?.length && value?.length <= 2) {
    return value.map((ele: any) => ele?.code).join(", ");
  } else if (value?.length > 2) {
    return value
      .slice(0, 2)
      .map((ele: any) => ele?.code)
      .join(", ")
      .concat(dot);
  }
  return "-";
};

export const showEllipsis2 = (value: any): string => {
  let dot: string = ",...";
  if (value?.length && value?.length <= 2) {
    return value.map((ele: any) => ele?.tosp_code).join(", ");
  } else if (value?.length > 2) {
    return value
      .slice(0, 2)
      .map((ele: any) => ele?.tosp_code)
      .join(", ")
      .concat(dot);
  }
  return "-";
};

export const formatDate = (
  value: any,
  format: string,
  valNUll: string,
  formatFrom?: string | undefined
) => {
  if (!value) return valNUll;

  if (formatFrom) {
    return moment(value, formatFrom).format(format);
  }

  let parsed = moment(value, "DD/MM/YYYY");

  if (!parsed.isValid()) {
    parsed = moment(value, "DD-MMM-YYYY");
  }

  if (!parsed.isValid()) {
    parsed = moment(value);
  }

  return parsed.format(format);
};

export const formatDate2 = (
  value: any,
  formatTo: string,
  formatFrom: string,
  valNUll: string
) => {
  if (!value) return valNUll;
  return moment(value, formatTo).format(formatFrom);
};
