import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html, body{
    margin: 0;
    padding: 0;
  }

  #root{
    min-height: 100vh;
    background: linear-gradient(244.89deg, #E2FBFF 7.39%, #EAE1D9 74.76%, #F7B89C 100%);
    overflow: hidden;
    overflow-y: auto;
  }
`;
