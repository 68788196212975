import React from "react";
import { Container, Checker, Text } from "./styles";

export default function Checkbox(props: {
  label?: string;
  placeholder?: string;
  onChange?: any;
  checked?: boolean;
  disable?: boolean;
  onDBClick?: any;
  styleCheckBox?: any;
}) {
  return (
    <Container onDoubleClick={props.onDBClick}>
      <Checker
        checked={!!props.checked}
        onChange={props.onChange}
        disabled={props.disable}
        style={props.styleCheckBox || null}
      />
      {props?.label ? <Text>{props.label}</Text> : null}
    </Container>
  );
}
