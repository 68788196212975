import React, { useEffect, useState } from "react";
import { Avatar, message, notification, Progress, Tooltip, Upload } from "antd";
import draggerIcon from "../../../assets/dragger.png";
import {
  CustomSpace,
  FileIndicator,
  StyleText,
  UploadDragIcon,
  UploadItem,
} from "./styles";
import "./styles.css";
import { IconTypeFile } from "components/Common/IconTypeFile";
import { CloseCircleOutlined } from "@ant-design/icons";
import { MAX_SIZE_UPLOAD } from "constants/epac.constans";

const { Dragger } = Upload;

interface CustomDraggerValue {
  files?: any[];
}

interface CustomDragger {
  value?: CustomDraggerValue;
  onFinish?: (files: any[]) => void;
}

const CustomAvatar: React.FC<any> = ({ name = "jpg" }) => (
  <div style={{ position: "relative" }}>
    <Avatar
      style={{
        backgroundColor: "white",
        marginRight: "12px",
        padding: "3px",
        color: "black",
        position: "relative",
      }}
      shape="square"
      size={45}
      icon={
        <IconTypeFile
          style={{ position: "relative", top: "-8px", left: "-8px" }}
        />
      }
    ></Avatar>
    <span style={{ position: "absolute", color: "black", top: 19, right: 19 }}>
      {name}
    </span>
  </div>
);

const getEndPathFile = (fileName: string) => {
  return fileName.split(".")[fileName.split(".").length - 1] || "";
};

const DragableUploadListItem: React.FC<any> = ({
  originNode,
  moveRow,
  file,
  fileList,
  remove,
}) => {
  const errorNode = (
    <Tooltip title="Upload Error">
      <CustomSpace align="center" style={{ width: "100%" }}>
        <CustomAvatar fileUpload={file} name={getEndPathFile(file.name)} />
        <UploadItem>{file.name}</UploadItem>
        <CloseCircleOutlined style={{ color: "red" }} onClick={remove} />
      </CustomSpace>
    </Tooltip>
  );
  const sizeInMB =
    file.size < 1024
      ? file.size + "KB"
      : (file.size / (1024 * 1024)).toFixed(2) + "MB";
  const sizePercentInMB =
    file.size < 1024
      ? file.size + "KB"
      : ((file.percent * file.size) / 100 / (1024 * 1024)).toFixed(2) + "MB";
  return (
    <div className={`ant-upload-draggable-list-item`}>
      {file.status === "error" ? (
        errorNode
      ) : (
        <CustomSpace align="center">
          <CustomAvatar fileUpload={file} name={getEndPathFile(file.name)} />
          <UploadItem>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "395px",
              }}
            >
              <p
                style={{
                  display: "inline-block",
                  width: "calc(100% - 16px)",
                }}
              >
                {file.name}
              </p>
              <CloseCircleOutlined style={{ color: "red" }} onClick={remove} />
            </div>
            <Progress
              style={{ width: "395px" }}
              percent={file.percent}
              showInfo={false}
              strokeWidth={5}
              strokeColor={"#00C1D5"}
            />
            <FileIndicator>
              {sizePercentInMB} of {sizeInMB}
            </FileIndicator>
          </UploadItem>
        </CustomSpace>
      )}
    </div>
  );
};

const CustomDragger = (props: any) => {
  const {
    accept = ["image/png", "image/jpeg", "image/jpg", "application/pdf"],
    description = "Only JPEG, PNG and PDF files with maximum of",
    maximum,
  } = props;
  const theme = {
    fontStyle: "italic",
  };
  const params = {
    name: "files",
    multiple: props.multiple || false,
    beforeUpload: (file: File) => {
      let check = false;
      accept.forEach((item: string) => {
        if (file.name.includes(item)) {
          check = true;
        }
      });
      if (!check) {
        let textNoti = accept.join(", ");
        textNoti = textNoti.toUpperCase();
        notification.error({
          message: "Error",
          description: `Only ${textNoti} file is allowed`,
        });
        return false;
      }
      if (maximum && Math.floor(file.size / 1024) > maximum) {
        const maxSizeUpload = MAX_SIZE_UPLOAD;
        notification.error({
          message: "Error",
          description: "Maximum file size is " + maxSizeUpload + "MB",
        });
        return false;
      }
      return true;
    },
    customRequest: async (value: any) => {
      const callback  = (status: any, url: any) => {
        value.onSuccess({
          name: value.file.name,
          status: status,
          url: url,
          uid: url,
          thumbUrl: url,
        });
      }
      const result: any = await props.handleUpload(value.file, callback);
      if (!!result) {
        value.onSuccess({
          name: value.file.name,
          status: "success",
          url: result,
          uid: result,
          thumbUrl: result,
        });
      }
    },
    maxCount: props.limit || 999,
    accept: accept.join(","),
    progress: { strokeWidth: 5, strokeColor: "#00C1D5" },

    itemRender: (
      originNode: any,
      file: any,
      currFileList: any,
      actions: any
    ) => {
      let check = false;
      props.filesUpload?.forEach((item: any) => {
        if (item?.uid === file?.uid) {
          check = true;
        }
      });
      if (!check) return null;
      return file?.status === "done" && file?.response?.url ? (
        <DragableUploadListItem
          key={file.uid}
          originNode={originNode}
          file={file}
          fileList={currFileList}
          remove={actions.remove}
        />
      ) : null;
    },
    onChange(info: any) {
      const { status } = info.file;
      const { file } = info;
      if (status === "done") {
        let newFiles = info.fileList.filter(
          (file: any) => file.status === "done"
        );
        const listFileUploaded = props?.filesUploaded?.map(
          (fileU: any) => fileU.uid
        );
        newFiles = newFiles.filter(
          (fileNew: any) => !listFileUploaded?.includes(fileNew.uid)
        );
        props.setFilesUpload(newFiles);
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e: any) {
      //console.log("Dropped files", e.dataTransfer.files);
    },
    onRemove(e: any) {
      const newFiles = props?.filesUpload?.filter((i: any) => i.uid !== e.uid);
      props.setFilesUpload(newFiles);
    },
  };

  return (
    <Dragger {...params} disabled={props.disabled}>
      <UploadDragIcon className="ant-upload-drag-icon">
        <img style={{ margin: "auto" }} src={draggerIcon} alt="" />
      </UploadDragIcon>
      <p className="ant-upload-text">
        Drag & Drop or <StyleText color={"#00C1D5"}>browse</StyleText> your
        files
      </p>
      <p className="ant-upload-hint">
        <StyleText color={"747C89"} theme={theme}>
          {description}{" "}
        </StyleText>
        {maximum && (
          <StyleText theme={theme} color={"747C89"}>
            <b>{maximum / (1024 )}MB</b>
          </StyleText>
        )}
        .
      </p>
    </Dragger>
  );
};

export default CustomDragger;
