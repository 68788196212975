import React from "react";

type Props = {};

export const IconTypeFile = (props: any) => {
  return (
    <div style={props.style}>
      <svg
        width="59"
        height="59"
        viewBox="0 0 59 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M50.3955 25.0498H43.2909C37.4647 25.0498 32.7201 20.3052 32.7201 14.4789V7.37435C32.7201 6.02227 31.6138 4.91602 30.2618 4.91602H19.8384C12.2668 4.91602 6.14551 9.83268 6.14551 18.6089V40.3898C6.14551 49.166 12.2668 54.0827 19.8384 54.0827H39.1609C46.7326 54.0827 52.8538 49.166 52.8538 40.3898V27.5081C52.8538 26.156 51.7476 25.0498 50.3955 25.0498Z"
          fill="#E9EEF6"
        />
        <path
          d="M38.8412 5.4341C37.8333 4.42618 36.0879 5.11452 36.0879 6.51577V15.0954C36.0879 18.6845 39.1362 21.6591 42.8483 21.6591C45.1837 21.6837 48.4287 21.6837 51.2066 21.6837C52.6079 21.6837 53.3454 20.0366 52.3621 19.0533C48.8221 15.4887 42.4796 9.07243 38.8412 5.4341Z"
          fill="#E9EEF6"
        />
      </svg>
    </div>
  );
};
