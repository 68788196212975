import {Col, Row, Space} from 'antd';
import Checkbox from 'components/Checkbox';
import SpaceView from 'components/Space';
import React from 'react'
import {
  LabelSection1,
  TableBody,
  TableBodyRow,
  TableBodyRowTitle,
  TableBodyRowValue,
  TableHead,
  TableHeadItem
} from '../styled';
import {PriceView} from './EstimateCost';

type Props = {
  title: string,
  data: any
}

const ItemOthersFees = ({title, data}: Props) => {
  return (
    <>
      <SpaceView size={28}/>
      <Row
        style={{
          backgroundColor: "#F3F7FC",
          padding: "16px 24px 24px 24px",
          borderRadius: "24px",
        }}
      >
        <Col span={24}>
          <Row>
            <Space>
              <LabelSection1 style={{textTransform: 'capitalize'}}>{title.split("_").join(" ")}</LabelSection1>
            </Space>
          </Row>
          <SpaceView size={25}/>
          <Row>
            <Col span={24}>
              <div>
                <TableHead>
                  <TableHeadItem style={{textAlign: "left", paddingLeft: 24}}>Item description</TableHeadItem>
                  {
                    title !== "significant_investigations" ? 
                      <TableHeadItem>Dosage</TableHeadItem>
                    : null
                  }
                  <TableHeadItem>No. of units</TableHeadItem>
                  <TableHeadItem>Unit cost</TableHeadItem>
                  <TableHeadItem>GST?</TableHeadItem>
                  <TableHeadItem>Total</TableHeadItem>
                </TableHead>
                <SpaceView size={16}/>
                <TableBody>
                  {
                    data && data.length ? data?.map((item: any) => {
                      return (
                        <TableBodyRow>
                          <TableBodyRowTitle style={{textAlign: "left", paddingLeft: 24}}>
                            {item?.description}
                          </TableBodyRowTitle>
                          {
                            title !== "significant_investigations" ? 
                              <TableBodyRowValue>{item?.brand}</TableBodyRowValue>
                            : null
                          }
                          <TableBodyRowValue>{item?.unit_no}</TableBodyRowValue>
                          <TableBodyRowValue>
                            <PriceView>{item?.unit_cost}</PriceView>
                          </TableBodyRowValue>
                          <TableBodyRowValue>
                            <Checkbox disable checked={item?.gst_included}/>
                          </TableBodyRowValue>
                          <TableBodyRowValue>
                            <PriceView>{item?.total * (item?.gst_included ? 1.1 : 1)}</PriceView>
                          </TableBodyRowValue>
                        </TableBodyRow>
                      );
                    }) : null
                  }
                </TableBody>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ItemOthersFees;