import { Button, Col, Popconfirm, Row, Tooltip } from "antd";
import { DeleteIcon2 } from "components/icons/deleteIcon2";
import { FolderIcon } from "components/icons/folderIcon";
import { DocumentDownload } from "iconsax-react";
import React from "react";
import DAService from "services/DAService";
import { dowloadFile } from "utils/helper";
import { Center, ContentSection1 } from "../styled";

type Props = {
  name: string;
  type: string;
  url: string;
  isUpload?: boolean;
  isDelete?: boolean;
  formId?: string;
  commentId?: string;
  documentId?: string;
  callback?: any;
};

const DocumentFileComment = ({
  url,
  type,
  name,
  isDelete,
  formId,
  commentId,
  documentId,
  callback,
}: Props) => {
  const handleDowloadFile = async (url: string, type: string, name: string) => {
    try {
      dowloadFile(url, type, name);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async () => {
    try {
      if (!formId || !commentId || !documentId) return;
      await DAService.assessor.deleteDocumentComment(
        formId,
        commentId,
        documentId
      );
      if (callback) callback();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Row className="mt-1">
      <Col span={18}>
        <Center>
          <FolderIcon />
          <ContentSection1
            style={{
              marginLeft: "14px",
              display: "inline-block",
              width: "calc(100% - 36px)",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            color="#1B1B1B"
          >
            {name}
          </ContentSection1>
        </Center>
      </Col>
      <Col span={1}></Col>
      <Col span={5}>
        <Row>
          <Col>
            <Center
              style={{
                width: "36px",
                height: "36px",
                borderRadius: "8px",
                padding: "6px",
                backgroundColor: "#f3f7fc",
              }}
              onClick={() => handleDowloadFile(url, type, name)}
            >
              <DocumentDownload
                className="cursor-pointer"
                size="48"
                variant="Bold"
                color="#4dd4e2"
              />
            </Center>
          </Col>
          <Col span="4"></Col>
          {isDelete && (
            <Col>
              <Center
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "8px",
                  padding: "6px",
                  backgroundColor: "#FFEEEE",
                }}
              >
                <Popconfirm title="Are you sure?" onConfirm={handleDelete}>
                  <Button
                    style={{
                      width: 14,
                      height: 18,
                      backgroundColor: "transparent",
                      border: "none",
                      bottom: "8px",
                      right: "4px",
                    }}
                    icon={<DeleteIcon2 />}
                  ></Button>
                </Popconfirm>
              </Center>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default DocumentFileComment;
