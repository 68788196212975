export const EditIcon = ({ color }: { color?: string }) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 20C3.5 19.4477 3.94772 19 4.5 19H20.5C21.0523 19 21.5 19.4477 21.5 20C21.5 20.5523 21.0523 21 20.5 21H4.5C3.94772 21 3.5 20.5523 3.5 20Z"
      fill={color || "#00C1D5"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0858 3.99985C15.8668 3.2188 17.1332 3.2188 17.9142 3.99985L20.5 6.58564C21.281 7.36669 21.281 8.63302 20.5 9.41406L9.20711 20.707C9.01957 20.8945 8.76522 20.9998 8.5 20.9998H4.5C3.94772 20.9998 3.5 20.5521 3.5 19.9998V15.9998C3.5 15.7346 3.60536 15.4803 3.79289 15.2927L15.0858 3.99985ZM19.0858 7.99985L16.5 5.41406L5.5 16.4141V18.9998H8.08579L19.0858 7.99985Z"
      fill={color || "#00C1D5"}
    />
  </svg>
);

export const DeleteIcon = ({ color }: { color?: string }) => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.50073 0L10.5007 1H14.0007V3H0.000732422V1H3.50073L4.50073 0H9.50073ZM1.00073 16C1.00073 17.1 1.90073 18 3.00073 18H11.0007C12.1007 18 13.0007 17.1 13.0007 16V4H1.00073V16ZM3.00073 6H11.0007V16H3.00073V6Z"
      fill={color || "#626262"}
    />
  </svg>
);

export const ResendIcon = ({ color }: { color?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.8064 11.9568C21.8064 9.08477 20.3732 6.37104 17.9533 4.67496C17.6948 4.49404 17.3189 4.53927 17.1075 4.81064C16.9195 5.0594 16.9665 5.42123 17.2484 5.62476C19.3629 7.0947 20.6081 9.46922 20.6081 11.9794C20.6081 16.3214 16.943 19.8493 12.4321 19.8493H12.2676L13.0429 19.103C13.2779 18.8768 13.2779 18.515 13.0429 18.2889C12.808 18.0627 12.4321 18.0627 12.1971 18.2889L10.4116 20.0076C10.1766 20.2337 10.1766 20.5955 10.4116 20.8217L12.1971 22.5404C12.3146 22.6534 12.4556 22.6987 12.62 22.6987C12.7845 22.6987 12.9255 22.6534 13.0429 22.5404C13.2779 22.3142 13.2779 21.9524 13.0429 21.7263L12.2676 20.98H12.4321C17.6009 20.98 21.8064 16.932 21.8064 11.9568Z"
      fill={color || "#626262"}
      stroke={color || "#626262"}
      strokeWidth="0.5"
    />
    <path
      d="M3.69822 11.4524C3.69822 7.11042 7.36335 3.58256 11.8743 3.58256H12.0387L11.2634 4.32884C11.0285 4.55498 11.0285 4.91681 11.2634 5.14296C11.3809 5.25603 11.5219 5.30126 11.6863 5.30126C11.8508 5.30126 11.9918 5.25603 12.1092 5.14296L13.8948 3.42426C14.1297 3.19812 14.1297 2.83628 13.8948 2.61014L12.1092 0.868827C11.8743 0.642683 11.4984 0.642683 11.2634 0.868827C11.0285 1.09497 11.0285 1.4568 11.2634 1.68295L12.0387 2.42922H11.8743C6.7055 2.42922 2.5 6.47721 2.5 11.4524C2.5 14.3244 3.93316 17.0382 6.35309 18.7342C6.47056 18.8021 6.58803 18.8473 6.7055 18.8473C6.89346 18.8473 7.08141 18.7569 7.19889 18.6212C7.38684 18.3724 7.33985 18.0106 7.05792 17.8071C4.96692 16.3371 3.69822 13.9626 3.69822 11.4524Z"
      fill={color || "#626262"}
      stroke={color || "#626262"}
      strokeWidth="0.5"
    />
    <path
      d="M7.00415 8.42248V14.4832C7.00415 14.7771 7.2391 15.0033 7.54452 15.0033H16.8718C17.1772 15.0033 17.4122 14.7771 17.4122 14.4832V8.42248C17.4122 8.12849 17.1772 7.90234 16.8718 7.90234H7.52103C7.23909 7.90234 7.00415 8.12849 7.00415 8.42248ZM8.0614 13.963V9.59843L11.844 12.4931C11.9615 12.5609 12.0789 12.5835 12.1964 12.5835C12.3139 12.5835 12.4314 12.5383 12.5253 12.4705L16.3079 9.57581V13.9404H8.0614V13.963ZM8.95419 8.94261H15.4621L12.1964 11.4076L8.95419 8.94261Z"
      fill={color || "#626262"}
      stroke={color || "#626262"}
      strokeWidth="0.5"
    />
  </svg>
);

export const RemoveIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="36" height="36" rx="8" fill="#F3F7FC" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2929 11.2929C11.6834 10.9024 12.3166 10.9024 12.7071 11.2929L24.7071 23.2929C25.0976 23.6834 25.0976 24.3165 24.7071 24.7071C24.3165 25.0976 23.6834 25.0976 23.2929 24.7071L11.2929 12.7071C10.9024 12.3166 10.9024 11.6834 11.2929 11.2929Z"
      fill="#727C8A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.7071 11.2929C25.0977 11.6834 25.0977 12.3166 24.7071 12.7071L12.7071 24.7071C12.3166 25.0977 11.6834 25.0977 11.2929 24.7071C10.9024 24.3166 10.9024 23.6835 11.2929 23.2929L23.2929 11.2929C23.6835 10.9024 24.3166 10.9024 24.7071 11.2929Z"
      fill="#727C8A"
    />
  </svg>
);

export const NewCommentIcon = ({ number }: { number: number }) => (
  <div>
    <svg
      width="57"
      height="37"
      viewBox="0 0 57 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.2199 31.4875L32.2187 31.489L29.4687 35.1374L29.468 35.1383C28.9435 35.8376 28.2863 36.1483 27.668 36.1483C27.0496 36.1483 26.3924 35.8376 25.868 35.1383L25.8672 35.1374L23.1172 31.489L23.1173 31.489L23.1136 31.4843C22.8999 31.2078 22.5903 30.9815 22.277 30.8249C21.9646 30.6687 21.5985 30.5567 21.2513 30.5567H9.16667C4.38085 30.5567 0.5 26.6902 0.5 21.9267V20.0933V9.13C0.5 4.36643 4.38085 0.5 9.16667 0.5H47.1666C51.9525 0.5 55.8333 4.36643 55.8333 9.13V21.9267C55.8333 26.6902 51.9525 30.5567 47.1666 30.5567H34.0846C33.3577 30.5567 32.6613 30.9066 32.2199 31.4875Z"
        fill="white"
        stroke="#00C1D5"
      />
    </svg>
    <span
      style={{
        position: "absolute",
        top: "45%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        fontWeight: 'bold',
        color: '#00C1D5'
      }}
    >
      {number}
    </span>
  </div>
);
