import { formatCurrency2 } from "helpers";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DAService from "services/DAService";
import styled from "styled-components";
import { romanize } from "utils/helper";
import "./style.css";

type Props = {};

const TextSpan = styled.span`
  background-color: transparent;
  color: ${(props) => (props?.color ? props?.color : "#222a35")};
  font-family: Calibri, sans-serif;
  font-size: 10pt;
  font-variant: normal;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
`;

const TextPTag = styled.p`
  line-height: 1.2;
  margin-bottom: 0pt;
  margin-top: 0pt;
  text-align: justify;
`;

const NonPatient = (props: Props) => {
  const { id } = useParams();

  const [data, setData] = useState<any>();

  const initDataPreAuth = (dataCase: any) => {
    return [
      {
        name: "Special comment to LA",
        value: dataCase?.special_comment_to_la,
        isHasPrice: false,
      },
      {
        name: "General exclusion",
        value: dataCase?.general_exclusion,
        isHasPrice: true,
        description: dataCase?.desctiption_general_exclusion,
      },
      {
        name: "Policy exclusion",
        value: dataCase?.policy_exclusion,
        isHasPrice: true,
        description: dataCase?.desctiption_policy_exclusion,
      },
      {
        name: "Pro-ration",
        value: dataCase?.proration,
        isHasPrice: true,
        description: dataCase?.description_proration,
      },
      {
        name: "Exceed policy benefit capped limit (for Non-As-Charged policy)",
        value: dataCase?.non_as_charged,
        isHasPrice: true,
        description: dataCase?.desctiption_non_as_charged,
      },
      {
        name: "Deductible (if no rider)",
        value: dataCase?.or_deductible,
        isHasPrice: true,
        description: dataCase?.description_or_deductible,
      },
      {
        name: "10% co-insurance (if no rider and deductibles fully met)",
        value: dataCase?.co_insurance_alway,
        isHasPrice: true,
        description: dataCase?.description_co_insurance_alway,
      },
      {
        name: "5% co-payment (capped at $3000)",
        value: dataCase?.co_payment,
        isHasPrice: true,
        description: dataCase?.description_co_payment,
      },
      {
        name: "Co-payment (capped at $3000)",
        value: dataCase?.co_payment_3rd,
        isHasPrice: true,
        description: dataCase?.description_co_payment_3rd,
      },
      {
        name: "Deductible (Rider covers 95% of Deductible)",
        value: dataCase?.deductiable_rider_cover,
        isHasPrice: true,
        description: dataCase?.desctiption_deductiable_rider_cover,
      },
      {
        name: "Deductible",
        value: dataCase?.deductiable_rider,
        isHasPrice: true,
        description: dataCase?.desctiption_deductiable_rider,
      },
      {
        name: "Co-Insurance (Rider covers 50% of Co-insurance)",
        value: dataCase?.co_insurance_rider_cover,
        isHasPrice: true,
        description: dataCase?.desctiption_co_insurance_rider_cover,
      },
    ]?.filter((item) => item?.value || item?.description);
  };

  useEffect(() => {
    const root: any = document?.querySelector("#root");
    if (root) {
      root.style.background = "#fff";
    }
    if (!id) return;
    (async () => {
      const data = await DAService.assessor.getDataFileNonClinic(id);
      const originData = {
        ...(data?.data?.data ?? {}),
      };
      setData(originData);
    })();
  }, [id]);

  return (
    <div
      style={{
        background: "#fff",
        width: 800,
        padding: "80px 78px 0 78px",
        margin: "0 auto",
      }}
    >
      <TextPTag>
        <TextSpan>Dear Sir / Mdm,</TextSpan>
      </TextPTag>
      <p className="mt-3 mb-0">
        <TextSpan>
          Please be informed that your admission stated in the subject title has
          been pre-authorized.
        </TextSpan>
      </p>
      <p className="mt-3 mb-0">
        <TextSpan>Please note on the following:</TextSpan>
      </p>
      <ol className="mb-0 mt-0 px-9" style={{ listStyleType: "decimal" }}>
        <li style={{ paddingLeft: "16px" }}>
          <TextSpan>
            We have attached with this email the Certificate of
            Pre-Authorisation (CPA) covering the admission. The CPA has been
            encrypted with password with your last 4 characters of your NRIC and
            your birth date in the DDMMYYYY format.
            <br />
            (Example: 123A01012022)
          </TextSpan>
        </li>
        <br />
        <li style={{ paddingLeft: "16px" }}>
          <TextSpan>
            A copy of this Certificate of Pre-Authorisation (CPA) has been
            emailed to hospital business office today.
          </TextSpan>
        </li>
        <br />
        <li style={{ paddingLeft: "16px" }}>
          <TextSpan>
            <strong>
              Please bring along a copy of the Certificate of Pre-Authorisation
              (CPA) for quick reference during the admission.
            </strong>
          </TextSpan>
        </li>
        <br />
        <li style={{ paddingLeft: "16px" }}>
          <TextSpan>
            <strong>For Singaporeans/ Permanent Residents:</strong>
          </TextSpan>
          <br />
          <TextSpan>
            Do remind the hospital staff to activate your Integrated
            shield/Medishield and sign MCAF (Medical Claims Authorisation Form)
            during admission so that the CPA can be processed.
            <br />
          </TextSpan>
          <br />
          <TextSpan className="mt-3">
            <strong>For Foreigners:</strong>
          </TextSpan>
          <br />
          <TextSpan>
            Please submit your claim to GE Claims Department directly after you
            have received the finalised tax invoice and discharge summary from
            the hospital. Do visit Great Eastern’s website for the claim
            documents required.
          </TextSpan>
        </li>
        <br />
        <li style={{ paddingLeft: "16px" }}>
          <TextSpan>
            <strong>
            The CPA may be reviewed again and revised accordingly should we be informed or made aware of any changes such as 
            but not limited to changes to the Policy terms and conditions, surgery/admission date, procedures and cost after 
            our issuance of the attached CPA and prior to the patient's discharge. It will be revised according to the Policy terms and conditions, including any endorsements.
            </strong>
          </TextSpan>
        </li>
        <br />

        <li style={{ paddingLeft: "16px" }}>
          <TextSpan>
            <strong>
              Please refer to the following for non-preauthorised item(s):
            </strong>
          </TextSpan>
          <br />
          <ol>
            {initDataPreAuth(data)?.map((item, index) => (
              <li className="mb-0 mt-0 flex">
                {item?.isHasPrice && (
                  <span
                    style={{
                      color: "#4472c3",
                      fontSize: 13,
                      minWidth: 20,
                      display: "inline-block",
                    }}
                  >
                    {romanize(
                      initDataPreAuth(data)[0]?.isHasPrice ? index + 1 : index
                    )}
                    .
                  </span>
                )}

                {item.isHasPrice ? (
                  <TextSpan color="#4472c3" className=" inline-block">
                    {item?.name ?? ""} : {formatCurrency2(item?.value)}
                    <span>{`${
                      item?.description ? " - " + item?.description : ""
                    }`}</span>
                  </TextSpan>
                ) : (
                  <TextSpan color="#4472c3" style={{ whiteSpace: "pre-wrap" }}>
                    {item?.value}
                  </TextSpan>
                )}
              </li>
            ))}
          </ol>
        </li>
        <br />

        <li style={{ paddingLeft: "16px" }}>
          <TextSpan>
            Please also be informed that the claims review is dependent on
            whether the treatment, medical service and/or supplies is Medically
            Necessary or falls within the scope of the policy general exclusions
            as extracted below (non-exhaustive).&nbsp;
            <strong>
              A copy of the complete list of policy exclusions has been enclosed
              for your ease of reference.
            </strong>
            <br />
          </TextSpan>
          <br />
          <i>
            <TextSpan>
              <strong>1.1.33 Medically Necessary </strong><br />Medically Necessary refers to
              treatments, medical services and/or supplies which, in the
              Company’s opinion, are:
            </TextSpan>
          </i>
          <br />
          <i>
            <TextSpan>(a) pursuant to an order of a Medical Doctor;</TextSpan>
          </i>
          <br />
          <i>
            <TextSpan>
              (b) consistent with the diagnosis and customary medical treatment
              for a covered illness, disease or injury in accordance with
              generally accepted medical practice in Singapore;
            </TextSpan>
          </i>
          <br />
          <i>
            <TextSpan>
              (c) in accordance with the standards of good medical practice,
              consistent with current standard of professional medical care, and
              of proven medical benefits;
            </TextSpan>
          </i>
          <br />
          <i>
            <TextSpan>
              (d) approved by the Institutional Review Board, the Centre of Medical Device Regulation, 
              Health Sciences Authority (HSA) or other relevant authority in Singapore;
            </TextSpan>
          </i>
          <br />
          <i>
            <TextSpan>
              (e) not purely for the convenience of the Life Assured or the
              Medical Doctor, and unable to be reasonably rendered in an
              outpatient setting if admitted as an inpatient;
            </TextSpan>
          </i>
          <br />
          <i>
            <TextSpan>
              (f) not of an experimental or research nature (including but not
              limited to experimental, pioneering medical or surgical techniques
              and medical devices);
            </TextSpan>
          </i>
          <br />
          <i>
            <TextSpan>
              (g) as regards to medicinal products, not on medical trials
              whether or not these trials have a clinical trial certificate
              issued by HSA or other relevant authority in Singapore; and
            </TextSpan>
          </i>
          <br />
          <i>
            <TextSpan>
              (h) not for Primary Prevention or for health enhancement
              (including but not limited to dietary replacement or supplement)
              in purpose.
            </TextSpan>
          </i>
          <br />
          <i>
            <TextSpan>
              The Company has the discretion to determine whether or not a
              treatment, medical service and/or supply is Medically Necessary.
            </TextSpan>
          </i>
          <br />
          <br />
          <i className="mt-3">
            <TextSpan><strong>4.1 General Exclusions</strong></TextSpan>
          </i>
          <br />
          <i>
            <TextSpan>
              Under this Policy, the Company will not reimburse any Expenses
              incurred in respect of or for the consequences of, the following:
            </TextSpan>
          </i>
          <br />
          <i>
            <TextSpan>
            (k) Purchase and rental of items (unless such item satisfies the definition of a Surgical Implant/ Approved Medical Consumables)
            </TextSpan>
          </i>
          <br />
          <i>
            <TextSpan>
            (w) Routine physical or any other examinations which are solely for the purposes of Primary Prevention or any preventive measures which are not also therapeutic in nature to prevent illness or disease.
            </TextSpan>
          </i>
          <br />
          <i>
            <TextSpan>
            (aa) Correction for refractive errors of the eye (including the use of artificial lens implant), 
            routine eye and eye examinations, costs of spectacles, costs of contact lenses and costs of hearing aids.
            </TextSpan>
          </i>
          <br />
          <i>
            <TextSpan>
            (bb) Non-medical items including but not limited to, parking fees, hospital administration and registration fees, 
            laundry, rental of television, newspaper, medical report fees, personal care and hygiene products, regardless of whether it is Medically Necessary or otherwise.
            </TextSpan>
          </i>
        </li>
      </ol>

      <p className="mt-3 mb-0" dir="ltr">
        <TextSpan>
          Please feel free to contact us if you encounter any problems during
          admission or during discharge.
        </TextSpan>
      </p>
      <p className="mt-0 mb-0" dir="ltr">
        <TextSpan>Thank you and we wish you a speedy recovery.</TextSpan>
      </p>
      <p className="mt-4 mb-0" dir="ltr">
        <TextSpan color="#000">
          <strong>Warmest Regards,</strong>
        </TextSpan>
      </p>
      <p className="mt-0 mb-0" dir="ltr">
        <TextSpan color="#3b3838">
          <strong>{data?.assessor_name}</strong>
        </TextSpan>
      </p>
      <p className="mt-0 mb-0" dir="ltr">
        <TextSpan color="#000">Health Connect Team</TextSpan>
      </p>
      <p className="mt-0 mb-0" dir="ltr">
        <TextSpan color="#000">Great Eastern Life Assurance Co Ltd</TextSpan>
      </p>
      <p className="mt-0 mb-0" dir="ltr">
        <TextSpan color="#000">General Helpline (+65) 6563 2233</TextSpan>
      </p>
    </div>
  );
};

export default NonPatient;
