import { InboxOutlined } from "@ant-design/icons";
import { Button, ModalProps, notification, Progress, Upload } from "antd";
// @ts-ignore
import { parse } from "papaparse";
import React, { useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as DrawerIcon } from "../../assets/dragger.svg";
import { ReactComponent as CsvIcon } from "../../assets/csv.svg";
import Modal from "components/Common/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  selectConfigSelector,
  setIsCurrentImporting,
  setProcessed,
} from "containers/Configuration/configSlice";
import { MAX_SIZE_UPLOAD } from "constants/epac.constans";

interface IProps {
  onFinish?: (data: any[], file: any, callback?: any) => void;
  loading?: boolean;
}

const ModalUpload = (props: IProps & ModalProps) => {
  const config = useSelector(selectConfigSelector);
  const [file, setFile] = React.useState<File>();
  const [data, setData] = React.useState<any>([]);
  const [percent, setPercent] = React.useState<any>({
    loaded: 0,
    percent: 0,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.visible) {
      setFile(undefined);
      setData([]);
      setPercent({ loaded: 0, percent: 0 });
      dispatch(setProcessed(false));
      dispatch(setIsCurrentImporting(false));
    }
  }, [props.visible]);

  useEffect(() => {
    if (data && file) {
      handleUpload();
    }
  }, [data, file]);

  const handleChange = async (info: any) => {
    setPercent({
      loaded: 0,
      percent: 0,
    });
    setFile(undefined);
    setData([]);

    const { file } = info;

    if (file.size > MAX_SIZE_UPLOAD * 1024 * 1024) {
      notification.error({
        message: "Error",
        description: "File size for upload must not exceed " + MAX_SIZE_UPLOAD + "MB",
      });
      return;
    }
    
    if (!file.name.includes(".csv")) {
      notification.error({
        message: "Error",
        description: "Only CSV file is allowed",
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e: any) => {
      const { data: parseData, errors } = parse(e.target.result);

      if (errors.length) {
        notification.error({
          message: "Error",
          description: "Require minimum 1 record",
        });
        reader.abort();
        return;
      }

      setPercent({
        loaded: 0,
        percent: 0,
      });
      setData(parseData);
      setFile(file.originFileObj);
    };
    reader.readAsText(file.originFileObj);
  };

  const inprogressFile = (event?: any) => {
    if (file) {
      let loaded = event.loaded;
      const percent = Math.ceil((loaded / file.size) * 100);
      setPercent({ loaded, percent });
    }
  };

  const handleUpload = () => {
    setPercent({
      loaded: 0,
      percent: 0,
    });

    if (props.onFinish) {
      const newData = JSON.parse(JSON.stringify(data));
      props.onFinish(newData, file, inprogressFile);
      dispatch(setIsCurrentImporting(true));
    }
  };

  const humanFileSize = (size: number) => {
    if (size === 0) return "0 B";

    var i = Math.floor(Math.log(size) / Math.log(1024));
    return (
      ((size / Math.pow(1024, i)) * 1).toFixed(2) +
      " " +
      ["B", "kB", "MB", "GB", "TB"][i]
    );
  };
  const maxSizeUpload = MAX_SIZE_UPLOAD;

  return (
    <Modal footer={null} {...props} title={null}>
      <Title className="mb-5">Upload file</Title>
      <Dragger
        multiple={false}
        showUploadList={false}
        customRequest={() => {}}
        onChange={handleChange}
      >
        <p className="ant-upload-drag-icon">
          <DrawerIcon />
        </p>
        <p className="ant-upload-text">
          Drag & Drop or <span style={{ color: "#55BED2" }}>browse</span> your
          files
        </p>
        <i style={{ color: "#727C8A" }}>
          Only CSV files with maximum of <b>{maxSizeUpload}MB</b>.
        </i>
      </Dragger>
      {file && (
        <FileItem>
          <div className="mr-10">
            <CsvIcon />
          </div>
          <div className="flex-1 flex flex-col" style={{ overflow: "hidden" }}>
            <FileItemName>{file.name}</FileItemName>
            <FileItemProgress
              strokeColor={"#00C1D5"}
              percent={percent.percent}
              showInfo={false}
            />
            <FileItemSize>
              {humanFileSize(percent.loaded)} of {humanFileSize(file.size)}
            </FileItemSize>
          </div>
        </FileItem>
      )}
      <div className="mt-5 flex justify-center">
        <UploadButton
          disabled={!config.processed}
          type="primary"
          onClick={props.onCancel}
        >
          Done
        </UploadButton>
      </div>
    </Modal>
  );
};

const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;

const Dragger = styled(Upload.Dragger)`
  &.ant-upload.ant-upload-drag {
    height: 250px;
    border: 1px dashed #55bed2;
    background-color: #fff;
  }

  .ant-upload-drag-icon {
    display: flex;
    justify-content: center;
  }
`;

const FileItem = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
`;

const FileItemProgress = styled(Progress)`
  width: 100%;
`;
const FileItemName = styled.p`
  margin-bottom: 0;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const FileItemSize = styled.p`
  margin-bottom: 0;
  font-size: 14px;
  color: #727c8a;
`;

const UploadButton = styled(Button)`
  min-height: 64px;
  background-color: #55bed2;
  font-size: 16px;
  padding-left: 60px;
  padding-right: 60px;
  border-radius: 24px;
`;

export default ModalUpload;
