import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

const initialState = {
  notifications: {
    data: [],
    unreadNumber: 0,
    total: 0,
    isOpened: false
  },
};

export const notificationsSlice = createSlice({
  name: 'noti',
  initialState,
  reducers: {
    setNotifications: (state, { payload }) => {
      state.notifications = payload;
    },
  },
});


export const { setNotifications } = notificationsSlice.actions;
export const notificationsSelector = (state:RootState) => state.notifications.notifications;
export default notificationsSlice.reducer;
