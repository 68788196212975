import React from 'react'

type Props = {}

export const FolderIcon = (props: Props) => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3053 2.74414H10.5988C10.3623 2.74414 10.1469 2.59883 10.0413 2.36445L9.56396 1.31914C9.45836 1.08477 9.24294 0.939453 9.00641 0.939453H2.09193C1.74557 0.939453 1.4668 1.24883 1.4668 1.6332V18.7613C1.4668 19.1457 1.74557 19.4551 2.09193 19.4551H20.3095C20.6558 19.4551 20.9346 19.1457 20.9346 18.7613V3.43789C20.9304 3.05352 20.6516 2.74414 20.3053 2.74414Z"
        fill="#00C1D5"
      />
      <path
        d="M21.5342 4.75391H0.862547C0.647129 4.75391 0.469727 4.95078 0.469727 5.18984V19.0133C0.469727 19.2523 0.647129 19.4492 0.862547 19.4492H21.5342C21.7496 19.4492 21.927 19.2523 21.927 19.0133V5.18984C21.927 4.95078 21.7496 4.75391 21.5342 4.75391Z"
        fill="#4DD4E2"
      />
      <path
        d="M2.35352 3.41992H20.0431V4.75586H2.35352V3.41992Z"
        fill="#EFF3F5"
      />
      <path d="M1.94824 4.08398H20.453V4.7543H1.94824V4.08398Z" fill="white" />
    </svg>
  );
}