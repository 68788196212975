/* eslint-disable jsx-a11y/anchor-is-valid */
import { SearchOutlined } from '@ant-design/icons';
import { Input, Switch, notification, Space, Tooltip, Button, Row, Col } from 'antd';
import { Form } from 'antd';
import { EditIcon } from 'components/Common/Icon';
import Pagination, { PaginationConfig } from 'components/Common/PaginationOpenSearch';
import Status from 'components/Common/Status';
import ModalUpload from 'components/Configuration/ModalUpload';
import { StyledWrapper } from 'components/Configuration/style';
import Layout from 'components/Layout';
import Loading from 'components/Loading';
import withAuth from 'helpers/withAuth';
import { AddCircle, ArrowLeft2, DocumentText, Import, Save2 } from 'iconsax-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BaseService } from 'services';
import CoreService from 'services/CoreService';
import DAService from 'services/DAService';
import styled from 'styled-components';
import { StyledButton, StyledTable } from 'styles/styled';

const Diagnosis = ({ loading, setLoading, shouldAllowExecute, ...props }: any) => {
    const navigate = useNavigate();
    const handleClickNew = () => navigate('/configuration/diagnoses/create');
    const handleClickEdit = (id: any) => navigate(`/configuration/diagnoses/edit/${id}`);

    const baseService = new BaseService(undefined, false);
    const [list, setList] = useState<any>([]);
    const [pagination, setPagination] = useState<PaginationConfig>({
        limit: 100,
        page: 1,
        total: 0,
        reset: 0,
    });
    const [visible, setVisible] = useState(false);
    const [keyword, setKeyword] = useState<any>(null);

    const columns = [
        {
            dataIndex: 'id',
            render: (id: string) => 
                <Tooltip title="Edit">
                    <Button
                        type="link"
                        icon={<EditIcon />}
                        size="small"
                        onClick={() => handleClickEdit(id)}
                    />
                </Tooltip>
        },
        {
            title: 'Diagnosis',
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: 'ICD-10',
            key: 'icd_10',
            dataIndex: 'icd_10',
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            render: (val: any) => <Status type={val === 'Active' ? 'active' : 'inactive'}>{val}</Status>,
        },
        {
            title: 'Create Date',
            key: 'createdAt',
            dataIndex: 'createdAt',
            width: 200,
            render: (val: any) => moment(val).format('DD-MMM-YYYY HH:mm:ss'),
        },
    ];

    useEffect(() => {
        fetchDiagnosis();
    }, [pagination.page, pagination.reset]);

    useEffect(() => {
        if (props.reset) {
            handleSearch();
        }
    }, [props.reset]);

    const fetchDiagnosis = async () => {
        if (loading) return;
        setLoading(true);
        try {
            const { data } = await DAService.diagnosis.getList(keyword, pagination.limit, pagination.page);
            setList(data?.data?.items || []);
            setPagination({
                ...pagination,
                ...data?.data?.page_info,
            });
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const onPageChange = (page: number, data: any = []) => {
        setPagination({
            ...pagination,
            page,
        });
    };

    const handleSearch = () => {
        setPagination({
            limit: 100,
            page: 1,
            total: 0,
            reset: Date.now(),
        });
    };

    const handleDownload = async () => {
        setLoading(true);

        if (!list.length) {
            try {
                const { data } = await DAService.diagnosis.download(!list.length);
                data?.data && window.open(data?.data, '_blank');
            } finally {
                setLoading(false);
            }
        } else {
            props.socket.send(JSON.stringify({ action: 'requestExport', type: 'diagnosis' }));
        }
    };

    const handleUpload = async (data: any[] = [], file: File, callback?: any) => {
        setLoading(true);
        try {
            const headerRow = data.shift();
            if (headerRow.length) {
                const headerKey: any = {};
                headerRow.map((key: any, index: number) => {
                    headerKey[key] = index;
                });

                const listColumns = ['Id', 'Name', 'Icd 10', 'Status', 'Created At', 'Updated At'];
                const requiredColumns = ['Name', 'Icd 10', 'Status'];
                const listErrors = [];

                for (let key of listColumns) {
                    if (!headerKey.hasOwnProperty(key)) {
                        listErrors.push(key);
                    }
                }

                if (listErrors.length) {
                    notification.error({
                        message: 'Error',
                        description: `Missing columns: ${listErrors.join(', ')}`,
                    });
                    throw new Error();
                }

                for (let i = 0; i < data.length; i++) {
                    const [...args] = data[i];
                    if (!args || !args.length || args.length < 2) {
                        continue;
                    }

                    for (let key of requiredColumns) {
                        if (!data[i][headerKey[key]]?.trim()) {
                            notification.error({
                                message: 'Error',
                                description: `Line ${i + 1}: ${key} is invalid`,
                            });
                            throw new Error();
                        }
                    }
                }
            } else {
                notification.error({
                    message: 'Require minimum 1 record',
                });
                throw new Error();
            }

            const result = await CoreService.getUrlUpload('csv', 'diagnosis');
            const reader = new FileReader();
            reader.onload = async (e: any) => {
                try {
                    props.setImportStatus(true);
                    await baseService.instance.put(result.data.data, e.target.result, {
                        headers: {
                            'Content-Type': file.type,
                        },
                        onUploadProgress: callback,
                    });
                } catch (error) {
                    setLoading(false);
                    props.setImportStatus(false);
                    notification.error({
                        message: 'Error',
                        description: 'Error while uploading CSV file',
                    });
                }
            };

            reader.readAsArrayBuffer(file);
        } catch (error: any) {
            if (error?.data?.message) {
                notification.error({
                    message: 'Error',
                    description: error.data.message,
                });
            }
            setLoading(false);
        }
    };

    return (
        <>
            {loading ? <Loading /> : null}
            <div className="flex justify-between mb-10 flex-wrap">
                <Space size={10} align="center" wrap className="mt-5">
                    {shouldAllowExecute && (
                        <StyledButton
                            shape="round"
                            type="primary"
                            icon={<Import />}
                            disabled={props.importStatus?.is_processing}
                            onClick={() => setVisible(true)}
                        >
                            Import
                        </StyledButton>
                    )}
                    {!loading && (
                        <StyledButton
                            type="text"
                            shape="round"
                            onClick={handleDownload}
                            icon={list.length ? <Save2 /> : <DocumentText />}
                        >
                            Download
                        </StyledButton>
                    )}
                    {props.error ? (
                        props.importStatus?.is_processing ? (
                            <p className="m-0">Importing...</p>
                        ) : (
                            <p className="m-0">
                                Last import date: {moment(props.error.create_date).format('DD-MMM-YYYY HH:mm:ss')},
                                <a className="ml-1" href="#" onClick={props.showErrorPage}>
                                    {props.error.data.fail} import errors.
                                </a>
                            </p>
                        )
                    ) : null}
                </Space>
                <Space size={15} align="start" wrap className="mt-5">
                    <StyledButton
                        shape="round"
                        type="primary"
                        icon={<AddCircle size={18} />}
                        onClick={handleClickNew}
                    >
                        Create New
                    </StyledButton>
                    <Input
                        prefix={<SearchOutlined size={18} />}
                        style={{ borderRadius: 16 }}
                        value={keyword || ''}
                        placeholder="Search"
                        onChange={(event) => setKeyword(event.target.value)}
                    />
                    <StyledButton shape="round" onClick={handleSearch}>
                        Search
                    </StyledButton>
                </Space>
            </div>
            <StyledWrapper>
                <StyledTable columns={columns} dataSource={list} pagination={false} />
                <div className="flex justify-end mt-5">
                    <Pagination pagination={pagination} onChange={onPageChange} />
                </div>
            </StyledWrapper>
            <ModalUpload
                visible={visible}
                title="Upload"
                loading={loading}
                onCancel={() => setVisible(false)}
                onFinish={handleUpload}
            />
        </>
    );
};

export const Edit = withAuth(() => {
    const { id } = useParams<{ id: string}>();
    const [form] = Form.useForm();

    const navigate = useNavigate();
    const handleClickBack = (reload = false) => {
        const pathname = '/configuration/diagnosis';
        if (reload) window.location.pathname = pathname
        else navigate(pathname);
    }
    const handleClickSave = () => form.submit();

    const handleFinish = async (value: any) => {
        try {
            const method = id ? 'update' : 'create'
            const { data } = await DAService.diagnosis[method]({ 
                ...value,
                ...id && { id },
                status: value.status ? "Active" : "Inactive"
            });
            if (data.code == 200) {
                notification.success({
                    message: "Success",
                    description: `${id ? "Update" : "Create"} Diagnosis successfully`,
                });
                handleClickBack(true);
            } else {
                notification.error({
                    message: "Error",
                    description: data.data.error ?? "Something went wrong"
                });
            }
        } catch (error: any) {
            notification.error({
                message: "Error",
                description: error.data
                    ? Array.isArray(error.data.message)
                        ? error.data.message[0]
                        : error.data.message
                    : error.message,
            });
        }
    }

    useEffect(() => {
        async function load(id: string) {
            const { data: { data } = {} } = await DAService.diagnosis.get(id);
            form.setFieldsValue({ ...data, status: data.status === "Active" })
        }

        if (id) load(id)
    }, [])

    return <Layout isHasHeader={false}>
        <EditLayout>
            <Row gutter={24} className="back-action">
                <Col span={6} className="flex">
                    <div onClick={() => handleClickBack()} className="flex inline-flex">
                        <ArrowLeft2
                            size={14}
                            color={'#727C8A'}
                        />
                        <div className="inline-block icon-name">Back</div>
                    </div>
                </Col>
                <Col span={18} className="flex text-right">
                    <Button
                        className="rounded-full absolute right-0 btn-add"
                        type="primary"
                        key="submit"
                        onClick={handleClickSave}
                    >
                        Save
                    </Button>
                </Col>
            </Row>
            <Row>
                <Form
                    form={form}
                    onFinish={handleFinish}
                    requiredMark={false}
                    layout="inline"
                >
                    <Form.Item
                        name="name"
                        label="Dianogsis"
                        rules={[
                            {   message: "Please input Name",
                                required: true
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="icd_10"
                        label="ICD 10"
                        rules={[
                            {   message: "Please input ICD 10",
                                required: true
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="status"
                        label="Status"
                        valuePropName="checked"
                        rules={[
                            {   message: "Please input Status",
                                required: true
                            }
                        ]}
                    >
                        <Switch/>
                    </Form.Item>
                </Form>
            </Row>
        </EditLayout>
    </Layout>
})

const EditLayout = styled.div`
  min-height: 95px;
  background-color: #fff;
  border-radius: 16px;
  padding: 16px 28px;

  .ant-row {

    &.back-action {
      .icon-name {
        line-height: 12px;
        margin-left: 12px;
        cursor: pointer;
        color: #727C8A;
        &:hover {
          font-weight: 500;
          color: #333333;
        }
      }

      margin-bottom: 27px;
    }

    &.back-action {
      background-color: transparent;
      border-radius: 0;

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #727C8A;
      }
    }
  }

  .ant-form-item-label {
    text-align: left;
    width: 100%;
  }
`;


export default Diagnosis
