import React from 'react'

type Props = {}

export const DeleteIcon2 = (props: Props) => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="36" height="36" rx="8" fill="#FFEEEE"/>
    <path d="M27 11.9805C23.67 11.6505 20.32 11.4805 16.98 11.4805C15 11.4805 13.02 11.5805 11.04 11.7805L9 11.9805" stroke="#F04646" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.5 10.97L14.72 9.66C14.88 8.71 15 8 16.69 8H19.31C21 8 21.13 8.75 21.28 9.67L21.5 10.97" stroke="#F04646" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24.8504 15.1406L24.2004 25.2106C24.0904 26.7806 24.0004 28.0006 21.2104 28.0006H14.7904C12.0004 28.0006 11.9104 26.7806 11.8004 25.2106L11.1504 15.1406" stroke="#F04646" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.3301 22.5H19.6601" stroke="#F04646" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.5 18.5H20.5" stroke="#F04646" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}
