import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

type Props = {};

const PreviewFile = (props: Props) => {
  //   const location = useLocation();
  //   const state = location.state;
  //   if (!state) return null;
  const [url, setUrl] = useState("");
  const { name } = useParams();
  useEffect(() => {
    if (!name || name === "undefined" || name === "null") return;
    const data = localStorage.getItem(name);
    const currentData = JSON.parse(data ?? "{}");
    if (!currentData?.isView) {
      if (currentData?.name) {
        setUrl(currentData?.name + `?random=${Math.random()}`);
      }
      localStorage.removeItem(name);
    } else {
      localStorage.removeItem(name);
    }
  }, [name]);
  return url ? (
    <div style={{ width: "100vw", height: "100vh" }}>
      <iframe
        width="100%"
        height="100%"
        title="preview file"
        src={url as string}
      ></iframe>
    </div>
  ) : null;
};

export default PreviewFile;
