import Loading from "components/Loading";
import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DAService from "../services/DAService";
import { GROUP_NAME, ROLE_USER } from "../constants";
import { selectUserSelector, setUserInfo } from "../containers/Auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Archive from "containers/Master/archive";
import AssessmentPDF from "components/assessor/claim/AssessmentPDF/AssessmentPDF";
import Nonclinic from "containers/Nonclinic";
import Nonpatient from "containers/NonPatient";
import PreviewFile from "containers/PreviewFile";
import PdfOptionNo from "containers/PdfOptionNo";
import Allocation from "containers/Allocation";
import { Edit as DiagnosisCreateUpdate } from "containers/Configuration/Diagnosis";
import Announcement from "containers/Announcement";

const App = React.lazy(() => import("App"));
const Login = React.lazy(() => import("containers/Auth/Login"));
const AssessmentPatient = React.lazy(
  () => import("../containers/assessment/patient")
);
const AssessmentClaim = React.lazy(() => import("containers/assessment/claim"));
const HistoryPAC = React.lazy(() => import("containers/archive/historyPAC"));
const HistoryPACDetail = React.lazy(
  () => import("containers/assessment/historyPacClaimDetail")
);
const HistoryPacPatient = React.lazy(
  () => import("containers/assessment/historyPacPatient")
);
const HistoryPacDoctor = React.lazy(
  () => import("containers/assessment/historyPacDoctor")
);
const AssessmentDoctor = React.lazy(
  () => import("../containers/assessment/doctor")
);

const Epac = React.lazy(() => import("../containers/Epac/Epac.main"))
const SectionA = React.lazy(() => import("../containers/Epac/Epac.SectionA"))
const SectionB = React.lazy(() => import("../containers/Epac/Epac.SectionB"))
const SectionC = React.lazy(() => import("../containers/Epac/Epac.SectionC"))
const SignUp = React.lazy(() => import("../containers/Auth/SignUp"));
const ForgotPassword = React.lazy(
  () => import("../containers/Auth/ForgotPassword")
);

const Profile = React.lazy(() => import("../containers/User/Profile"));
// const User = React.lazy(() => import("containers/User"));
// const Group = React.lazy(() => import("containers/User/Group"));

const Configuration = React.lazy(() => import("../containers/Configuration"));

const Provider = React.lazy(() => import("../containers/Provider"));

const Doctor = React.lazy(() => import("../containers/Doctor"));

const Holiday = React.lazy(() => import("../containers/Holiday"));

const ProviderDetail = React.lazy(() => import("containers/Provider/Detail"));

const Policy = React.lazy(() => import("../containers/Policy"));

const Admin = React.lazy(() => import("../containers/Admin"));

const AllocationDetail = React.lazy(
  () => import("containers/Allocation/Detail")
);

const TOSPCreateUpdate = React.lazy(
  () => import("containers/Configuration/TOSPCreateUpdate")
);

const Analytic = React.lazy(() => import("../containers/Analytic"));
const AnnouncementForm = React.lazy(() => import("containers/Announcement/form"));
const AnnouncementDetails = React.lazy(() => import("containers/Announcement/details"));

function AppRouter() {
  const user = useSelector(selectUserSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    if (user && !user?.id && user.idToken && !user?.permission) {
      DAService.admin.profile().then((value) => {
        if (value.status === 200 && value.data?.data) {
          const profile = value.data?.data || {};
          if (profile.group == GROUP_NAME.ADMINISTRATORS)
            profile.role = ROLE_USER.SUPPER_ADMIN;
          dispatch(
            setUserInfo({
              ...user,
              ...profile,
            })
          );
        }
      });
    }
  }, [user]);

  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Routes>
          <Route path="configuration">
            <Route path=":tab" element={<Configuration />} />

            <Route path="diagnoses">
              <Route path="create" element={<DiagnosisCreateUpdate />} />
              <Route path="edit/:id" element={<DiagnosisCreateUpdate />} />
            </Route>

            <Route path="tosps">
              <Route path="create" element={<TOSPCreateUpdate />} />
              <Route path="edit/:id" element={<TOSPCreateUpdate />} />
            </Route>

            <Route index element={<Configuration />} />
          </Route>

          <Route path="policy">
            <Route index element={<Policy />} />
          </Route>
          <Route path="providers">
            <Route index element={<Provider />} />
          </Route>
          <Route path="providers">
            <Route path="detail/:id" element={<ProviderDetail />} />
          </Route>
          <Route path="teams">
            <Route index element={<Admin />} />
          </Route>
          <Route path="doctors">
            <Route index element={<Doctor />} />
          </Route>
          {/* DISABLE HOLIDAY FEATURE */}

          {/*<Route path="holiday">
            <Route index element={<Holiday />} />
          </Route>*/}
          <Route path="epac">
            <Route
              path="section-a/:case_id"
              element={
                <Epac>
                  <SectionA />
                </Epac>
              }
            />
            <Route
              path="section-b/:case_id"
              element={
                <Epac>
                  <SectionB />
                </Epac>
              }
            />
            <Route
              path="section-c/:case_id"
              element={
                <Epac>
                  <SectionC />
                </Epac>
              }
            />
          </Route>
          <Route path="holiday">
            <Route index element={<Holiday />} />
          </Route>
          <Route path="allocations">
            <Route index element={<Allocation />} />
          </Route>
          <Route path="allocations">
            <Route path="create" element={<AllocationDetail />} />
            <Route path="detail/:id" element={<AllocationDetail />} />
          </Route>
          <Route path="announcement" element={<Announcement />} />
          <Route path="announcement">
            <Route path="create" element={<AnnouncementForm />} />
            <Route path="edit/:id" element={<AnnouncementForm />} />
            <Route path="view/:id" element={<AnnouncementDetails />} />
          </Route>
          <Route path="analytics">
            <Route index element={<Analytic />} />
          </Route>
          <Route path="users">
            <Route path="profile" element={<Profile />} />
            {/* <Route path="groups" element={<Group />} /> */}
            {/* <Route index element={<User />} /> */}
          </Route>
          <Route path="assessment">
            <Route path="claim/:id" element={<AssessmentClaim />} />
            <Route path="patient/:id" element={<AssessmentPatient />} />
            <Route path="doctor/:id" element={<AssessmentDoctor />} />
          </Route>
          <Route path="history-pac-detail">
            <Route path="claim/:id" element={<HistoryPACDetail />} />
            <Route path="patient/:id" element={<HistoryPacPatient />} />
            <Route path="doctor/:id" element={<HistoryPacDoctor />} />
          </Route>
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="archive" element={<Archive />} />
          <Route path="archive">
            <Route path="claim/:id" element={<AssessmentClaim />} />
            <Route path="patient/:id" element={<AssessmentPatient />} />
            <Route path="doctor/:id" element={<AssessmentDoctor />} />
            <Route path="history-pac/:id" element={<HistoryPAC />} />
          </Route>
          <Route
            path="assessment-file/:id"
            element={<AssessmentPDF isHistory={false} />}
          />
          <Route path="non-clinic/:id" element={<Nonclinic />} />
          <Route path="non-patient/:id" element={<Nonpatient />} />
          <Route path="pdf-option-no/:id" element={<PdfOptionNo />} />
          <Route path="preview-file/:name" element={<PreviewFile />} />
          <Route index element={<App />} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default AppRouter;
