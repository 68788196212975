import { BaseService } from "services";
import { SERVICES } from "../constants";

const baseService = new BaseService(SERVICES.CORE_SERVICE_URL);

export default {
  upload: (formData: any, params: any) =>
    baseService.instance.post("/upload", formData, { params }),
  getUrlUpload: (extension: string, folder: string, params?: any) =>
    baseService.instance.get("/upload-url", {
      params: {
        extension,
        folder,
        ...params,
      },
    }),
};
