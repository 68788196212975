import { Col, Input, Row } from "antd";
import { AmbulanceIcon } from "components/icons/ambulanceIcon";
import SpaceView from "components/Space";
import styled from "styled-components";
import { getAgeFromBirthday, formatPhoneCode } from "utils/helper";
import { Center, ContentSection1, ContentSection2, LabelSection1 } from "../../styled";
import { formatDate } from "helpers";
import moment from "moment";
import "./styles.css"

import { useSelector } from "react-redux";
import { selectUserSelector } from "containers/Auth/authSlice";
import { usePermissionClaim } from "hooks/usePermissionClaim";
import PatientTable from "./PatientTable";
const { TextArea } = Input;

type Props = {
    data: dataType;
    assessorName: string,
    approvedName: string,
    setData: any;
    claimStatus: any;
    isHistory?: boolean;
};

type dataType = {
    case_id?: string;
    nric_no?: string;
    policy_id?: string;
    dob?: string;
    phone_number?: string;
    email?: string;
    full_name?: string;
    nationality?: string;
    company_name?: string;
    hr_contact?: string;
    info_warranty_sign?: string;
    life_assured_sign?: string;
    home_tel_number?: string;
    inpatient_coverage?: string;
    createdAt: string;
    last_similar_related_claim?: any;
    lastest_reinstatement?: any;
    exclusion?: any[];
    gsh_info: {
        inception_date: string;
        next_premium_due_date: string;
        plan_type: string;
        policy_duration: string;
        policy_no: string;
        remark?: string;
        policy_no_remark?: string;
        inception_date_remark?: string;
        policy_duration_remark?: string;
        caveat_remark?: string;
        exclusion?: any[];
        exclusion_remark?: string;
        last_similar_related_claim_remark?: string;
        next_premium_due_date_remark?: string;
        exclusion_start_date_remark?: string;
        exclusion_end_date_remark?: string;
    };
    gtc_info: {
        inception_date: string;
        next_premium_due_date: string;
        plan_type: string;
        policy_duration: string;
        policy_no: string;
        remark?: string;
        policy_no_remark?: string;
        inception_date_remark?: string;
        policy_duration_remark?: string;
        caveat_remark?: string;
        exclusion?: any[];
        exclusion_remark?: string;
        last_similar_related_claim_remark?: string;
        next_premium_due_date_remark?: string;
        exclusion_start_date_remark?: string;
        exclusion_end_date_remark?: string;
    };
};

const PatientInfomation = ({ data, assessorName, approvedName, setData, claimStatus, isHistory = false }: Props) => {
    const handleChangeInputRemark = (
        scope: string,
        fieldName: string,
        event: any
    ) => {
        setData((prevData: any) => ({
            ...prevData,
            [scope]: { ...prevData[scope], [fieldName]: event.target.value },
        }));
    };

    const user = useSelector(selectUserSelector);
    const { isBlockEdit } = usePermissionClaim({
        claimStatus: claimStatus,
        user: user,
        isHistory,
    });
    const defaultEmptyRemarks = "-";
    const getDefaultValue = (value: string | undefined) => {
        return value == defaultEmptyRemarks ? "" : value;
    }

    const gtcExclusion = data?.gtc_info?.exclusion?.length
        ? data?.gtc_info?.exclusion
        : data?.exclusion?.length ? data?.exclusion : null       

    const gshExclusion = data?.gsh_info?.exclusion?.length
        ? data?.gsh_info?.exclusion
        : data?.exclusion?.length ? data?.exclusion : null  

    return (
        <div className="">
            <Row>
                <Col span={6}>
                    <LabelSection1>Name (Same as NRIC/ Passport)</LabelSection1>
                    <SpaceView size={8} />
                    <ContentSection1>{data?.full_name}</ContentSection1>
                </Col>
                <Col span={6}>
                    <LabelSection1>Age </LabelSection1>
                    <SpaceView size={8} />
                    <ContentSection1>
                        {getAgeFromBirthday(data?.dob || "--")}
                    </ContentSection1>
                </Col>
                <Col span={6}>
                    <LabelSection1>DOB</LabelSection1>
                    <SpaceView size={8} />
                    <ContentSection1>
                        {moment(data?.dob, "DD/MM/YYYY").format("DD-MMM-YYYY")}
                    </ContentSection1>
                </Col>
                <Col span={6}>
                    <LabelSection1>Mobile No.</LabelSection1>
                    <SpaceView size={8} />
                    <ContentSection1>
                        {formatPhoneCode(data?.phone_number || "--")}
                    </ContentSection1>
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <Row
                        gutter={8}
                        justify="space-between"
                        style={{ height: "100%", alignItems: "center" }}
                    >
                        <Col>
                            <Center>
                                <ContentSection1 color="#000000">
                                    Prepared by:{" "}
                                </ContentSection1>
                                <ContentSection2
                                    style={{ width: "unset", paddingLeft: 6 }}
                                    color="#1B1B1B"
                                >
                                    {assessorName}
                                </ContentSection2>
                            </Center>
                        </Col>
                        <Col>
                            <Center>
                                <ContentSection1 color="#000000">
                                    Approved by:{" "}
                                </ContentSection1>
                                <ContentSection2
                                    style={{ width: "unset", paddingLeft: 6 }}
                                    color="#000000"
                                >
                                    {approvedName ? approvedName : "--"}
                                </ContentSection2>
                            </Center>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <SpaceView size={48} />
            <Row gutter={8}>
                <Col span={7}>
                    <Center
                        style={{
                            justifyContent: "flex-start",
                            height: 70,
                            paddingLeft: "25px",
                            backgroundColor: "#F3F7FC",
                            borderRadius: "20px",
                        }}
                    >
                        <AmbulanceIcon />
                        <LabelSection1 style={{ marginLeft: "18px" }} color="#101820">
                            Plan Type
                        </LabelSection1>
                    </Center>
                </Col>
                <Col span={8}>
                    <Center
                        style={{
                            height: 82,
                            backgroundColor: "#EDFBFC",
                            borderTopLeftRadius: "20px",
                            borderTopRightRadius: "20px",
                        }}
                    >
                        <LabelSection1 color="#00C1D5">
                            {data?.gsh_info?.plan_type}
                        </LabelSection1>
                    </Center>
                </Col>
                <Col span={9}>
                    <Center style={{ height: 82 }}>
                        <LabelSection1>{data?.gtc_info?.plan_type}</LabelSection1>
                    </Center>
                </Col>
            </Row>

            <Row className=""
                style={{
                    borderRadius: "20px",
                    border: "4px solid #4DD4E2",
                    overflow: "hidden",
                }}
                >
                <Col span={24}>
                    {/*  */}
                    <Row gutter={8}>
                        <Col span={7}>
                            <StyledCell color="#4DD4E2">
                                <ValueCell>Policy No. (*GSH only)</ValueCell>
                            </StyledCell>
                        </Col>
                        <Col span={8}>
                            <StyledCell color="#EDFBFC">
                                <ValueCell color="#101820">
                                    {data?.gsh_info?.policy_no || "--"}
                                </ValueCell>
                            </StyledCell>
                        </Col>
                        <Col span={9}>
                            <StyledCell>
                                <ValueCell color="#101820">
                                    {data?.gtc_info?.policy_no || "--"}
                                </ValueCell>
                            </StyledCell>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={7} style={{backgroundColor:"#4DD4E2", height:"auto", transform:"translate(-4px, 0)"}} className="tungdeptrai-col">
                            
                        </Col>
                        <Col span={8}>
                            <StyledCell
                                color="#EDFBFC"
                                className="flex px-1 items-center input-remark-wraper"
                            >
                                <TextArea
                                    disabled
                                    style={{resize: "none"}}
                                    className="input-remark ml-4 tung-hehe"
                                    readOnly={isBlockEdit}
                                    onChange={(e) =>
                                        handleChangeInputRemark("gsh_info", "policy_no_remark", e)
                                    }
                                    value={getDefaultValue(data?.gsh_info?.policy_no_remark)}
                                    placeholder="Remark"
                                    autoSize = {{minRows: 1}}
                                />
                            </StyledCell>
                            <CustomHr />
                        </Col>
                        <Col span={9}>
                            <StyledCell className="flex px-1 items-center input-remark-wraper">
                                <TextArea
                                    disabled
                                    className="input-remark ml-4 tung-hehe"
                                    readOnly={isBlockEdit}
                                    onChange={(e) =>
                                        handleChangeInputRemark("gtc_info", "policy_no_remark", e)
                                    }
                                    value={getDefaultValue(data?.gtc_info?.policy_no_remark)}
                                    placeholder="Remark"
                                    autoSize = {{minRows: 1}}
                                />
                            </StyledCell>
                            <CustomHr />
                        </Col>
                    </Row>
                    {/*  */}
                    <Row gutter={8}>
                        <Col span={7}>
                            <StyledCell color="#4DD4E2">
                                <ValueCell>Inception date</ValueCell>
                            </StyledCell>
                        </Col>
                        <Col span={8}>
                            <StyledCell color="#EDFBFC">
                                <ValueCell color="#101820">
                                    {formatDate(
                                        data?.gsh_info?.inception_date,
                                        "DD-MMM-YYYY",
                                        "--",
                                        "YYYY-MM-DD"
                                    )}
                                </ValueCell>
                            </StyledCell>
                        </Col>
                        <Col span={9}>
                            <ValueCell color="#101820">
                                {formatDate(
                                    data?.gtc_info?.inception_date,
                                    "DD-MMM-YYYY",
                                    "--",
                                    "YYYY-MM-DD"
                                )}
                            </ValueCell>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                            <Col span={7} style={{backgroundColor:"#4DD4E2", height:"auto", transform:"translate(-4px, 0)"}} className="tungdeptrai-col">
                            
                            </Col>
                        <Col span={8}>
                            <StyledCell
                                color="#EDFBFC"
                                className="flex px-1 items-center input-remark-wraper"
                            >
                                <TextArea
                                    className="input-remark ml-4 tung-hehe"
                                    readOnly={isBlockEdit}
                                    onChange={(e) =>
                                        handleChangeInputRemark(
                                            "gsh_info",
                                            "inception_date_remark",
                                            e
                                        )
                                    }
                                    value={getDefaultValue(data?.gsh_info?.inception_date_remark)}
                                    placeholder="Remark"
                                    autoSize={{ minRows: 1  }}
                                />
                            </StyledCell>
                            <CustomHr />
                        </Col>
                        <Col span={9}>
                            <StyledCell className="flex px-1 items-center input-remark-wraper">
                                <TextArea
                                    className="input-remark ml-4 tung-hehe"
                                    readOnly={isBlockEdit}
                                    onChange={(e) =>
                                        handleChangeInputRemark(
                                            "gtc_info",
                                            "inception_date_remark",
                                            e
                                        )
                                    }
                                    value={getDefaultValue(data?.gtc_info?.inception_date_remark)}
                                    placeholder="Remark"
                                    autoSize={{ minRows: 1 }}
                                />
                            </StyledCell>
                            <CustomHr />
                        </Col>
                    </Row>
                    {/*  */}
                    <Row gutter={8}>
                        <Col span={7}>
                            <StyledCell color="#4DD4E2">
                                <ValueCell>Latest RI/ Upgrade</ValueCell>
                            </StyledCell>
                        </Col>
                        <Col span={8}>
                            <StyledCell color="#EDFBFC">
                                <ValueCell color="#101820">
                                    {data?.lastest_reinstatement || "--"}
                                </ValueCell>
                            </StyledCell>
                        </Col>
                        <Col span={9}>
                            <ValueCell color="#101820">
                                {data?.lastest_reinstatement || "--"}
                            </ValueCell>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                    <Col span={7} style={{backgroundColor:"#4DD4E2", height:"auto", transform:"translate(-4px, 0)"}} className="tungdeptrai-col">
                            
                            </Col>
                        <Col span={8}>
                            <StyledCell
                                color="#EDFBFC"
                                className="flex px-1 items-center input-remark-wraper"
                            >
                                <TextArea
                                    className="input-remark ml-4 tung-hehe"
                                    readOnly={isBlockEdit}
                                    onChange={(e) =>
                                        handleChangeInputRemark("gsh_info", "remark", e)
                                    }
                                    value={getDefaultValue(data?.gsh_info?.remark)}
                                    placeholder="Remark"
                                    autoSize={{ minRows: 1  }}
                                />
                            </StyledCell>
                            <CustomHr />
                        </Col>
                        <Col span={9}>
                            <StyledCell className="flex px-1 items-center input-remark-wraper">
                                <TextArea
                                    className="input-remark ml-4 tung-hehe"
                                    readOnly={isBlockEdit}
                                    onChange={(e) =>
                                        handleChangeInputRemark("gtc_info", "remark", e)
                                    }
                                    value={getDefaultValue(data?.gtc_info?.remark)}
                                    placeholder="Remark"
                                    autoSize={{ minRows: 1 }}
                                />
                            </StyledCell>
                            <CustomHr />
                        </Col>
                    </Row>
                    {/*  */}
                    <Row gutter={8}>
                        <Col span={7}>
                            <StyledCell color="#4DD4E2">
                                <ValueCell>
                                    Policy duration
                                    <br />
                                    (*Based on planned adm date)
                                </ValueCell>
                            </StyledCell>
                        </Col>
                        <Col span={8}>
                            <StyledCell color="#EDFBFC">
                                <ValueCell color="#101820">
                                    {data?.gsh_info?.policy_duration || "--"}
                                </ValueCell>
                            </StyledCell>
                        </Col>
                        <Col span={9}>
                            <ValueCell color="#101820">
                                {data?.gtc_info?.policy_duration || "--"}
                            </ValueCell>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                    <Col span={7} style={{backgroundColor:"#4DD4E2", height:"auto", transform:"translate(-4px, 0)"}} className="tungdeptrai-col">
                            
                            </Col>
                        <Col span={8}>
                            <StyledCell
                                color="#EDFBFC"
                                className="flex px-1 items-center input-remark-wraper"
                            >
                                <TextArea
                                    className="input-remark ml-4 tung-hehe"
                                    readOnly={isBlockEdit}
                                    onChange={(e) =>
                                        handleChangeInputRemark(
                                            "gsh_info",
                                            "policy_duration_remark",
                                            e
                                        )
                                    }
                                    value={getDefaultValue(data?.gsh_info?.policy_duration_remark)}
                                    placeholder="Remark"
                                    autoSize={{ minRows: 1  }}
                                />
                            </StyledCell>
                            <CustomHr />
                        </Col>
                        <Col span={9}>
                            <StyledCell className="flex px-1 items-center input-remark-wraper">
                                <TextArea
                                    className="input-remark ml-4 tung-hehe"
                                    readOnly={isBlockEdit}
                                    onChange={(e) =>
                                        handleChangeInputRemark(
                                            "gtc_info",
                                            "policy_duration_remark",
                                            e
                                        )
                                    }
                                    value={getDefaultValue(data?.gtc_info?.policy_duration_remark)}
                                    placeholder="Remark"
                                    autoSize={{ minRows: 1 }}
                                />
                            </StyledCell>
                            <CustomHr />
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={7}>
                            <StyledCell style={{ height: "100%" }} color="#4DD4E2">
                                <ValueCell>Exclusion Description</ValueCell>
                            </StyledCell>
                        </Col>
                        <Col span={8}>
                            <StyledCell style={{ height: "100%" }} color="#EDFBFC">
                                {gshExclusion?.map?.(
                                    (exclusion: any, index: number) => (
                                        <TextCell color="#101820">
                                            {exclusion?.ExclusionDescription
                                                ? `(${index + 1}) ${exclusion?.ExclusionDescription}`
                                                : "--"}
                                        </TextCell>
                                    )
                                )
                                || <TextCell color="#101820">--</TextCell>
                                }
                            </StyledCell>
                        </Col>
                        <Col span={9}>
                            {gtcExclusion?.map?.(
                                (exclusion: any, index: number) => (
                                    <TextCell color="#101820">
                                        {exclusion?.ExclusionDescription
                                            ? `(${index + 1}) ${exclusion?.ExclusionDescription}`
                                            : "--"}
                                    </TextCell>
                                )
                            )
                            || <TextCell color="#101820">--</TextCell>
                            }
                        </Col>
                    </Row>
                    <Row gutter={8}>
                    <Col span={7} style={{backgroundColor:"#4DD4E2", height:"auto", transform:"translate(-4px, 0)"}} className="tungdeptrai-col">
                            
                            </Col>
                        <Col span={8}>
                            <StyledCell
                                color="#EDFBFC"
                                className="flex px-1 items-center input-remark-wraper"
                            >
                                <TextArea
                                    className="input-remark ml-4 tung-hehe"
                                    readOnly={isBlockEdit}
                                    onChange={(e) =>
                                        handleChangeInputRemark("gsh_info", "exclusion_remark", e)
                                    }
                                    value={getDefaultValue(data?.gsh_info?.exclusion_remark)}
                                    placeholder="Remark"
                                    autoSize={{ minRows: 1  }}
                                />
                            </StyledCell>
                            <CustomHr />
                        </Col>
                        <Col span={9}>
                            <StyledCell className="flex px-1 items-center input-remark-wraper">
                                <TextArea
                                    className="input-remark ml-4 tung-hehe"
                                    readOnly={isBlockEdit}
                                    onChange={(e) =>
                                        handleChangeInputRemark("gtc_info", "exclusion_remark", e)
                                    }
                                    value={getDefaultValue(data?.gtc_info?.exclusion_remark)}
                                    placeholder="Remark"
                                    autoSize={{ minRows: 1 }}
                                />
                            </StyledCell>
                            <CustomHr />
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={7}>
                            <StyledCell color="#4DD4E2" style={{ height: "100%" }}>
                                <ValueCell>Exclusion Start Date</ValueCell>
                            </StyledCell>
                        </Col>
                        <Col span={8}>
                            <StyledCell color="#EDFBFC" style={{ height: "100%" }}>
                                {gshExclusion?.map?.(
                                    (exclusion: any, index: number) => (
                                        <TextCell color="#101820">
                                            {exclusion?.ExclusionStartDate
                                                ? `(${index + 1}) ${formatDate(
                                                    exclusion?.ExclusionStartDate,
                                                    "DD-MMM-YYYY",
                                                    "--"
                                                )}`
                                                : "--"}
                                        </TextCell>
                                    )
                                )
                                || <TextCell color="#101820">--</TextCell>
                                }
                            </StyledCell>
                        </Col>
                        <Col span={9}>
                            {gtcExclusion?.map?.(
                                (exclusion: any, index: number) => (
                                    <TextCell color="#101820">
                                        {exclusion?.ExclusionStartDate
                                            ? `(${index + 1}) ${formatDate(
                                                exclusion?.ExclusionStartDate,
                                                "DD-MMM-YYYY",
                                                "--"
                                            )}`
                                            : "--"}
                                    </TextCell>
                                )
                            )
                            || <TextCell color="#101820">--</TextCell>
                            }
                        </Col>
                    </Row>

                    <Row gutter={8}>
                    <Col span={7} style={{backgroundColor:"#4DD4E2", height:"auto", transform:"translate(-4px, 0)"}} className="tungdeptrai-col">
                            
                            </Col>
                        <Col span={8}>
                            <StyledCell
                                color="#EDFBFC"
                                className="flex px-1 items-center input-remark-wraper"
                            >
                                <TextArea
                                    className="input-remark ml-4 tung-hehe"
                                    readOnly={isBlockEdit}
                                    onChange={(e) =>
                                        handleChangeInputRemark(
                                            "gsh_info",
                                            "exclusion_start_date_remark",
                                            e
                                        )
                                    }
                                    value={getDefaultValue(data?.gsh_info?.exclusion_start_date_remark)}
                                    placeholder="Remark"
                                    autoSize={{ minRows: 1  }}
                                />
                            </StyledCell>
                            <CustomHr />
                        </Col>
                        <Col span={9}>
                            <StyledCell className="flex px-1 items-center input-remark-wraper">
                                <TextArea
                                    className="input-remark ml-4 tung-hehe"
                                    readOnly={isBlockEdit}
                                    onChange={(e) =>
                                        handleChangeInputRemark(
                                            "gtc_info",
                                            "exclusion_start_date_remark",
                                            e
                                        )
                                    }
                                    value={getDefaultValue(data?.gtc_info?.exclusion_start_date_remark)}
                                    placeholder="Remark"
                                    autoSize={{ minRows: 1 }}
                                />
                            </StyledCell>
                            <CustomHr />
                        </Col>
                    </Row>

                    <Row gutter={8}>
                        <Col span={7}>
                            <StyledCell color="#4DD4E2" style={{ height: "100%" }}>
                                <ValueCell>Exclusion End Date</ValueCell>
                            </StyledCell>
                        </Col>
                        <Col span={8}>
                            <StyledCell color="#EDFBFC" style={{ height: "100%" }}>
                                {gshExclusion?.map?.(
                                    (exclusion: any, index: number) => (
                                        <TextCell color="#101820">
                                            {exclusion?.ExclusionEndDate
                                                ? `(${index + 1}) ${formatDate(
                                                    exclusion?.ExclusionEndDate,
                                                    "DD-MMM-YYYY",
                                                    "--"
                                                )}`
                                                : "--"}
                                        </TextCell>
                                    )
                                )
                                || <TextCell color="#101820">--</TextCell>
                                }
                            </StyledCell>
                        </Col>
                        <Col span={9}>
                            {gtcExclusion?.map(
                                (exclusion: any, index: number) => (
                                    <TextCell color="#101820">
                                        {exclusion?.ExclusionEndDate
                                            ? `(${index + 1}) ${formatDate(
                                                exclusion?.ExclusionEndDate,
                                                "DD-MMM-YYYY",
                                                "--"
                                            )}`
                                            : "--"}
                                    </TextCell>
                                )
                            )
                            || <TextCell color="#101820">--</TextCell>
                            }
                        </Col>
                    </Row>

                    <Row gutter={8}>
                    <Col span={7} style={{backgroundColor:"#4DD4E2", height:"auto", transform:"translate(-4px, 0)"}} className="tungdeptrai-col">
                            
                            </Col>
                        <Col span={8}>
                            <StyledCell
                                color="#EDFBFC"
                                className="flex px-1 items-center input-remark-wraper"
                            >
                                <TextArea
                                    className="input-remark ml-4 tung-hehe"
                                    readOnly={isBlockEdit}
                                    onChange={(e) =>
                                        handleChangeInputRemark(
                                            "gsh_info",
                                            "exclusion_end_date_remark",
                                            e
                                        )
                                    }
                                    value={getDefaultValue(data?.gsh_info?.exclusion_end_date_remark)}
                                    placeholder="Remark"
                                    autoSize={{ minRows: 1  }}
                                />
                            </StyledCell>
                            <CustomHr />
                        </Col>
                        <Col span={9}>
                            <StyledCell className="flex px-1 items-center input-remark-wraper">
                                <TextArea
                                    className="input-remark ml-4 tung-hehe"
                                    readOnly={isBlockEdit}
                                    onChange={(e) =>
                                        handleChangeInputRemark(
                                            "gtc_info",
                                            "exclusion_end_date_remark",
                                            e
                                        )
                                    }
                                    value={getDefaultValue(data?.gtc_info?.exclusion_end_date_remark)}
                                    placeholder="Remark"
                                    autoSize={{ minRows: 1 }}
                                />
                            </StyledCell>
                            <CustomHr />
                        </Col>
                    </Row>

                    {/*  */}
                    <Row gutter={8}>
                        <Col span={7}>
                            <StyledCell color="#4DD4E2">
                                <ValueCell>Last similar/ related claim</ValueCell>
                            </StyledCell>
                        </Col>
                        <Col span={8}>
                            <StyledCell color="#EDFBFC">
                                <ValueCell color="#101820">
                                    {data?.last_similar_related_claim || "--"}
                                </ValueCell>
                            </StyledCell>
                        </Col>
                        <Col span={9}>
                            <ValueCell color="#101820">
                                {data?.last_similar_related_claim || "--"}
                            </ValueCell>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                    <Col span={7} style={{backgroundColor:"#4DD4E2", height:"auto", transform:"translate(-4px, 0)"}} className="tungdeptrai-col">
                            
                            </Col>
                        <Col span={8}>
                            <StyledCell
                                color="#EDFBFC"
                                className="flex px-1 items-center input-remark-wraper"
                            >
                                <TextArea
                                    className="input-remark ml-4 tung-hehe"
                                    readOnly={isBlockEdit}
                                    onChange={(e) =>
                                        handleChangeInputRemark(
                                            "gsh_info",
                                            "last_similar_related_claim_remark",
                                            e
                                        )
                                    }
                                    value={getDefaultValue(data?.gsh_info?.last_similar_related_claim_remark)}
                                    placeholder="Remark"
                                    autoSize={{ minRows: 1  }}
                                />
                            </StyledCell>
                            <CustomHr />
                        </Col>
                        <Col span={9}>
                            <StyledCell className="flex px-1 items-center input-remark-wraper">
                                <TextArea
                                    className="input-remark ml-4 tung-hehe"
                                    readOnly={isBlockEdit}
                                    onChange={(e) =>
                                        handleChangeInputRemark(
                                            "gtc_info",
                                            "last_similar_related_claim_remark",
                                            e
                                        )
                                    }
                                    value={getDefaultValue(data?.gtc_info?.last_similar_related_claim_remark)}
                                    placeholder="Remark"
                                    autoSize={{ minRows: 1 }}
                                />
                            </StyledCell>
                            <CustomHr />
                        </Col>
                    </Row>
                    {/*  */}
                    <Row gutter={8}>
                        <Col span={7}>
                            <StyledCell color="#4DD4E2">
                                <ValueCell>Next premium due date</ValueCell>
                            </StyledCell>
                        </Col>
                        <Col span={8}>
                            <StyledCell color="#EDFBFC">
                                <ValueCell color="#101820">
                                    {formatDate(
                                        data?.gsh_info?.next_premium_due_date,
                                        "DD-MMM-YYYY",
                                        "--",
                                        "YYYY-MM-DD"
                                    )}
                                </ValueCell>
                            </StyledCell>
                        </Col>
                        <Col span={9}>
                            <ValueCell color="#101820">
                                {formatDate(
                                    data?.gtc_info?.next_premium_due_date,
                                    "DD-MMM-YYYY",
                                    "--",
                                    "YYYY-MM-DD"
                                )}
                            </ValueCell>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                    <Col span={7} style={{backgroundColor:"#4DD4E2", height:"auto", transform:"translate(-4px, 0)"}} className="tungdeptrai-col">
                            
                            </Col>
                        <Col span={8}>
                            <StyledCell
                                color="#EDFBFC"
                                className="flex px-1 items-center input-remark-wraper"
                            >
                                <TextArea
                                    className="input-remark ml-4 tung-hehe"
                                    readOnly={isBlockEdit}
                                    onChange={(e) =>
                                        handleChangeInputRemark(
                                            "gsh_info",
                                            "next_premium_due_date_remark",
                                            e
                                        )
                                    }
                                    value={getDefaultValue(data?.gsh_info?.next_premium_due_date_remark)}
                                    placeholder="Remark"
                                    autoSize={{ minRows: 1  }}
                                />
                            </StyledCell>
                            <CustomHr />
                        </Col>
                        <Col span={9}>
                            <StyledCell className="flex px-1 items-center input-remark-wraper">
                                <TextArea
                                    className="input-remark ml-4 tung-hehe"
                                    readOnly={isBlockEdit}
                                    onChange={(e) =>
                                        handleChangeInputRemark(
                                            "gtc_info",
                                            "next_premium_due_date_remark",
                                            e
                                        )
                                    }
                                    value={getDefaultValue(data?.gtc_info?.next_premium_due_date_remark)}
                                    placeholder="Remark"
                                    autoSize={{ minRows: 1 }}
                                />
                            </StyledCell>
                            <CustomHr />
                        </Col>
                    </Row>
                    {/*  */}
                    <Row gutter={8}>
                        <Col span={7}>
                            <StyledCell color="#4DD4E2">
                                <ValueCell style={{ border: "none" }}>Caveat</ValueCell>
                            </StyledCell>
                        </Col>
                        <Col span={8}>
                            <StyledCell color="#EDFBFC">
                                <ValueCell color="#101820">{"--"}</ValueCell>
                            </StyledCell>
                        </Col>
                        <Col span={9}>
                            <ValueCell color="#101820">{"--"}</ValueCell>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                    <Col span={7} style={{backgroundColor:"#4DD4E2", height:"auto", transform:"translate(-4px, 0)"}} className="tungdeptrai-col">
                            
                            </Col>
                        <Col span={8}>
                            <StyledCell
                                color="#EDFBFC"
                                className="flex px-1 items-center input-remark-wraper"
                            >
                                <TextArea
                                    className="input-remark ml-4 tung-hehe"
                                    readOnly={isBlockEdit}
                                    onChange={(e) =>
                                        handleChangeInputRemark("gsh_info", "caveat_remark", e)
                                    }
                                    value={getDefaultValue(data?.gsh_info?.caveat_remark)}
                                    placeholder="Remark"
                                    autoSize={{ minRows: 1  }}
                                />
                            </StyledCell>
                            <CustomHr />
                        </Col>
                        <Col span={9}>
                            <StyledCell className="flex px-1 items-center input-remark-wraper">
                                <TextArea
                                    className="input-remark ml-4 tung-hehe"
                                    readOnly={isBlockEdit}
                                    onChange={(e) =>
                                        handleChangeInputRemark("gtc_info", "caveat_remark", e)
                                    }
                                    value={getDefaultValue(data?.gtc_info?.caveat_remark)}
                                    placeholder="Remark"
                                    autoSize={{ minRows: 1 }}
                                />
                            </StyledCell>
                            <CustomHr />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default PatientInfomation;

const StyledCell = styled.div`
  position: relative;
  min-height: 85px;
  height: auto;
  justify-content: flex-start;
  background-color: ${(props) => (props.color ? `${props.color}` : "#fff")};
`;

const ValueCell = styled.div`
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding-left: 0;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  color: ${(props) => (props.color ? `${props.color}` : "#fff")};
  margin: 0 26px 0 22px;
`;

const TextCell = styled.div`
  margin-top: 6px;
  padding-left: 22px;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  color: ${(props) => (props.color ? `${props.color}` : "#fff")};
`;

const CustomHr = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 1px;
  z-index: 100;
  background-color: ${(props) => (props.color ? `${props.color}` : "#A6E9F0")};
`;
