import React from "react";

type Props = {};

export const DocumentIcon = (props: Props) => {
  return (
    <svg
      width="10"
      height="13"
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 3.8743e-07L5.97693 0C6.32513 0 6.65756 0.151052 6.89413 0.41677L9.6672 3.53148C9.88115 3.77179 10 4.08722 10 4.41471V11.7C10 12.418 9.44036 13 8.75 13H1.25C0.559645 13 1.08033e-06 12.418 1.00583e-06 11.7L0 1.3C-7.45058e-08 0.58203 0.559644 4.26173e-07 1.25 3.8743e-07ZM5.97693 1.3L1.25 1.3L1.25 11.7H8.75L8.75 4.41471L5.97693 1.3Z"
        fill="#989898"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.625 0C5.97018 0 6.25 0.279822 6.25 0.625L6.25 3.75L9.375 3.75C9.72018 3.75 10 4.02982 10 4.375C10 4.72018 9.72018 5 9.375 5H6.25C5.55964 5 5 4.44036 5 3.75L5 0.625C5 0.279822 5.27982 3.72529e-08 5.625 0Z"
        fill="#989898"
      />
    </svg>
  );
};
