import React from "react";
import styled from "styled-components";
import {Space} from "antd";

export const UploadDragIcon = styled.p`
    & img {
        width: 155px;
        height: 112.01px;
    }
`

export const StyleText = styled.span`
    color: ${props => props.color || '#000000'} !important;
    font-style: ${props => props.theme.fontStyle || 'normal'} !important;
`

export const UploadItem = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1B1B1B;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
`

export const FileIndicator = styled.span`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #727C8A;
`

export const CustomSpace = styled(Space)`
    display: flex !important;
    margin: 12px 0 !important;
    flexWrap: wrap !important;
`
