import React from 'react'

type Props = {}

export const AmbulanceIcon = (props: Props) => {
  return (
    <svg
      width="45"
      height="41"
      viewBox="0 0 45 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.4857 19.8799C43.2157 19.8792 42.947 19.8008 42.7225 19.6476L31.2008 11.8023C30.7568 11.4997 30.5753 10.9722 30.7497 10.4923C30.925 10.0124 31.4168 9.68958 31.9706 9.69116L40.0945 9.71432C42.6869 9.72172 44.7918 11.6548 44.7851 14.0248L44.7717 18.7118C44.7704 19.1539 44.4963 19.5586 44.0629 19.7555C43.8807 19.84 43.6826 19.8805 43.4857 19.8799Z"
        fill="#03B4CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.7288 8.67829L38.6987 19.2406L7.05893 19.1503L7.10994 1.2616C7.11178 0.614989 7.68713 0.0914801 8.39595 0.0935013L29.4505 0.153539L32.1527 5.78628L38.7288 8.67829Z"
        fill="#FFF5F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.6991 19.2404L38.7293 8.6781L32.1532 5.78518L29.4509 0.15335L22.9336 0.134766L22.8792 19.1953L38.6991 19.2404Z"
        fill="#EBEBF0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4492 0.152344L29.4306 6.69703C29.4275 7.77654 30.3827 8.6533 31.5624 8.65666L38.7276 8.67709L29.4492 0.152344Z"
        fill="#D2DCE1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.0061 40.1769L5.62903 40.0789C3.0367 40.0715 0.931743 38.1384 0.938498 35.7694L1.00533 12.3333C1.01208 9.96416 3.12803 8.04313 5.72036 8.05052L17.3743 8.08375C19.1669 8.08886 20.7768 9.0031 21.5745 10.4703L23.6839 14.3506L40.0796 14.3973C42.6729 14.4047 44.7779 16.3387 44.7711 18.7078L44.7221 35.8942C44.7154 38.2633 42.5994 40.1843 40.0061 40.1769Z"
        fill="#4DD4E2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.0809 14.4057L23.6851 14.359L22.8965 12.9082L22.8188 40.1363L40.0074 40.1853C42.6007 40.1927 44.7167 38.2708 44.7234 35.9017L44.7724 18.7153C44.7792 16.3462 42.6742 14.4131 40.0809 14.4057Z"
        fill="#00C1D5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5441 34.6698L21.124 34.66C20.4151 34.658 19.8418 34.1321 19.8436 33.4846L19.8537 29.9684L16.0057 29.9574C15.2978 29.9554 14.7245 29.4295 14.7263 28.7829L14.7352 25.6576C14.7371 25.0101 15.3134 24.4875 16.0213 24.4895L19.8693 24.5005L19.8793 20.9852C19.8811 20.3377 20.4575 19.8151 21.1663 19.8171L24.5864 19.8269C25.2942 19.8289 25.8676 20.3548 25.8657 21.0023L25.8557 24.5176L29.7037 24.5285C30.4125 24.5306 30.9849 25.0564 30.983 25.704L30.9741 28.8293C30.9723 29.4759 30.3969 29.9985 29.6881 29.9964L25.8401 29.9855L25.8301 33.5017C25.8282 34.1492 25.2519 34.6718 24.5441 34.6698Z"
        fill="#FABE2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.7048 24.5288L25.8568 24.5178L25.8668 21.0026C25.8687 20.355 25.2963 19.8292 24.5875 19.8271L22.877 19.8223L22.8346 34.6652L24.5452 34.67C25.254 34.6721 25.8293 34.1495 25.8312 33.5019L25.8412 29.9857L29.6892 29.9967C30.398 29.9987 30.9734 29.4761 30.9752 28.8295L30.9841 25.7042C30.986 25.0567 30.4136 24.5308 29.7048 24.5288Z"
        fill="#FA6400"
      />
    </svg>
  );
}