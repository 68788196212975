import { MentionData } from "@draft-js-plugins/mention";
import { Col, Row, Space } from "antd";
import SessionDivider from "components/SessionDivider";
import SpaceView from "components/Space";
import {
    APPROVED,
    CLARIFICATION_REQUIRED,
    COMMENT_MODE,
    CO_MORBIDITIES,
    PRE_AUTHORISATION,
    REJECTED,
    CANCELLED
} from "constants/index";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import DAService from "services/DAService";
import { getNameFromLink } from "utils/helper";
import {
    Center,
    ContentSection1,
    ContentSection2,
    ContentSection4,
    ContentSection8,
    ContentTag,
    ContentTag2,
    ContentTagActive,
    LabelSection1,
    SessionContainer,
} from "../../styled";
import { CommentItem } from "../CommentItem";
import SimpleMentionEditor from "../EditorComment";
import Loading from "components/Loading";
import { useSelector } from "react-redux";
import { selectUserSelector } from "containers/Auth/authSlice";
import moment from "moment";
import { usePermissionClaim } from "hooks/usePermissionClaim";
import { formatDate } from "helpers";
import styled from "styled-components";
import "./styles.css"

type Props = {
    mentionDoctor: Array<MentionData>;
    claimStatus: any;
    assessorName: string;
    setModeComment?: any;
    modeComment?: any;
    isPinHeader?: boolean;
    approvedName?: string;
    isHistory?: boolean;
};

export interface ICoMordibity {
    name: string,
    date: string,
    name_clinic_doctor: string
}

export const StText = styled.button`
  width: 48px;
  height: 48px;
  border: 1px solid #55BED2;
  box-sizing: border-box;
  border-radius: 24px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #55BED2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0);

  & span {
    font-size: 14px;
    line-height: 10px;
    margin-bottom: 9px;
    display: inline-block;
    font-weight: 400;
    margin-left: 2px;
  }
`;

let sendingComment = false;

const SessionA = ({
    mentionDoctor,
    claimStatus,
    assessorName,
    setModeComment,
    modeComment,
    isPinHeader,
    approvedName,
    isHistory = false
}: Props) => {
    const [data, setData] = useState<any>();
    const { id } = useParams();
    const search = useLocation().search;
    const version_id = new URLSearchParams(search).get("versionId")?.toString() ?? '';
    const [listSymbol, setListSymbol] = useState<any>();
    const [listComments, setListComments] = useState<Array<any>>([]);
    const [loading, setLoading] = useState(false);
    const [isBlockEdit, setIsBlockEdit] = useState<boolean>(false);
    const [showPopoverComment, setShowPopoverComment] = useState<boolean>(false);
    const [showPopoverRemark, setShowPopoverRemark] = useState<boolean>(false);
    const user = useSelector(selectUserSelector);
    const { isComment } = usePermissionClaim({
        claimStatus: claimStatus,
        user: user,
        isHistory
    });

    const sendComment = async (field_name: string, content: string) => {
        if (sendingComment) {
            return;
        }
        sendingComment = true;

        const isInternal = modeComment == COMMENT_MODE.INTERNAL;
        const mode = isInternal ? modeComment : COMMENT_MODE.DRAFT;
        const dataComment = {
            version: data.version?.toString(),
            case_id: data?.case_id,
            form_name: "formA",
            form_id: data?.id,
            field_name,
            content,
            mode,
        };
        await DAService.assessor.sendComment(dataComment);
        getListComments(data?.original_form_id || data?.id, isInternal);
        sendingComment = false;
    };

    const getListComments = async (form_id: string, isInternal: boolean) => {
        if (isHistory && !version_id) return;
        const res = !isHistory
            ? await DAService.assessor.getListComments(form_id, isInternal)
            : await DAService.historyPAC.getHistoryCaseListComments(version_id, form_id, isInternal);
        
        // sort comment
        const comments = (res?.data?.data || []).sort((a: any, b: any) => {
            const aTime = moment(a.createdAt).toDate().getTime();
            const bTime = moment(b.createdAt).toDate().getTime();
            return aTime > bTime ? 1 : aTime < bTime ? -1 : 0;
        });

        let newComment: any = localStorage.getItem('newComment');
        console.log(comments)
        if (comments.length > 0) {
            if (newComment) {
                if (comments[comments.length - 1]?.id !== JSON.parse(newComment)?.id) {
                    setShowPopoverComment(true);
                    const elm: any = document.getElementsByClassName(
                        // @ts-ignore
                        comments[comments.length - 1]?.field_name
                    );
                    console.log('elm', elm);
                    console.log('comments[comments.length - 1]?', comments[comments.length - 1]);
                    

                    if (elm[0]) {
                        elm[0].scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                    }
                }
                else setShowPopoverComment(false);
            } else {
                setShowPopoverComment(true);
                const elm: any = document.getElementsByClassName(
                    // @ts-ignore
                    comments[comments.length - 1]?.field_name
                );

                if (elm[0]) {
                    elm[0].scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                }
            }
        } else {
            setShowPopoverComment(false);
        }
        if (comments.length > 0) localStorage.setItem('newComment', JSON.stringify(comments[comments.length - 1]));

        setListComments(comments);
    };

    const getNameSymptom = (id: string) => {
        if (listSymbol) {
            return listSymbol.find((symbol: any) => symbol.id === id)?.name || id;
        }
        return id;
    };

    const getSymptoms = async (ids: Array<string>) => {
        try {
            const res = await DAService.symptom.filter(ids);
            setListSymbol(res?.data?.data?.items || []);
        } catch (error) {
            console.log(error);
        }
    };

    const renderCommentByKeyName = (keyName: string) => {
        if (!listComments) return;
        const comments = listComments?.filter(
            (item: any) => item.field_name === keyName
        );
        
        return comments.map((comment: any) => {
            if (comment.mode == COMMENT_MODE.DRAFT && [
                CLARIFICATION_REQUIRED, APPROVED, REJECTED, CANCELLED
            ].includes(claimStatus?.valueOrigin)) comment = {
                ...comment, mode: COMMENT_MODE.DOCTOR // optimistic update
            }
      
            return <CommentItem
                key={comment.id}
                form_id={data?.id}
                isDeleteComment={false}
                id={comment.id}
                comment={comment}
                time={comment.createdAt}
                username={comment.user_name || comment.assessor_name}
                content={comment.content}
                callback={() => getListComments(data?.original_form_id || data?.id, modeComment == COMMENT_MODE.INTERNAL)}
            />
        });
    };

    useEffect(() => {
        if (data?.id || data?.original_form_id) {
            getListComments(data?.original_form_id || data?.id, modeComment == COMMENT_MODE.INTERNAL);
        }
    }, [data, modeComment]);

    useEffect(() => {
        if (id) {
            if (isHistory && !version_id) return;
            (async () => {
                try {
                    setLoading(true);
                    const response = !isHistory ? await DAService.assessor.getDoctorPACFormA(id) : await DAService.historyPAC.getHistoryCaseFormA(version_id, id);
                    await getSymptoms(
                        (response?.data?.data?.symptoms || []).map((x: any) => x.symptom_id)
                    );
                    setData(response?.data?.data || {});
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                }
            })();
        }
    }, [id]);

    const dateRange = (
        data?.date_of_first_consultation
            ? moment(data?.date_of_first_consultation || "", "DD-MMM-YYYY")
            : moment()
    )?.diff(
        data?.date_of_onset_or_duration_symptom
            ? moment(data?.date_of_onset_or_duration_symptom || "", "DD-MMM-YYYY")
            : moment(),
        "days"
    );

    const nth = function (d: number) {
        if (d > 3 && d < 21) return 'th';
        switch (d % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    }

    const renderPreAuthorization = (items: any) => {
        return PRE_AUTHORISATION.map((item: string) => {
            const isActive = items?.find((itemRes: string) => itemRes === item);
            if (isActive) {
                return (
                    <Col span={24}>
                        <ContentTagActive>{item}</ContentTagActive>
                    </Col>
                )
            }
            return (
                <Col span={24}>
                    <ContentTag>{item}</ContentTag>
                </Col>
            )
        })
    }

    const isNotContainsObject = (obj: ICoMordibity, list: ICoMordibity[]) => {
        let i;
        for (i = 0; i < list.length; i++) {
            if (list[i].name === obj.name) {
                return false;
            }
        }

        return true;
    }

    const renderCoMorbidities = (items: ICoMordibity[]) => {
        return CO_MORBIDITIES.map((item: ICoMordibity, index) => {
            const itemActive = items?.find((itemRes: ICoMordibity) => itemRes.name === item.name);
            const OtherActive = items?.find((test: ICoMordibity) => isNotContainsObject(test, CO_MORBIDITIES));

            if (OtherActive && index === CO_MORBIDITIES.length - 1) {
                return (
                    <Row
                        key={index}
                        style={{
                            padding: "20px 32px",
                            borderRadius: "24px",
                            marginTop: "8px",
                            backgroundColor: "#EDFBFC",
                        }}
                    >
                        <Col span={24}>
                            <Row>
                                <ContentSection4>Co-morbidities</ContentSection4>
                                <SpaceView size={4} />
                                <ContentSection1>{OtherActive?.name}</ContentSection1>
                            </Row>
                            <SpaceView size={16} />
                            <hr style={{ borderColor: "#ffffff" }} />
                            <SpaceView size={16} />
                            <Row>
                                <ContentSection4>
                                    Date of diagnosis, if available
                                </ContentSection4>
                                <SpaceView size={4} />
                                <ContentSection1>
                                    {OtherActive?.date ? moment(OtherActive?.date, "DD/MM/YYYY").format(
                                        "DD-MMM-YYYY"
                                    ) : ''}
                                </ContentSection1>
                            </Row>
                            <SpaceView size={16} />
                            <hr style={{ borderColor: "#ffffff" }} />
                            <SpaceView size={16} />
                            <Row>
                                <ContentSection4>
                                    Name of Clinic and Doctor who had treated the patient,
                                    if applicable
                                </ContentSection4>
                                <SpaceView size={4} />
                                <ContentSection1>
                                    {OtherActive?.name_clinic_doctor}
                                </ContentSection1>
                            </Row>
                        </Col>
                    </Row>
                )
            }
            if (itemActive) {
                return (
                    <Row
                        key={index}
                        style={{
                            padding: "20px 32px",
                            borderRadius: "24px",
                            marginTop: "8px",
                            backgroundColor: "#EDFBFC",
                        }}
                    >
                        <Col span={24}>
                            <Row>
                                <ContentSection4>Co-morbidities</ContentSection4>
                                <SpaceView size={4} />
                                <ContentSection1>{itemActive?.name}</ContentSection1>
                            </Row>
                            <SpaceView size={16} />
                            <hr style={{ borderColor: "#ffffff" }} />
                            <SpaceView size={16} />
                            <Row>
                                <ContentSection4>
                                    Date of diagnosis, if available
                                </ContentSection4>
                                <SpaceView size={4} />
                                <ContentSection1>
                                    {itemActive?.date ? moment(itemActive?.date, "DD/MM/YYYY").format(
                                        "DD-MMM-YYYY"
                                    ) : ''}
                                </ContentSection1>
                            </Row>
                            <SpaceView size={16} />
                            <hr style={{ borderColor: "#ffffff" }} />
                            <SpaceView size={16} />
                            <Row>
                                <ContentSection4>
                                    Name of Clinic and Doctor who had treated the patient,
                                    if applicable
                                </ContentSection4>
                                <SpaceView size={4} />
                                <ContentSection1>
                                    {itemActive?.name_clinic_doctor}
                                </ContentSection1>
                            </Row>
                        </Col>
                    </Row>
                )
            }
            return (
                <Row
                    key={index}
                    style={{
                        padding: "20px 32px",
                        backgroundColor: "#F3F7FC",
                        borderRadius: "24px",
                        marginTop: "8px",
                    }}
                >
                    <Col span={24}>
                        <Row>
                            <ContentSection4>Co-morbidities</ContentSection4>
                            <SpaceView size={4} />
                            <ContentSection1>{item?.name ? item?.name : 'Other Significant Comobidities'}</ContentSection1>
                        </Row>
                    </Col>
                </Row>
            )
        }
        )
    }

    return (
        <div className="" style={{ margin: "auto" }}>
            {loading ? <Loading /> : null}
            {!isPinHeader && (
                <Row justify="space-between">

                </Row>
            )}
            <SpaceView size={44} />
            {/* 1 */}
            <Row style={{ alignItems: "center", position: "relative" }}>
                <Col span={22}>
                    <SessionDivider />
                </Col>
                {/*<Center
          style={{
            width: "36px",
            height: "36px",
            borderRadius: "12px",
            backgroundColor: "#F3F7FC",
            position: "absolute",
            right: "0",
            cursor: "pointer",
            bottom: "12px",
          }}
        >
          <EditPencilIcon />
        </Center>*/}
            </Row>
            <SessionContainer className="" style={{ marginRight: 0, height: "100%" }}>
                
                <Row
                    style={{ borderBottom: "1px solid #DDDDDD", paddingBottom: "32px" }}
                >
                    <Col span={12} style={{ paddingRight: "32px" }}>
                        <LabelSection1 className="doctor_name">Name of Principal Doctor</LabelSection1>
                        <SpaceView size={8} />
                        <ContentSection1>{data?.doctor_name}</ContentSection1>
                    </Col>
                    <Col
                        span={12}
                        style={{ paddingLeft: "32px", borderLeft: "1px solid #DDDDDD" }}
                    >
                        {renderCommentByKeyName("doctor_name")}
                        <SpaceView size={10} />
                        {isComment && (
                            <SimpleMentionEditor
                                mentions={mentionDoctor}
                                onSubmit={(content: string) =>
                                    sendComment("doctor_name", content)
                                }
                            />
                        )}
                    </Col>
                </Row>
                {/*  */}
                <SpaceView size={44} />
                <Row
                    style={{ borderBottom: "1px solid #DDDDDD", paddingBottom: "32px" }}
                >
                    <Col span={12} style={{ paddingRight: "32px" }}>
                        <LabelSection1 className="clinic_name">Name of Clinic</LabelSection1>
                        <SpaceView size={8} />
                        <ContentSection1>{data?.clinic_name?.toUpperCase()}</ContentSection1>
                    </Col>
                    <Col
                        span={12}
                        style={{ paddingLeft: "32px", borderLeft: "1px solid #DDDDDD" }}
                    >
                        {renderCommentByKeyName("clinic_name")}
                        <SpaceView size={10} />
                        {isComment && (
                            <SimpleMentionEditor
                                mentions={mentionDoctor}
                                onSubmit={(content: string) =>
                                    sendComment("clinic_name", content)
                                }
                            />
                        )}
                    </Col>
                </Row>
                {/*  */}
                <SpaceView size={44} />
                <Row
                    style={{ borderBottom: "1px solid #DDDDDD", paddingBottom: "32px" }}
                >
                    <Col span={12} style={{ paddingRight: "32px" }}>
                        <LabelSection1 className="hospital_name">Name of Admitting Hospital / Surgery Centre</LabelSection1>
                        <SpaceView size={8} />
                        <ContentSection1>{data?.hospital_name?.toUpperCase()}</ContentSection1>
                    </Col>
                    <Col
                        span={12}
                        style={{ paddingLeft: "32px", borderLeft: "1px solid #DDDDDD" }}
                    >
                        {renderCommentByKeyName("hospital_name")}
                        <SpaceView size={10} />
                        {isComment && (
                            <SimpleMentionEditor
                                mentions={mentionDoctor}
                                onSubmit={(content: string) =>
                                    sendComment("hospital_name", content)
                                }
                            />
                        )}
                    </Col>
                </Row>
                {/*  */}
                <SpaceView size={44} />
                <Row
                    style={{ borderBottom: "1px solid #DDDDDD", paddingBottom: "32px" }}
                >
                    <Col span={12} style={{ paddingRight: "32px" }}>
                        <LabelSection1 className="date_of_admission">Date of Admission</LabelSection1>
                        <SpaceView size={8} />
                        <ContentSection1>
                            {formatDate(data?.date_of_admission, "DD-MMM-YYYY", "-")}
                        </ContentSection1>
                    </Col>
                    <Col
                        span={12}
                        style={{ paddingLeft: "32px", borderLeft: "1px solid #DDDDDD" }}
                    >
                        {renderCommentByKeyName("date_of_admission")}
                        <SpaceView size={10} />
                        {isComment && (
                            <SimpleMentionEditor
                                mentions={mentionDoctor}
                                onSubmit={(content: string) =>
                                    sendComment("date_of_admission", content)
                                }
                            />
                        )}
                    </Col>
                </Row>
                {/*  */}
                <SpaceView size={44} />
                <Row
                    style={{ borderBottom: "1px solid #DDDDDD", paddingBottom: "32px" }}
                >
                    <Col span={12} style={{ paddingRight: "32px" }}>
                        <LabelSection1 className="preferred_ward_type">Preferred Ward Type</LabelSection1>
                        <SpaceView size={8} />
                        <ContentSection1>
                            {data?.private_preferred_ward_type ?? data?.public_preferred_ward_type}
                        </ContentSection1>
                    </Col>
                    <Col
                        span={12}
                        style={{ paddingLeft: "32px", borderLeft: "1px solid #DDDDDD" }}
                    >
                        {renderCommentByKeyName("preferred_ward_type")}
                        <SpaceView size={10} />
                        {isComment && (
                            <SimpleMentionEditor
                                mentions={mentionDoctor}
                                onSubmit={(content: string) =>
                                    sendComment("preferred_ward_type", content)
                                }
                            />
                        )}
                    </Col>
                </Row>
                {/*  */}
                <SpaceView size={44} />
                <Row
                    style={{ borderBottom: "1px solid #DDDDDD", paddingBottom: "32px" }}
                >
                    <Col span={12} style={{ paddingRight: "32px" }}>
                        <LabelSection1 className="date_of_panel">Panel</LabelSection1>
                        <SpaceView size={8} />
                        <ContentSection1>
                            {formatDate(data?.date_of_panel, "DD-MMM-YYYY", "-")}
                        </ContentSection1>
                    </Col>
                    <Col
                        span={12}
                        style={{ paddingLeft: "32px", borderLeft: "1px solid #DDDDDD" }}
                    >
                        {renderCommentByKeyName("date_of_panel")}
                        <SpaceView size={10} />
                        {isComment && (
                            <SimpleMentionEditor
                                mentions={mentionDoctor}
                                onSubmit={(content: string) =>
                                    sendComment("date_of_panel", content)
                                }
                            />
                        )}
                    </Col>
                </Row>
            </SessionContainer>
            {/*  */}
            <SpaceView size={44} />

            <Row className="" style={{ alignItems: "center", position: "relative" }}>
                <Col span={22}>
                    <SessionDivider value={{ index: 2 }}></SessionDivider>
                </Col>
                {/*<Center
          style={{
            width: "36px",
            height: "36px",
            borderRadius: "12px",
            backgroundColor: "#F3F7FC",
            position: "absolute",
            right: "0",
            cursor: "pointer",
            bottom: "12px",
          }}
        >
          <EditPencilIcon />
        </Center>*/}
            </Row>
            <SessionContainer style={{ marginRight: 0, height: "100%" }}>
                <SpaceView size={48} />
                {
                    data?.symptoms &&
                    data?.symptoms?.map((sym: any, indexSym: number) => {
                        const value = sym;
                        const startDate = value?.date_of_first_consultation ? moment(
                            value?.date_of_first_consultation || "",
                            "DD-MMM-YYYY"
                        ) : moment();
                        const endDate = value?.date_of_onset_or_duration_symptom ? moment(
                            value?.date_of_onset_or_duration_symptom || "",
                            "DD-MMM-YYYY"
                        ) : moment();
                        const duration = moment.duration(Math.abs(startDate.endOf('days').toDate().getTime() - endDate.startOf('days').toDate().getTime()) + 1, 'milliseconds');
                        const dateRange = [
                            {
                                value: Math.round(duration.years()),
                                name: (Math.round(duration.years()) > 1 ? "Years" : "Year")
                            },
                            {
                                value: Math.round(duration.months()),
                                name: (Math.round(duration.months()) > 1 ? "Months" : "Month")
                            },
                            {
                                value: duration.days(),
                                name: (duration.days() > 1 ? "Days" : "Day")
                            }
                        ].map(item => `${item.value} ${item.name}`).join(' ');

                        return (
                            <Row key={indexSym.toString()}
                                style={{ borderBottom: "1px solid #DDDDDD", paddingBottom: "32px" }}
                            >
                                <Col span={12} style={{ paddingRight: "32px" }}>
                                    <Row>
                                        <Col span={24} className="mb-2">
                                            {indexSym >= 1 ? (<SpaceView size={32} />) : null}
                                            <StText>{indexSym + 1} <span>{nth(indexSym + 1)}</span></StText>
                                        </Col>
                                        <SpaceView size={16} />
                                        <Col span={24} className="mb-2">
                                            <LabelSection1 className="symptoms_0">Symptoms</LabelSection1>
                                            <SpaceView size={8} />
                                            <ContentSection1>
                                                {getNameSymptom(sym.symptom_id)}
                                            </ContentSection1>
                                        </Col>
                                        <SpaceView size={16} />
                                        <Col span={24} className="mb-2">
                                            <LabelSection1 className="symptoms_1">Date of onset of symptom</LabelSection1>
                                            <SpaceView size={8} />
                                            <ContentSection1>
                                                {formatDate(sym?.date_of_onset_or_duration_symptom, "DD-MMM-YYYY", "-")}
                                            </ContentSection1>
                                        </Col>
                                        <SpaceView size={16} />
                                        <Col span={24} className="mb-2">
                                            <LabelSection1 className="symptoms_2">Date of first consultation of symptoms</LabelSection1>
                                            <SpaceView size={8} />
                                            <ContentSection1>
                                                {formatDate(sym?.date_of_first_consultation, "DD-MMM-YYYY", "-")}
                                            </ContentSection1>
                                        </Col>
                                        <SpaceView size={16} />
                                        <Col span={24} className="mb-2">
                                            <LabelSection1 className="symptoms_3">Duration of symptom</LabelSection1>
                                            <SpaceView size={8} />
                                            <ContentSection1>
                                                {dateRange}
                                            </ContentSection1>
                                        </Col>
                                        <SpaceView size={16} />
                                        <Col span={24} className="mb-2">
                                            <LabelSection1>Note</LabelSection1>
                                            <SpaceView size={8} />
                                            <ContentSection1>
                                                {sym?.note || "-"}
                                            </ContentSection1>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col
                                    span={12}
                                    style={{ paddingLeft: "32px", borderLeft: "1px solid #DDDDDD" }}
                                >
                                    {renderCommentByKeyName(`symptoms_${indexSym}`)}
                                    <SpaceView size={10} />
                                    {isComment && (
                                        <SimpleMentionEditor
                                            mentions={mentionDoctor}
                                            onSubmit={(content: string) => sendComment(`symptoms_${indexSym}`, content)}
                                        />
                                    )}
                                </Col>
                            </Row>
                        )
                    })
                }
                {/*  */}
                <SpaceView size={44} />
                <Row
                    style={{ borderBottom: "1px solid #DDDDDD", paddingBottom: "32px" }}
                >
                    <Col span={12} style={{ paddingRight: "32px" }}>
                        <LabelSection1 className="date_of_diagnosis">
                            Date of diagnosis/ provisional diagnosis
                        </LabelSection1>
                        <SpaceView size={8} />
                        <ContentSection1>
                            {formatDate(data?.date_of_diagnosis, "DD-MMM-YYYY", "-")}
                        </ContentSection1>
                    </Col>
                    <Col
                        span={12}
                        style={{ paddingLeft: "32px", borderLeft: "1px solid #DDDDDD" }}
                    >
                        {renderCommentByKeyName("date_of_diagnosis")}
                        <SpaceView size={10} />
                        {isComment && (
                            <SimpleMentionEditor
                                mentions={mentionDoctor}
                                onSubmit={(content: string) =>
                                    sendComment("date_of_diagnosis", content)
                                }
                            />
                        )}
                    </Col>
                </Row>
                {/*  */}
                <SpaceView size={44} />
                <Row
                    style={{ borderBottom: "1px solid #DDDDDD", paddingBottom: "32px" }}
                >
                    <Col span={12} style={{ paddingRight: "32px" }}>
                        <LabelSection1 className="diagnoses">
                            Diagnosis / Provisional diagnosis in ICD-10-AM with description
                        </LabelSection1>
                        <SpaceView size={8} />
                        <p style={{
                            marginBottom: "0",
                        }}>(You may select more than 1 diagnosis, where necessary)</p>
                        <SpaceView size={8} />
                        <Row>
                            {data?.diagnoses &&
                                data?.diagnoses?.length > 0 &&
                                data?.diagnoses.map((item: any) => {
                                    return (
                                        <>
                                            <SpaceView size={8} />
                                            <Col span={24}>
                                                <>
                                                    <ContentTag>
                                                        <ContentSection1>
                                                            {item?.icd_10} - {item?.name}
                                                        </ContentSection1>
                                                    </ContentTag>
                                                    <SpaceView size={8} />
                                                </>
                                            </Col>
                                        </>
                                    );
                                })}
                        </Row>
                    </Col>
                    <Col
                        span={12}
                        style={{ paddingLeft: "32px", borderLeft: "1px solid #DDDDDD" }}
                    >
                        {renderCommentByKeyName("diagnoses")}
                        <SpaceView size={10} />
                        {isComment && (
                            <SimpleMentionEditor
                                mentions={mentionDoctor}
                                onSubmit={(content: string) =>
                                    sendComment("diagnoses", content)
                                }
                            />
                        )}
                    </Col>
                </Row>
            </SessionContainer>
            {/*  */}
            <SpaceView size={24} />
            <Row  className="break-before" style={{ alignItems: "center", position: "relative" }}>
                <Col span={22}>
                    <SessionDivider value={{ index: 3 }}></SessionDivider>
                </Col>
                {/*<Center
          style={{
            width: "36px",
            height: "36px",
            borderRadius: "12px",
            backgroundColor: "#F3F7FC",
            position: "absolute",
            right: "0",
            cursor: "pointer",
            bottom: "12px",
          }}
        >
          <EditPencilIcon />
        </Center>*/}
            </Row>
            <SessionContainer className="" style={{ marginRight: 0, height: "100%" }}>
                <SpaceView size={48} />
                <Row
                    style={{ borderBottom: "1px solid #DDDDDD", paddingBottom: "32px" }}
                >
                    <Col span={12} style={{ paddingRight: "32px" }}>
                        <LabelSection1 className="referral_letter">
                            Did the patient come to see you with a referral letter?
                        </LabelSection1>
                        <SpaceView size={8} />
                        <ContentSection4 color="#828282">
                            (If a referral letter is available, please attach a copy to speed
                            up the pre-authorisation process.)
                        </ContentSection4>
                        <SpaceView size={8} />
                        <Space>
                            <ContentSection1>
                                {data?.referral_letter && data?.referral_letter?.length > 0
                                    ? "Yes"
                                    : "No"}
                            </ContentSection1>
                        </Space>
                        <SpaceView size={8} />
                        <Row>
                            {data?.referral_letter &&
                                data?.referral_letter?.length > 0 &&
                                data?.referral_letter.map((item: any) => (
                                    <>
                                        <Col span={24}>
                                            <ContentTag>
                                                <ContentSection1>
                                                    {getNameFromLink(item)}
                                                </ContentSection1>
                                            </ContentTag>
                                        </Col>
                                        <SpaceView size={8} />
                                    </>
                                ))}
                        </Row>
                    </Col>
                    <Col
                        span={12}
                        style={{ paddingLeft: "32px", borderLeft: "1px solid #DDDDDD" }}
                    >
                        {renderCommentByKeyName("referral_letter")}
                        <SpaceView size={10} />
                        {isComment && (
                            <SimpleMentionEditor
                                mentions={mentionDoctor}
                                onSubmit={(content: string) =>
                                    sendComment("referral_letter", content)
                                }
                            />
                        )}
                    </Col>
                </Row>
                {/*  */}
                <SpaceView size={44} />
                <Row className="break-after"
                    style={{ borderBottom: "1px solid #DDDDDD", paddingBottom: "32px" }}
                    >
                    <Col span={12} style={{ paddingRight: "32px" }}>
                        <LabelSection1 className="pre_authorisation_requested">
                            Based on the information available to you, is the event for which
                            pre-authorisation is being requested:
                        </LabelSection1>
                        <SpaceView size={8} />
                        <Row gutter={[8, 8]}>
                            {renderPreAuthorization(data?.pre_authorisation_requested)}
                        </Row>
                    </Col>
                    <Col
                        span={12}
                        style={{ paddingLeft: "32px", borderLeft: "1px solid #DDDDDD" }}
                    >
                        {renderCommentByKeyName("pre_authorisation_requested")}
                        <SpaceView size={10} />
                        {isComment && (
                            <SimpleMentionEditor
                                mentions={mentionDoctor}
                                onSubmit={(content: string) =>
                                    sendComment("pre_authorisation_requested", content)
                                }
                            />
                        )}
                    </Col>
                </Row>
                {/*  */}
                <SpaceView size={44} />
                <Row className="break-before"
                    style={{ borderBottom: "1px solid #DDDDDD", paddingBottom: "32px" }}
                    >
                    <Col span={12} style={{ paddingRight: "32px" }}>
                        <LabelSection1 className="co_morbidites">
                            Based on the information available to you, does the patient have
                            any of the following major co-morbidities?
                        </LabelSection1>
                        <SpaceView size={8} />
                        <ContentSection4 color="#828282">
                            (Note: Only co-morbidities that have impact on the patient’s
                            treatment, impact on the duration of hospitalisation, or which are
                            medically related to the patient’s condition, need to be
                            indicated.)
                        </ContentSection4>
                        <SpaceView size={8} />
                        {renderCoMorbidities(data?.co_morbidites)}
                    </Col>
                    <Col
                        span={12}
                        style={{ paddingLeft: "32px", borderLeft: "1px solid #DDDDDD" }}
                    >
                        {renderCommentByKeyName("co_morbidites")}
                        <SpaceView size={10} />
                        {isComment && (
                            <SimpleMentionEditor
                                mentions={mentionDoctor}
                                onSubmit={(content: string) =>
                                    sendComment("co_morbidites", content)
                                }
                            />
                        )}
                    </Col>
                </Row>
            </SessionContainer>
        </div>
    );
};

export default SessionA;
