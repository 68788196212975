import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";

const initialState = {
  formA: null,
  formB: null,
  detailCase: null,
};

export const dataCasePACSlice = createSlice({
  name: "casepac",
  initialState,
  reducers: {
    setDataFormA: (state, { payload }) => {
      state.formA = payload;
    },
    setDataFormB: (state, { payload }) => {
      state.formB = payload;
    },
    setDataDetailCase: (state, { payload }) => {
      state.formA = payload;
    },
  },
});

export const { setDataFormA, setDataFormB, setDataDetailCase } =
  dataCasePACSlice.actions;
export const formASelector = (state: RootState) => state.casePAC.formA;
export const formBSelector = (state: RootState) => state.casePAC.formB;
export const detailCaseSelector = (state: RootState) =>
  state.casePAC.detailCase;
export default dataCasePACSlice.reducer;
