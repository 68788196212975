import { ModalProps } from "antd";
import React from "react";
import { RiCloseLine } from "react-icons/ri";
import { StyledModal } from "styles/styled";

export interface CustomModalProps {
  children?: any;
}

const Modal = ({
  children,
  closeIcon,
  ...props
}: CustomModalProps & ModalProps) => {
  return (
    <StyledModal {...props} closeIcon={closeIcon || <RiCloseLine size={32} />}>
      {children}
    </StyledModal>
  );
};

export default Modal;
