import { Button, Col, notification, Row, Space, Tooltip } from 'antd';
import { EditIcon } from 'components/Common/Icon';
import Status from 'components/Common/Status';
import React from 'react';
import styled from 'styled-components';
import { formatCurrency2, showEllipsis, showEllipsis2 } from 'helpers';

const ProviderItem = ({ item, tosp, onEdit, shouldAllowEdit, ...props }: any) => {
    return (
        <StyledAllocationItem {...props}>
            <Row gutter={24}>
                <Col span={5}>
                    <Space size={24}>
                        <div>
                            <p className="m-0 mb-2 title">Name</p>
                            <p className="m-0 value">{item?.name}</p>
                        </div>
                    </Space>
                </Col>

                <Col span={shouldAllowEdit ? 4 : 6}>
                    <div className="mx-5">
                        <p className="m-0 mb-2 title">Amount</p>
                        <p className="m-0 value">
                            {formatCurrency2(item?.from_amount)} - {formatCurrency2(item?.to_amount)}
                        </p>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="mx-5">
                        <p className="m-0 mb-2 title">TOSP</p>
                        <p className="m-0 value">
                            {item?.tosp?.length ? showEllipsis(item?.tosp) : showEllipsis2(tosp)}
                        </p>
                    </div>
                </Col>

                <Col span={3}>
                    <div className="mx-5">
                        <p className="m-0 mb-2 title">Specialty</p>
                        <p className="m-0 value">Any</p>
                    </div>
                </Col>
                <Col span={3}>
                    <div className="mx-5">
                        <p className="m-0 mb-2 title">Status</p>
                        <Space size={20}>
                            <Status type={item.status.toLowerCase()}>{item.status}</Status>
                        </Space>
                    </div>
                </Col>
                <Col span={3}>
                    <div className="mx-5">
                        <p className="m-0 mb-2 title">is STP?</p>
                        <p className="m-0 value">{item.is_stp ? 'Yes' : 'No'}</p>
                    </div>
                </Col>
                {shouldAllowEdit ? (
                    <Col span={2}>
                        <div className="h-full flex items-center justify-end">
                            <Space size={16}>
                                <Tooltip title="Edit">
                                    <Button
                                        type="link"
                                        icon={<EditIcon />}
                                        size="small"
                                        onClick={() => onEdit(item)}
                                    />
                                </Tooltip>
                            </Space>
                        </div>
                    </Col>
                ) : null}
            </Row>
        </StyledAllocationItem>
    );
};

const StyledAllocationItem = styled.div`
    min-height: 95px;
    background-color: #fff;
    border-radius: 16px;
    padding: 16px 28px;
    .title {
        color: #727c8a;
    }
    .value {
        color: #101820;
    }
`;

export default ProviderItem;
