import { formatCurrency2 } from "helpers";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DAService from "services/DAService";
import styled from "styled-components";
import { romanize } from "utils/helper";
import "./style.css";

type Props = {};

const TextSpan = styled.span`
  background-color: transparent;
  color: ${(props) => (props?.color ? props?.color : "#222a35")};
  font-family: Calibri, sans-serif;
  font-size: 10pt;
  font-variant: normal;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
`;

const Index = (props: Props) => {
  const { id } = useParams();

  const [data, setData] = useState<any>();

  const initDataPreAuth = (dataCase: any) => {
    return [
      {
        name: "Special comment to Clinic",
        value: dataCase?.special_comment_to_clinic,
        isHasPrice: false,
      },
      {
        name: "General exclusion",
        value: dataCase?.general_exclusion,
        isHasPrice: true,
        description: dataCase?.desctiption_general_exclusion,
      },
      {
        name: "Policy exclusion",
        value: dataCase?.policy_exclusion,
        isHasPrice: true,
        description: dataCase?.desctiption_policy_exclusion,
      },
      {
        name: "Pro-ration",
        value: dataCase?.proration,
        isHasPrice: true,
        description: dataCase?.description_proration,
      },
      {
        name: "Exceed policy benefit capped limit (for Non-As-Charged policy)",
        value: dataCase?.non_as_charged,
        isHasPrice: true,
        description: dataCase?.desctiption_non_as_charged,
      },
      {
        name: "Deductible (if no rider)",
        value: dataCase?.or_deductible,
        isHasPrice: true,
        description: dataCase?.description_or_deductible,
      },
      {
        name: "10% co-insurance (if no rider and deductibles fully met)",
        value: dataCase?.co_insurance_alway,
        isHasPrice: true,
        description: dataCase?.description_co_insurance_alway,
      },
      {
        name: "5% co-payment (capped at $3000)",
        value: dataCase?.co_payment,
        isHasPrice: true,
        description: dataCase?.description_co_payment,
      },
      {
        name: "Co-payment (capped at $3000)",
        value: dataCase?.co_payment_3rd,
        isHasPrice: true,
        description: dataCase?.description_co_payment_3rd,
      },
      {
        name: "Deductible (Rider covers 95% of Deductible)",
        value: dataCase?.deductiable_rider_cover,
        isHasPrice: true,
        description: dataCase?.desctiption_deductiable_rider_cover,
      },
      {
        name: "Deductible",
        value: dataCase?.deductiable_rider,
        isHasPrice: true,
        description: dataCase?.desctiption_deductiable_rider,
      },
      {
        name: "Co-Insurance (Rider covers 50% of Co-insurance)",
        value: dataCase?.co_insurance_rider_cover,
        isHasPrice: true,
        description: dataCase?.desctiption_co_insurance_rider_cover,
      },
    ]?.filter((item) => item?.value || item?.description);
  };

  useEffect(() => {
    const root: any = document?.querySelector("#root");
    if (root) {
      root.style.background = "#fff";
    }
    if (!id) return;
    (async () => {
      const data = await DAService.assessor.getDataFileNonClinic(id);
      const originData = {
        ...(data?.data?.data ?? {}),
      };
      setData(originData);
    })();
  }, [id]);

  return (
    <div
      style={{
        background: "#fff",
        width: 800,
        padding: "80px 78px 0 78px",
        margin: "0 auto",
      }}
    >
      <p className="mb-0">
        <TextSpan>Dear Team,</TextSpan>
      </p>
      <br />
      <p>
        <strong>Non-preauthorised item(s):</strong>
      </p>
      <ol className="mb-0">
        {initDataPreAuth(data)?.map((item, index) => (
          <li className="mb-0 mt-0 pl-7">
            {item?.isHasPrice && (
              <span
                style={{
                  color: "#4472c3",
                  fontSize: 13,
                  minWidth: 20,
                  display: "inline-block",
                }}
              >
                {romanize(
                  initDataPreAuth(data)[0]?.isHasPrice ? index + 1 : index
                )}
                .
              </span>
            )}

            {item.isHasPrice ? (
              <TextSpan color="#4472c3">
                {item?.name ?? ""} : {formatCurrency2(item?.value)}
                <span>{`${
                  item?.description ? " - " + item?.description : ""
                }`}</span>
              </TextSpan>
            ) : (
              <TextSpan color="#4472c3" style={{ whiteSpace: "pre-wrap" }}>
                {item?.value}
              </TextSpan>
            )}
          </li>
        ))}
      </ol>
      <p className="mt-2 mb-0" dir="ltr">
        <TextSpan color="#000">
          <strong>Please take note on the following:</strong>
        </TextSpan>
      </p>

      <ol className="mt-3 pl-12 " style={{ listStyleType: "decimal" }}>
        <li>
          <p className="mb-0 mt-0 pl-6">
            <TextSpan>
              All non-preauthorised item(s) except Deductible, Co-insurance,
              Co-payment, Pro-ration and Capped policy benefit limits will be
              reviewed by GE Claims Department under e-file claim (for
              Singaporeans/ Permanent Residents only) based on Medical Necessity
              and/or policy terms and conditions.&nbsp;
            </TextSpan>
            <TextSpan>
              <strong>
                Please seek patient’s consent if they are agreeable to proceed
                at their own expense on the non-preauthorised item(s) in the
                event if it remains non-covered after e-file assessment.
              </strong>
            </TextSpan>
            <TextSpan>
              &nbsp; A copy of the terms and conditions has been enclosed for
              your ease of reference.
            </TextSpan>
          </p>
          <br />
          <p className="mb-0 mt-0 ml-6" dir="ltr">
            <i>
              <TextSpan>
                ***Foreigners are required to submit their claim to GE Claims
                Department directly.&nbsp; They may refer to GE website for the
                claim documents required.
              </TextSpan>
            </i>
          </p>
        </li>
        <br />
        <li>
          <p className="mb-0 mt-0 pl-6">
            <TextSpan color="#000000">
              Please also note that treatments and/or medical supplies which are
              for preventative, screening or health enhancement (including but
              not limited to dietary replacement or supplement) purposes are not
              covered under Great Eastern integrated shield plan policies.
            </TextSpan>
          </p>
        </li>
        <br />
        <li className="mb-0 mt-0 pl-6">
          <div>
            <TextSpan color="#000">
              For e-filing and/or submission of charges to BO:
            </TextSpan>
            <ol>
              <li>
                <span>
                  <TextSpan color="#000000">
                    <strong>
                      <span>{romanize(1)}</span>. Please file the procedure fee
                      and anaesthetist fee separately.
                    </strong>
                  </TextSpan>
                </span>
              </li>
              <li>
                <span>
                  <TextSpan color="#000000">
                    <strong>
                      <span>{romanize(2)}</span>. Do not include any
                      pre/post-hospitalisation charges.</strong> The patient is required to submit the pre/post bill to GE
                      Claims Department separately.
                  </TextSpan>
                </span>
              </li>
              <li>
                <TextSpan>
                  <span>{romanize(3)}</span>. For discharge medications, please
                  note that a maximum of 1 month’s medication is
                  pre-authorisable
                </TextSpan>
              </li>
              <li>
                <TextSpan>
                  <span>{romanize(4)}</span>. All agreed fees listed above must
                  be followed in order for successful claims processing.
                </TextSpan>
              </li>
            </ol>
          </div>
        </li>
        <br />
        <li className="pl-6">
          <TextSpan>
            <strong>
            The CPA may be reviewed again and revised accordingly should we be informed or made aware of 
            any changes such as but not limited to changes to the Policy terms and conditions, surgery/admission date, 
            procedures and cost after our issuance of the attached CPA and prior to the patient's discharge. 
            It will be revised according to the Policy terms and conditions, including any endorsements.
            </strong>
          </TextSpan>
        </li>
        <br />
        <li className="pl-6">
          <TextSpan>
            <strong>
              Please be reminded that all fees (pre/post, outpatient, inpatient/
              day surgery) will be at agreed panel rates.
            </strong>
          </TextSpan>
        </li>
      </ol>
      <p className="mt-2 mb-0" dir="ltr">
        <TextSpan>
          <strong style={{ textDecoration: "underline" }}>
            Please acknowledge receipt of this email and CPA.
          </strong>
        </TextSpan>
      </p>
      <p className="mt-0 mb-0" dir="ltr">
        <TextSpan>
          Thank you and I look forward to hearing from you soon.
        </TextSpan>
      </p>
      <p className="mt-4 mb-0" dir="ltr">
        <TextSpan color="#000">
          <strong>Warmest Regards,</strong>
        </TextSpan>
      </p>
      <p className="mt-0 mb-0" dir="ltr">
        <TextSpan color="#3b3838">
          <strong>{data?.assessor_name}</strong>
        </TextSpan>
      </p>
      <p className="mt-0 mb-0" dir="ltr">
        <TextSpan color="#000">Health Connect Team</TextSpan>
      </p>
      <p className="mt-0 mb-0" dir="ltr">
        <TextSpan color="#000">Great Eastern Life Assurance Co Ltd</TextSpan>
      </p>
      <p className="mt-0 mb-0" dir="ltr">
        <TextSpan color="#000">General Helpline (+65) 6563 2233</TextSpan>
      </p>
    </div>
  );
};

export default Index;
