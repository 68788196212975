import { Button, Col, Modal, notification, Popover, Row } from 'antd';
import ArchiveStatus, { Pill } from 'components/Common/Archive.status';
import RoleComponent from 'components/Common/Role';
import { useState, useEffect, ComponentProps } from 'react';
import Tooltip from 'antd/lib/tooltip';
import styled from 'styled-components';
import { formatCurrency2 } from 'helpers';
import moment from 'moment';
import { CLAIM_STATUS_VALUE_MAP_TEXT, Role, CANCELLED, APPROVED, APPROVED_RE_ISSUED_TEXT } from '../../constants';
import UncheckIcon from '../../assets/uncheck-icon.jpg';
import CheckIcon from '../../assets/check-icon.png';
import DAService from 'services/DAService';
import { STP_RESULT } from 'constants/epac.constans';

const styles = {
    name_patient: {
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '24px',
    },
    style_value: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '24px',
        color: '#101820',
    },
    style_title: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '24px',
        color: '#727C8A',
    }
};

const enum ExportStatus {
    Ready,
    Confirmation,
    InProgress,
}

const ArchiveItem = ({ item, onReassign, role, pathRedirectTo, setExportStatus: patchCase, ...props }: any) => {
    let stpResult = STP_RESULT.find(x => x.value === item.stp_result);

    const [exportStatus, setExportStatus] = useState(ExportStatus.Ready);
    const [open, setOpen] = useState(false);
    const handleRedirect = (id: string) => {
        window.open(pathRedirectTo ?? `/assessment/claim/${id}`);
    };
    const [isTicked, setIsTicked] = useState<boolean>(false);

    useEffect(() => {
        const initTicked = async () => {
            if (
                CLAIM_STATUS_VALUE_MAP_TEXT[item.claim_status] === 'Approved' ||
                CLAIM_STATUS_VALUE_MAP_TEXT[item.claim_status] === 'Rejected'
            ) {
                const res: any = await DAService.assessor.updateTick(item.id, { is_ticked: true });
                setIsTicked(true);
            }
        };
        initTicked();
    }, [item.claim_status, item.id]);

    const handleTick = async () => {
        try {
            const res: any = await DAService.assessor.updateTick(item.id, { is_ticked: !isTicked });
            if (res.data.data) {
                setIsTicked(res.data.data.is_ticked);
            }
            notification.success({
                message: 'Success',
                description: 'Update case successfully',
            });
        } catch {
            notification.error({
                message: 'Error',
                description: 'Update case failed',
            });
        }
        setOpen(false);
    };

    const copy = () => {
        const objCopy = {
            caseNo: item.serial,
            status: item.claim_status,
            patient_name: item.patient_name,
            NRIC: item.nric_no,
            gsh_info_policy_no: item.gsh_info_policy_no,
            gtc_info_policy_no: item.gtc_info_policy_no,
            Amount: item.total_amount,
        };
        const copyText = Object.values(objCopy).join(' ');
        navigator.clipboard.writeText(copyText).then(() => {
            notification.success({
                message: 'Success',
                description: 'Copied to clipboard',
            });
        });
    };

    const ALLOW_TO_TICK = [Role.ASSESSOR, Role.MASTER, Role.SUPERVISOR];

    const copyAction = () => (
        <Button
            type="link"
            onClick={(event) => {
                event.stopPropagation();
                copy();
            }}
            icon={
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.5 4H10.5L10.5 9C10.5 9.55228 10.0523 10 9.5 10C8.94772 10 8.5 9.55228 8.5 9L8.5 4C8.5 2.89543 9.39543 2 10.5 2L20.5 2C21.6046 2 22.5 2.89543 22.5 4V14C22.5 15.1046 21.6046 16 20.5 16H15.5C14.9477 16 14.5 15.5523 14.5 15C14.5 14.4477 14.9477 14 15.5 14H20.5L20.5 4Z"
                        fill="#727C8A"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.5 10H4.5V20L14.5 20L14.5 10ZM4.5 8L14.5 8C15.6046 8 16.5 8.89543 16.5 10L16.5 20C16.5 21.1046 15.6046 22 14.5 22L4.5 22C3.39543 22 2.5 21.1046 2.5 20L2.5 10C2.5 8.89543 3.39543 8 4.5 8Z"
                        fill="#727C8A"
                    />
                </svg>
            }
        />
    );

    const renderActions = () => {
        let certIcon = (
            <Tooltip title="Cert Issued ?">
                {isTicked ? (
                    <CustomIcon className={'uncheck-icon'} src={UncheckIcon} />
                ) : (
                    <StyledIcon className={'check-icon'} src={CheckIcon} />
                )}
            </Tooltip>
        );

        if (ALLOW_TO_TICK.includes(role) && CLAIM_STATUS_VALUE_MAP_TEXT[item.claim_status] === CANCELLED) {
            certIcon = (
                <Popover content={contentPopupConfirm()} trigger="click" open={open} onOpenChange={handleOpenChange}>
                    {certIcon}
                </Popover>
            );
        }

        return (
            <Col span={12} md={3}>
                <div className="mr-5">
                    <div className="inline-flex justify-between items-center w-full">
                        {certIcon}
                        {copyAction()}
                    </div>
                    {item.claim_status === APPROVED && (
                        <ExportButton
                            pacNumber={item.v2_export?.pac_number}
                            onClick={() => setExportStatus(ExportStatus.Confirmation)}
                            disabled={item.v2_export?.status}
                            log={item.v2_export?.log}
                        />
                    )}
                    {exportStatus === ExportStatus.Ready || (
                        <ExportDialog
                            inProgress={exportStatus === ExportStatus.InProgress}
                            cancel={() => setExportStatus(ExportStatus.Ready)}
                            ok={async () => {
                                setExportStatus(ExportStatus.InProgress);
                                try {
                                    const { data } = await DAService.v2.export(item.id);
                                    const { data: { pac_number } = {} as any } = data;
                                    patchCase(pac_number ? { pac_number } : { log: [data] });
                                } catch (error) {
                                    patchCase({ log: [(error as any).data ?? error] });
                                    console.error(error);
                                }
                                setExportStatus(ExportStatus.Ready);
                            }}
                        />
                    )}
                </div>
            </Col>
        );
    };

    const contentPopupConfirm = () => (
        <div className="flex justify-between items-center">
            <div className="text-base">Are you sure?</div>
            <div className="flex justify-between ml-2">
                <Button
                    onClick={handleTick}
                    size="small"
                    className="text-base ml-2 px-0 py-2 font-bold rounded-lg flex items-center text-popup-confirm text-popup-confirm-ok"
                    style={{ width: 50, height: 20 }}
                >
                    Yes
                </Button>
                <Button
                    danger
                    onClick={(event) => {
                        setOpen(false);
                        event.stopPropagation();
                    }}
                    size="small"
                    className="text-base ml-2 px-0 py-2 font-bold rounded-lg flex items-center text-popup-confirm"
                    style={{ width: 50, height: 20 }}
                >
                    No
                </Button>
            </div>
        </div>
    );

    const handleOpenChange = (newOpen: boolean) => {
        if (role === Role.SUPPER_ADMIN) return;
        setOpen(newOpen);
    };

    return (
        <StyledDashboardItem {...props}>
            <div className="absolute -top-3 left-4 flex space-x-3">
                <p className="bg-white rounded-xl px-2 border-[0.3px] border-[#5C5C5C] border-solid">{item.serial}</p>
                <ArchiveStatus onClick={() => handleRedirect(item.id)} type={item.status}>
                    {item.claim_status === APPROVED && item.serial.indexOf('-0') >= 0 ? APPROVED_RE_ISSUED_TEXT : CLAIM_STATUS_VALUE_MAP_TEXT[item.claim_status]}
                </ArchiveStatus>
                {stpResult ? <ComponentAround color={stpResult.color} background={stpResult.background} notShowBorder={true} >
                    {stpResult.text}
                </ComponentAround> : null}
            </div>
            <Row wrap={true}>
                <Col className="pt-2 relative" span={12} md={4}>
                    <div className="ml-5 mr-0">
                        <p className="m-0 mb-2" style={styles.name_patient}>
                            {item.patient_name ?? '-'}
                        </p>
                        <p className="m-0" style={styles.style_value}>
                            {item?.nric_no ?? '-'}
                        </p>
                    </div>
                </Col>
                <Col className="pt-2" span={12} md={4}>
                    <div className="mx-3">
                        <p className="m-0 mb-2" style={{ color: '#727C8A' }}>
                            Policy No.
                        </p>
                        <p className="m-0" style={styles.style_value}>
                            {item?.gtc_info_policy_no ?? '-'}
                        </p>
                        <p className="m-0" style={styles.style_value}>
                            {item?.gsh_info_policy_no ?? '-'}
                        </p>
                    </div>
                </Col>
                <Col className="pt-2" span={12} md={4}>
                    <div className="mx-3">
                        <p className="m-0 mb-2" style={{ color: '#727C8A' }}>
                            Dr.{' '}
                            <span style={styles.style_value} className="text-black">
                                {item.doctor_name ?? '-'}
                            </span>
                        </p>
                        <p className="m-0" style={{ color: '#727C8A' }}>
                            Amt.{' '}
                            <span style={styles.style_value} className="text-black">
                                {formatCurrency2(item.total_amount) ?? '-'}
                            </span>
                        </p>
                    </div>
                </Col>
                <Col className="pt-2" span={12} md={5}>
                    <div className="mx-1">
                        <p className="m-0 mb-2" style={{ color: '#727C8A' }}>
                            AdmD.{' '}
                            <span style={styles.style_value} className="text-black">
                                {item?.date_of_admission ? moment(item?.date_of_admission).format('DD/MM/YYYY') : '-'}
                            </span>
                        </p>
                        <p className="m-0 mb-2" style={{ color: '#727C8A' }}>
                            SbmD.{' '}
                            <span style={styles.style_value} className="text-black">
                                {item?.date_of_submission
                                    ? moment(item?.date_of_submission).format('DD/MM/YYYY HH:mm:ss')
                                    : '-'}
                            </span>
                        </p>
                    </div>
                </Col>
                <Col span={12} md={4} style={{ position: 'relative' }}>
                    {item.allocation_name && (
                        <div className="absolute -top-7 left-3 bg-white rounded-xl px-2 border-[0.3px] border-[#5C5C5C] border-solid">
                            {item.allocation_name}
                        </div>
                    )}

                    <div className="mx-3">
                        <p className="m-0 mb-2" style={{ color: '#727C8A' }}>
                            <span className={''}>Assigned </span>
                        </p>
                        <p className="m-0">
                            {item?.assessor?.full_name ?? '-'}{' '}
                            {item?.assessor?.role && (
                                <RoleComponent type={item?.assessor?.role}>{item?.assessor?.role}</RoleComponent>
                            )}
                        </p>
                    </div>
                </Col>
                {props.isArchive && renderActions()}
            </Row>
        </StyledDashboardItem>
    );
};

const ComponentAround = styled.div<{ color?: string, background?: string, notShowBorder?: boolean }>`
    height: 24px;
    border-radius: 12px;
    line-height: 24px;
    padding: 0 8px;
    background: ${(props: any) => (props.background ? `${props.background}` : '#fff')};
    text-align: center;
    border: ${(props: any) => (!props.notShowBorder ? `1` : '0')}px solid #aaaaac;
    color: ${(props: any) => (props.color ? `${props.color}` : '#101820')};
    border-color: ${(props: any) => (props.color ? `${props.color}` : '#aaaaac')};
`;

const CustomIcon = styled.img`
    cursor: pointer;
    padding: 5px;
`;

const StyledIcon = styled.img`
    cursor: pointer;
    width: 30px;
    height: 30px;
`;

const StyledDashboardItem = styled.div`
    position: relative;
    background-color: #fff;
    border-radius: 16px;
    padding: 16px 0;

    & div > p:first-child {
        line-height: 20px;
    }

    .disabled {
        background: #eeeeee;
        pointer-events: none;

        &:hover {
            cursor: default;
        }
    }

    .assigned {
        &:hover {
            cursor: pointer;
        }
    }

    .ant-row {
        .ant-col {
            &:last-child {
                padding-left: 0 !important;
            }
        }
    }
`;

export default ArchiveItem;

type ExportProps = {
    log?: { message: string; time: string }[];
    disabled?: boolean;
    pacNumber?: string;
};

const ExportButton = ({
    pacNumber,
    disabled,
    log,
    style,
    ...props
}: ExportProps & ComponentProps<typeof Pill>) => {
    if (pacNumber) delete props.onClick;
    let text = pacNumber || 'Transfer'
    let type = 'orange'

    if (!pacNumber) {
        if (log?.length) {
            text = 'Failed'
            type = 'red'
        }
        else type = disabled ? 'inactive' : 'tur'
    }

    return (
        <Pill
            {...props}
            style={{
                ...style,
                cursor: `${pacNumber ? 'default' : 'pointer'}`,
                display: 'flex',
                'justify-content': 'center',
                'max-width': '9rem',
                'white-space': 'nowrap',
                width: '100%',
            }}
            type={type}
        >
            {text}
        </Pill>
    );
};

const ExportDialog = (props: { inProgress: boolean; ok(): void; cancel(): void }) => {
    const modal: any = {
        onCancel: props.cancel,
        onOk: props.ok,
        closable: false,
        visible: true,
    };

    if (props.inProgress) modal.footer = null;

    return (
        <Modal {...modal}>
            <Dialog>{props.inProgress ? 'Transferring...' : 'Confirm Transfer'}</Dialog>
        </Modal>
    );
};

const Dialog = styled.div`
    font-size: 2em;
`;
