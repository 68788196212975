import moment from "moment";

export function floatify(number: number) {
  return parseFloat(number.toFixed(10));
}

export const getNameFromLink = (link: string) => {
  if (typeof link !== "string") return "";
  const baseName = decodeURIComponent(link).split("/").pop() as string;
  return baseName.replace(/^\d{13}_/, ''); // remove hash inserted by uploader
};

export const getContentTypeFile = (link: string) => {
  if (typeof link !== "string") return "";
  const ext = decodeURIComponent(link).split(".").pop() as string;
  return mapTypeFile[ext];
};

export const handleSum = (arr: Array<{ value: any; checked: boolean }>) => {
  if (!arr) return 0;
  return arr.reduce((sum, item) => {
    let currentItem = parseFloat(item.value) || 0;
    if (!item.checked) {
      currentItem = 0;
    }
    return currentItem + sum;
  }, 0);
};

export const getAgeFromBirthday = (birthday: string) => {
  if (!birthday) return;

  return moment().diff(moment(birthday, "DD/MM/YYYY"), "years");
};

export function formartMomentToTimestamp(date: string) {
  return Date.parse(date);
}

export const formatPhoneCode = (phone: string) => {
  if (!phone) return;
  let result: any = phone
    .replace(/\D/g, "")
    .match(/(\d{0,2})(\d{0,4})(\d{0,4})/);

  return "+" + result[1] + " " + result[2] + " " + result[3];
};

export const getFirstLetterOfName = (name: string) => {
  if (!name || typeof name !== "string") return "";
  const list = name.split(" ");
  return list.map((item) => item.slice(0, 1).toUpperCase()).join("");
};

export const mapTypeFile: {
  [key: string]: string;
} = {
  pdf: "application/pdf",
  jpeg: "image/jpeg",
  png: "image/png",
};
export const formatDate = (value: any, format: string, valNUll: string) => {
  if (!value) return valNUll;
  return moment(value).format(format);
};

export const formatPhone = (phone: string) => {
  if (phone) {
    const phones = phone.split("");
    return `${phones[0] || ""}${phones[1] || ""}${phones[2] || ""} ${
      phones[3] || ""
    }${phones[4] || ""}${phones[5] || ""}${phones[6] || ""} ${phones[7] || ""}${
      phones[8] || ""
    }${phones[9] || ""}${phones[10] || ""}`;
  }
  return "";
};

export const downloadFileBase64 = async (base64: string, name: string) => {
  var a = document.createElement("a"); //Create <a>
  a.href = "data:application/pdf;base64," + base64; //Image Base64 Goes here
  a.download = `${name}.pdf`; //File name Here
  a.click(); //Downloaded file
};

export const createFile = async (
  url: string,
  type = "image/jpeg",
  fileName = "file"
) => {
  const response = await fetch(url);
  const data = await response.blob();
  const metadata = {
    type,
  };
  const file = new File([data], `${fileName}.jpeg`, metadata);
  return file;
};

export const previewFile = (
  urlFile: string,
  type = "image/jpeg",
  name = "file"
) => {
  fetch(urlFile, {
    method: "GET",
    // headers: {
    //   "Content-Type": type,
    // },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob], { type: type }));
      const reader = new FileReader();
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      reader.readAsArrayBuffer(blob);
      link?.parentNode?.removeChild(link);
      window.open(url);
      console.log(url, link);
    });
};

export const dowloadFile = (
  urlFile: string,
  type = "image/jpeg",
  name = "file"
) => {
  let fetchInit: any = {
    method: "GET",
  };

  if (type) {
  fetchInit = {
      method: "GET",
      headers: {
        "Content-Type": type,
      },
    };
  }
  fetch(urlFile, fetchInit)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob], { type: type }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    });
};

export const generateRandomPass = () => {
  const alpha = "abcdefghijklmnopqrstuvwxyz";
  const calpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const num = "1234567890";
  const specials = "#$@!%&*";
  const options = [alpha, alpha, alpha, calpha, calpha, num, num, specials];
  let opt, choose;
  let pass = "";
  for (let i = 0; i < 8; i++) {
    opt = Math.floor(Math.random() * options.length);
    choose = Math.floor(Math.random() * options[opt].length);
    pass = pass + options[opt][choose];
    options.splice(opt, 1);
  }
  return pass;
};

export const customOtherFeesOrder = (otherFees: any) => {
  return {
    surgical_implants: otherFees?.surgical_implants || {},
    surgical_consumables: otherFees?.consumable || {},
    significant_medications: otherFees?.significant_medications || {},
    significant_investigations: otherFees?.significant_investigations || {},
    others: otherFees?.immunotherapies || {},
  };
};

export const passwordChecker = (password: string) => {
  return {
    number:
      !password ||
      !password.replace(/[^0-9]/g, "")?.toString() ||
      password === password.replace(/[^0-9]/g, "")?.toString(),
    mixed: !/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password),
    case:
      password.toUpperCase() === password ||
      password.toLowerCase() === password,
    minimum: password.length < 8,
  };
};

export function romanize(num: number) {
  let roman = "";
  let i = 3;
  if (isNaN(num)) return NaN;
  const digits = String(+num).split("");
  const key = [
    "",
    "C",
    "CC",
    "CCC",
    "CD",
    "D",
    "DC",
    "DCC",
    "DCCC",
    "CM",
    "",
    "X",
    "XX",
    "XXX",
    "XL",
    "L",
    "LX",
    "LXX",
    "LXXX",
    "XC",
    "",
    "I",
    "II",
    "III",
    "IV",
    "V",
    "VI",
    "VII",
    "VIII",
    "IX",
  ];
  while (i--) roman = (key[+(digits?.pop() ?? 0) + i * 10] || "") + roman;
  return Array(+digits.join("") + 1).join("M") + roman;
}

export const isOpenSocket = (socket: any) => {
  return socket.readyState === socket.OPEN;
};
