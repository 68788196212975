import { RootState } from "../../store/index";
import { createAction, createSlice } from "@reduxjs/toolkit";

interface UserData {
  id: string;
  username: string;
  attributes: any;
  accessToken: string;
  idToken: string;
  refreshToken: string;
  full_name: string;
  group: string;
  permission: any;
  role: "SupperAdmin" | "Assessor" | "Supervisor" | "Master" | "Customer_Service" | "Provider_Admin"
}
export interface AuthState {
  user?: UserData;
}

export interface PermissionData {
  allocation_execute: boolean;
  allocation_view: boolean;
  analytics_execute: boolean;
  analytics_view: boolean;
  archive_execute: boolean;
  archive_view: boolean;
  comment_case: boolean;
  configuration_execute: boolean;
  configuration_view: boolean;
  edit_case: boolean;
  hospital_info_execute: boolean;
  holiday_view: boolean;
  patient_execute: boolean;
  patient_view: boolean;
  policy_execute: boolean;
  policy_view: boolean;
  provider_execute: boolean;
  provider_view: boolean;
  team_execute: boolean;
  team_view: boolean;
  doctor_provider_view: boolean;
  doctor_provider_execute: boolean;
}

const initialState: AuthState = {};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const logout = createAction("auth/logout");

export const { setUserInfo } = authSlice.actions;

export const selectUserSelector = (state: RootState) => state?.auth?.user;

export default authSlice.reducer;
