import React from "react";
import styled from "styled-components";
import { Checkbox } from "antd";

export const Container = styled.div`
  display: flex;
`;
export const Text = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1b1b1b;
  margin-left: 16px;
`;

export const Checker = styled(Checkbox)`
  & .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border-radius: 4px !important;
  }

  & .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #00bce8;
  }

  & .ant-checkbox-inner::after {
    left: 5px !important;
  }

  & .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #E9EEF6;
  }

  & .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #bdc3cb;
  }

  & .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #ffffff;
  }
`;
