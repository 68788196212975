import { Avatar, Col, Dropdown, Menu, PageHeader, Row, Badge } from "antd";
import Status from "components/Common/Status";
import {
  CLAIM_STATUS_COLOR,
  CLAIM_STATUS_VALUE_MAP_TEXT,
} from "../../constants";
import { logout, selectUserSelector } from "containers/Auth/authSlice";
import Provider from "containers/Provider";
import { Logout, Notification, User } from "iconsax-react";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import DAService from "services/DAService";
import ProviderService from "services/ProviderService";
import styled from "styled-components";
import { DotIcon } from "components/icons/dotIcon";
import useNotifications from "hooks/useNotifications";

interface IProps {
  title?: any;
}

const HeaderRight = () => {
  const user = useSelector(selectUserSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const { notifications, setNotifications } = useNotifications();
  const handleGetFeedData = async (limit: number, page: number = 1) => {
    setLimit(limit);
    try {
      const { data } = await ProviderService.home.getListNew(limit, page);
      setNotifications({
        data:data?.data?.items,
        unreadNumber:data?.data?.unread,
        total: data?.data?.page_info?.total,
        isOpened: false
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleReadNotify = async (feedId: string) => {
    try {
      const { data } = await ProviderService.home.updateNew(feedId, {
        is_read: true,
      });
      if (data?.code == 200) {
        setTimeout(() => {
          handleGetFeedData(limit, 1);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickNotify = (
    caseId: string,
    feedId: string,
    isRead: boolean
  ) => {
    if (!isRead) handleReadNotify(feedId);
    navigate(`/assessment/claim/${caseId}`);
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setNotifications({
        ...notifications,
        isOpened: true
      });
    }
  };

  const handleReadMoreNotify = () => {
    let paramLimit: number = limit + 10;
    handleGetFeedData(paramLimit, 1);
  };

  useEffect(() => {
    handleGetFeedData(10, 1);
  }, []);

  return (
    <div className="flex items-center header-wraper">
      <Dropdown
        placement="bottomRight"
        trigger={["click"]}
        onOpenChange={onOpenChange}
        overlay={
          <Menu
            style={{
              minWidth: 180,
              maxWidth: 600,
              maxHeight: "80vh",
              padding: 0,
              overflowX: "hidden",
              overflowY: "scroll",
            }}
          >
            {notifications?.data?.length > 0 &&
              notifications?.data.map((item: any, key) => (
                <Menu.Item
                  key={key}
                  className={!item?.is_read ? "notify-new-read" : ""}
                  onClick={() =>
                    handleClickNotify(item?.case_id, item?.id, item?.is_read)
                  }
                >
                  <Row gutter={24}>
                    <Col
                      span={17}
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "#101820 ",
                        lineHeight: "24px",
                      }}
                    >
                      <span style={{ textTransform: "capitalize" }}>
                        {item?.patient_name?.toLowerCase()}
                      </span>
                      &nbsp;- Case no. {item?.case_serial}
                    </Col>
                    <Col span={7} className={"notify-text-right notify-time"}>
                      {moment(item.createdAt).format("DD-MMM-YYYY HH:mm")}
                    </Col>
                    <Col span={22} className={"notify-status"}>
                      <StyledStatus
                        theme={{
                          color: "#2AC3FF",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                          background: "#E4F3FC",
                        }}
                        className={CLAIM_STATUS_COLOR[item?.case_claim_status]}
                      >
                        {CLAIM_STATUS_VALUE_MAP_TEXT[item?.case_claim_status]}
                      </StyledStatus>
                    </Col>
                    <Col span={2} className={"notify-text-right notify-new"}>
                      {!item?.is_read && <DotIcon type="Assessor" />}
                    </Col>
                  </Row>
                </Menu.Item>
              ))}
            {notifications?.data?.length > 0 && notifications?.data?.length < notifications?.total && (
              <Menu.Item className={"show-more-notifications"}>
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                    handleReadMoreNotify();
                  }}
                >
                  Show more...
                </div>
              </Menu.Item>
            )}
          </Menu>
        }
      >
        <Badge count={notifications?.unreadNumber} color="#dd2d12">
          <StyledAvatar
            className="cursor-pointer"
            size={40}
            style={{ margin: 0 }}
            icon={<Notification />}
          />
        </Badge>
      </Dropdown>
      <span className="mr-5 ml-5">
        <b>{user?.attributes.nickname || user?.full_name}</b>
      </span>
      <Dropdown
        placement="bottomRight"
        trigger={["click"]}
        overlay={
          <Menu style={{ minWidth: 180, padding: 5 }}>
            <StyledMenuItem className="text-[16px] mb-5" icon={<User />}>
              <Link to="/users/profile">Profile</Link>
            </StyledMenuItem>
            <StyledMenuItem
              className="text-[16px]"
              icon={<Logout color="#F04646" />}
            >
              <a
                href="#"
                style={{ color: "#F04646" }}
                onClick={() => dispatch(logout())}
              >
                Logout
              </a>
            </StyledMenuItem>
          </Menu>
        }
      >
        <Avatar size={40} src={user?.attributes.picture} />
      </Dropdown>
    </div>
  );
};

const Header = ({ title = "Home" }: IProps) => {
  return <StyledHeader title={title} extra={<HeaderRight />} />;
};

export default Header;

const StyledHeader = styled(PageHeader)`
  padding-top: 24px;
  padding-left: 0;
  padding-bottom: 0;
  color: #101820;

  .ant-page-header-heading-title {
    font-size: 30px;
  }
`;

const StyledAvatar = styled(Avatar)`
  display: flex;
  background-color: #fff;
  color: #000;
  justify-content: center;
  align-items: center;
`;

const StyledMenuItem = styled(Menu.Item)`
  margin: 5px 0px;
  font-size: 16px;
`;

export const StyledStatus = styled.div`
  padding: 2px 10px;
  height: 28px;
  border-radius: 40px;
  width: fit-content;
  background: ${(props) => props.theme.background};
  font-weight: ${(props) => props.theme.fontWeight} !important;
  font-size: ${(props) => props.theme.fontSize} !important;
  color: ${(props) => props.theme.color};
  line-height: ${(props) => props.theme.lineHeight} !important;
  &.green {
    color: #7ec820;
    background-color: #eff8e5;
  }
  &.orange {
    color: #fe6b00;
    background-color: #fff0e6;
  }
  &.blue {
    color: #01a2ff;
    background-color: #f3f8fc;
  }
  &.yellow {
    color: #f0ad02;
    background-color: #fff8e6;
  }
  &.purple {
    color: #6a57fc;
    background-color: #f5f4ff;
  }
  &.mintt {
    color: #009b95;
    background-color: #ebfaf9;
  }
  &.pink {
    color: #f3007d;
    background-color: #ffedfa;
  }
  &.dark-pink {
    color: #9b0080;
    background-color: #ffe5fb;
  }
  &.tur {
    color: #03b4ce;
    background-color: #edfbfc;
  }
  &.red {
    color: #f04646;
    background-color: #ffeeee;
  }
`;
