import styled from "styled-components";
import * as antd from "antd";

export const AddMore = styled(antd.Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 32px;
    width: 194px;
    height: 64px;
    background: #55BED2;
    border: 1px solid #55BED2;
    box-sizing: border-box;
    border-radius: 24px;
    margin: 16px 0px;

    &:hover, &:focus {
        background: #55BED2;
        border: 1px solid #55BED2;
    }

    & span {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
        margin: 0px 10px;
    }
`;
export const AddIcon = (props: any) => (
    <div {...props}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.0022 22.7483C17.6022 22.7483 16.2922 22.1383 15.3922 21.0683C15.3822 21.0483 15.3222 20.9983 15.2722 20.9183C15.1622 20.8083 15.0322 20.6083 14.9222 20.4183C14.4822 19.7083 14.2422 18.8683 14.2422 17.9883C14.2422 16.5283 14.8922 15.1783 16.0222 14.2783C16.8622 13.6083 17.9222 13.2383 18.9922 13.2383C20.1522 13.2383 21.2222 13.6383 22.0822 14.3883C22.2022 14.4683 22.3422 14.6183 22.4722 14.7583C23.2822 15.6483 23.7322 16.7883 23.7322 17.9783C23.7322 18.8483 23.4922 19.6983 23.0422 20.4283C22.8022 20.8483 22.4722 21.2283 22.0922 21.5483C21.2822 22.3283 20.1722 22.7483 19.0022 22.7483ZM19.0022 14.7483C18.2622 14.7483 17.5622 14.9983 16.9722 15.4683C16.2022 16.0783 15.7522 17.0083 15.7522 17.9983C15.7522 18.5883 15.9122 19.1683 16.2222 19.6683C16.3022 19.8083 16.3822 19.9183 16.4722 20.0283C16.4922 20.0483 16.5522 20.1083 16.6022 20.1883C17.1522 20.8383 18.0522 21.2583 19.0022 21.2583C19.7922 21.2583 20.5522 20.9683 21.1322 20.4483C21.3922 20.2283 21.6122 19.9683 21.7722 19.6883C22.0922 19.1783 22.2522 18.5983 22.2522 18.0083C22.2522 17.1983 21.9422 16.4083 21.3822 15.7983C21.3122 15.7183 21.2322 15.6383 21.1522 15.5783C20.5222 15.0183 19.7922 14.7483 19.0022 14.7483Z"
                fill={props.color || "white"}/>
            <path
                d="M20.49 18.7305H17.5C17.09 18.7305 16.75 18.3905 16.75 17.9805C16.75 17.5705 17.09 17.2305 17.5 17.2305H20.49C20.9 17.2305 21.24 17.5705 21.24 17.9805C21.24 18.3905 20.91 18.7305 20.49 18.7305Z"
                fill={props.color || "white"}/>
            <path
                d="M19 20.2595C18.59 20.2595 18.25 19.9195 18.25 19.5095V16.5195C18.25 16.1095 18.59 15.7695 19 15.7695C19.41 15.7695 19.75 16.1095 19.75 16.5195V19.5095C19.75 19.9295 19.41 20.2595 19 20.2595Z"
                fill={props.color || "white"}/>
            <path
                d="M12.0013 13.3008C11.8713 13.3008 11.7413 13.2709 11.6213 13.2009L2.79129 8.09088C2.43129 7.88088 2.3113 7.42085 2.5213 7.06085C2.7313 6.70085 3.19129 6.58083 3.54129 6.79083L11.9913 11.6808L20.3913 6.82086C20.7513 6.61086 21.2113 6.74088 21.4113 7.09088C21.6213 7.45088 21.4913 7.91085 21.1413 8.12085L12.3713 13.2009C12.2613 13.2609 12.1313 13.3008 12.0013 13.3008Z"
                fill={props.color || "white"}/>
            <path
                d="M12 22.3591C11.59 22.3591 11.25 22.0191 11.25 21.6091V12.5391C11.25 12.1291 11.59 11.7891 12 11.7891C12.41 11.7891 12.75 12.1291 12.75 12.5391V21.6091C12.75 22.0191 12.41 22.3591 12 22.3591Z"
                fill={props.color || "white"}/>
            <path
                d="M11.9989 22.7519C11.1189 22.7519 10.2389 22.5619 9.5589 22.1719L4.2189 19.2119C2.7689 18.4119 1.62891 16.4819 1.62891 14.8219V9.16185C1.62891 7.50185 2.7689 5.5819 4.2189 4.7719L9.5589 1.81187C10.9189 1.04188 13.0589 1.04188 14.4289 1.81187L19.7689 4.7719C21.2189 5.5719 22.3589 7.50185 22.3589 9.16185V14.8219C22.3589 14.9219 22.3589 15.0019 22.3389 15.1019C22.2889 15.3619 22.0989 15.5819 21.8489 15.6619C21.5989 15.7519 21.3189 15.6919 21.1089 15.5219C19.9589 14.5219 18.1789 14.4819 16.9689 15.4519C16.1989 16.0619 15.7489 16.9919 15.7489 17.9819C15.7489 18.5719 15.9089 19.1519 16.2189 19.6519C16.2989 19.7919 16.3789 19.9019 16.4689 20.0119C16.6189 20.1819 16.6789 20.4119 16.6389 20.6319C16.5989 20.8519 16.4589 21.0419 16.2589 21.1519L14.4289 22.1618C13.7489 22.5618 12.8789 22.7519 11.9989 22.7519ZM11.9989 2.75188C11.3789 2.75188 10.7489 2.88188 10.2989 3.13188L4.95891 6.0919C3.98891 6.6219 3.1489 8.06185 3.1489 9.16185V14.8219C3.1489 15.9219 3.99891 17.3619 4.95891 17.8919L10.2989 20.8519C11.2089 21.3619 12.7989 21.3619 13.7089 20.8519L14.8289 20.2319C14.4589 19.5619 14.2589 18.7819 14.2589 17.9819C14.2589 16.5219 14.9089 15.1719 16.0389 14.2719C17.3989 13.1819 19.3489 12.9519 20.8689 13.6019V9.14189C20.8689 8.04189 20.0189 6.60188 19.0589 6.07188L13.7189 3.11186C13.2489 2.88186 12.6189 2.75188 11.9989 2.75188Z"
                fill={props.color || "white"}/>
        </svg>
    </div>
)
export const HighlightText = styled.p`
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #c9372c;
    margin: 30px 0;
`;