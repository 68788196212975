import React from "react";
import styled from "styled-components";
import { Button, Modal } from "antd";

export const UploadButton = styled(Button)`
width: 194px;
height: 64px;
background: linear-gradient(45, #55bed2, #70eaf0);
box-sizing: border-box;
border-radius: 50px;
display: flex;
align-items: center;
justify-content: center;
& > span {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

& img {
  width: 24px;
  margin-right: 12px;
}
`;

export const StyledModal = styled(Modal)`
    .ant - modal - title {
    text - align: center;
    font - weight: 600;
    font - size: 24px;
    line - height: 36px;
    color: #22313F;
}
    .ant - modal - header {
    border - radius: 24px;
}
    .ant - modal - header, .__upload_file_modal.ant - modal - footer {
    border - bottom: none!important;
    border - top: none!important;
}
    .ant - modal - content {
    width: 600px;
    background: #FFFFFF;
    box - shadow: 0px 4px 30px rgba(0, 149, 153, 0.1);
    border - radius: 24px;
}
    .ant - modal - footer {
    text - align: center;
    padding - bottom: 33px;
}
    .ant - modal - footer button {
    padding: 20px 32px;
    width: 194px;
    height: 64px;
    background: #55BED2;
    border: 1px solid #55BED2;
    box - sizing: border - box;
    border - radius: 24px;

    font - weight: 400;
    font - size: 16px;
    line - height: 24px;
    text - align: center;
    color: #FFFFFF;
}
`
