import React from "react";
import styled from "styled-components";

interface IProps {
  type: "active" | "inactive" | "info" | "warning" | "red" | "tur" | "pink" | "dark-pink" | "mintt" | "purple" | "yellow" | "blue" | "orange" | "green";
  children: React.ReactNode;
  onClick?: any;
}

const getColor = (type: string) => {
  switch (type) {
    case "active":
      return {
        color: "#7EC820",
        backgroundColor: "#EFF8E5",
      };
    case "inactive":
      return {
        color: "#F04646",
        backgroundColor: "#FFEEEE",
      };
    case "info":
      return {
        color: "#2AC3FF",
        backgroundColor: "#E4F3FC",
      };
    case "warning":
      return {
        color: "#FE8833",
        backgroundColor: "#FFF0E6",
      };
    case "green":
      return {
        color: "#7EC820",
        backgroundColor: "#EFF8E5",
      };
    case "orange":
      return {
        color: "#FE6B00",
        backgroundColor: "#FFF0E6",
      };
    case "blue":
      return {
        color: "#01A2FF",
        backgroundColor: "#F3F8FC",
      };
    case "yellow":
      return {
        color: "#F0AD02",
        backgroundColor: "#FFF8E6",
      };
    case "purple":
      return {
        color: "#6A57FC",
        backgroundColor: "#F5F4FF",
      };
    case "mintt":
      return {
        color: "#009B95",
        backgroundColor: "#EBFAF9",
      };
    case "pink":
      return {
        color: "#F3007D",
        backgroundColor: "#FFEDFA",
      };
    case "dark-pink":
      return {
        color: "#9B0080",
        backgroundColor: "#FFE5FB",
      };
    case "tur":
      return {
        color: "#03B4CE",
        backgroundColor: "#EDFBFC",
      };
    case "red":
      return {
        color: "#F04646",
        backgroundColor: "#FFEEEE",
      };
    default:
      return {};
  }
};

const ArchiveStatus = ({type, children, onClick}: IProps) => {
  let color = getColor(type)?.color;
  let bgColor = getColor(type)?.backgroundColor;

  return <p
    onClick={onClick}
    style={{backgroundColor:bgColor, color, borderColor:color, cursor: 'pointer'}}
    className={`rounded-xl px-2 border-[0.3px] border-solid`}
  >
    {children}
  </p>
}

export const Pill = styled.span((props: {
  type: "active" | "inactive" | "info" | "warning" | "red" | "tur" | "pink" | "dark-pink" | "mintt" | "purple" | "yellow" | "blue" | "orange" | "green";
}) => {
  const { color } = getColor(props.type);
  const { backgroundColor } = getColor(props.type);
  
  return `
    color: ${color};
    padding: 0 .5em !important;
    border: 1px solid ${color};
    background-color: ${backgroundColor};
    border-radius: 100vh;
    display: inline-block;
    position: relative;
  `
});

export default ArchiveStatus;
