import React from "react";
import styled from "styled-components";
import { DotIcon } from "../icons/dotIcon";

interface IProps {
  type: "Master" | "Doctor" | "Assessor";
  children: React.ReactNode;
}

const getColor = (type: string) => {
  switch (type) {
    case "Master":
      return {
        color: "#62AC03",
      };
    case "Doctor":
      return {
        color: "#FE6B00",
      };
    case "Assessor":
      return {
        color: "#01A2FF",
      };
    case "Patient":
      return {
        color: "#6A57FC",
      };
    case "Supervisor":
      return {
        color: "#F0AD02",
      };
    default:
      return {
        color: "#727C8A",
      };
  }
};

const Role = ({ type, children }: IProps) => {
  return <StyledRole type={type}><DotIcon type={type}/> {children}</StyledRole>;
};

const StyledRole = styled.span<{
  type: "Master" | "Doctor" | "Assessor";
}>`
  color: ${(prop) => getColor(prop.type)?.color};
  padding: 4px;
  border-radius: 15px;
  width: 100%;
`;

export default Role;
