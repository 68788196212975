import { BaseService } from "services";
import { SERVICES } from "../constants";

const baseService = new BaseService(SERVICES.PROVIDER_SERVICE_URL);
const baseServiceUpdate = new BaseService(SERVICES.PROVIDER_SERVICE_URL_UPDATE);


export default {
  doctor: {
    getList: (
      searchParam: any | null,
      limit: number = 10,
      nextToken: string | null = null,
      load_pac?: boolean,
      active?: boolean
    ) =>
      baseService.instance.get("/doctors", {
        params: {
          ...(!!searchParam && { ...searchParam }),
          limit,
          nextToken,
          ...(!!load_pac && { load_pac }),
          ...(!!active && { active })
        },
      }),
    invite: (data: any) => baseService.instance.post("/doctors/invite", data),
    doctorGetList: (
      id: string, 
      name: string | null, 
      limit: number = 50, 
      nextToken: string | null = null
    ) => baseService.instance.get(`/doctors/${id}/provider-doctor`, {
      params: {
        ...(!!name && { name }),
        limit,
        nextToken,
      },
    }),
    doctorPutList: (id: string, data: any) => baseService.instance.put(`/doctors/${id}/provider-doctor`, data),
    getClinicByCode: (clinicCode: string) => baseService.instance.get(`/doctors/clinic-code/${clinicCode}`),
    searchClinicByCode: (clinicCode: string) => baseService.instance.get(`/doctors/search-clinic/${clinicCode}`),
    detail: (id: string) => baseService.instance.get(`/doctors/${id}`),
    update: (id: string, data: any) =>
    baseServiceUpdate.instance.put(`/doctors/${id}`, data),
    resend: (data: any) => baseService.instance.post("/doctors/resend", data),
    delete: (id: any) => baseService.instance.delete(`/doctors/${id}`),
  },
  assessor: {
    getDoctorPACFormA: (id: string) => baseService.instance.get(`/pac/formA/${id}`),
    getDoctorPACFormB: (id: string) => baseService.instance.get(`/pac/formB/${id}`),
    getDoctorPACFormC: (id: string) => baseService.instance.get(`/pac/formC/${id}`),
  },
  home: {
    getListNew: (limit: number = 50, page: number = 1) => baseService.instance.get(`/feed/claim-team`, {
      params: {
        ...(!!limit && { limit }),
        ...(!!page && { page }),
      },
    }),
    updateNew: (id:string, data:any) => baseService.instance.put(`/feed/${id}`,{...data})
  }
};
