import styled from "styled-components";
import { Layout, Menu } from "antd";

export const StyledLayout = styled(Layout)`
  position: relative;
  min-height: 100%;
  background: transparent;
  transition: all 0.2s;
  .content-noheader {
    padding-top: 24px;
    >div {
      background: rgba(255, 255, 255, 0.7)
    }
  }
`;

export const StyledContent = styled(Layout.Content)`
  padding: 20px;
  padding-left: 0;
`;

export const StyledSider = styled(Layout.Sider)`
  position: fixed;
  margin: 0 24px;
  border-radius: 16px;
  padding: 10px;
  box-shadow: 0px 4px 30px rgba(0, 149, 153, 0.1);
  padding-bottom: 40px;
  top: 24px;
  bottom: 24px;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }

  .logo {
    display: flex;
    height: 54px;
    margin: 10px;
    align-items: center;
    justify-content: center;
  }

  .ant-menu-item-selected {
    border-radius: 16px;

    &::after {
      display: none;
    }
  }

  .custom-menu-item {
    .ant-menu-title-content {
      margin-left: 10px;
    }
    &.ant-menu-item-selected, &:hover {
      svg path {
       fill: #00c1d5;
      }
    }
  }

  .ant-menu-vertical {
    border-right: 0;
  }

  .ant-menu-inline {
    border-right: 0;

    & .ant-menu-item {
      height: 48px;
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  .ant-menu-inline-collapsed {
    & > .ant-menu-item {
      padding: 0 18px;
    }
    .custom-menu-item {
      .ant-menu-title-content {
        opacity: 0;
      }
      svg {
        display: inline-block;
      }
    }
  }
`;

export const StyledScroll = styled.div`
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
`;

export const StyledCollapsed = styled.button`
  display: flex;
  position: absolute;
  width: 100%;
  height: 40px;
  padding: 5px;
  bottom: 0;
  left: 0;
  justify-content: center;
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;