import {
  APPROVED,
  ASSESSMENT_IN_PROGRESS,
  ASSESSOR_TRIGGERED_PAC,
  CANCELLED,
  CLAIMS_STATUS_APPROVAL_REJECT,
  CLAIM_STATUS,
  CLAIM_STATUS_ASSESSOR_LEVEL2,
  CLAIM_STATUS_ASSESSOR_LEVEL3,
  CLAIM_STATUS_MASTER,
  CLAIM_STATUS_MASTER_LEVEL3,
  CLARIFICATION_REQUIRED,
  CancellationReason,
  PAC_INITIATED_DOCTOR,
  PAC_INITIATED_PATIENT,
  PATIENT_SUBMITTED_PAC,
  PENDING_GE_APPROVAL,
  PENDING_MASTER_ADVICE,
  PENDING_MASTER_APPROVAL,
  PENDING_SUPERVISOR_APPROVAL,
  REISSUED,
  REJECTED,
  Role,
} from "../constants";
import { useEffect, useState } from "react";

type Props = {
  claimStatus: any;
  user: any;
  role?: string;
  isHistory?: boolean;
  approvalLevel?: number;
};

export const usePermissionClaim = ({
  approvalLevel,
  claimStatus,
  user,
  isHistory = false,
}: Props) => {
  const [state, setState] = useState({
    isBlockEdit: true,
    isBlockRemarks: true,
    isComment: false,
    isShowSubmit: false,
    availableActions: Array()
  });

  useEffect(() => {
    const userId = user?.id || user?.attributes?.sub;
    const assignedToCurrentUser = claimStatus.current_assign_id == userId;
    const availableActions = approvalLevel === undefined ? [] :
      getAvailableActions(user?.role, approvalLevel, claimStatus.valueOrigin)
    
    let dataState = { ...state, availableActions, isShowSubmit: true };
    
    if (claimStatus.current_assign_id !== userId ||
      [ PAC_INITIATED_PATIENT, PAC_INITIATED_DOCTOR ].includes(claimStatus.valueOrigin)
    ) {
      dataState = {
        isBlockEdit: true,
        isBlockRemarks: true,
        isComment: false,
        isShowSubmit: false,
        availableActions: []
      };
    } else {
      switch (user?.role) {
        case Role.MASTER:
        case Role.SUPERVISOR:
          if (
            claimStatus.value === PENDING_MASTER_APPROVAL ||
            claimStatus.value === PENDING_SUPERVISOR_APPROVAL
          ) {
            dataState = {
              ...dataState,
              availableActions,
              isShowSubmit: false,
            };
          } else {
            dataState = {
              ...dataState,
              availableActions,
              isShowSubmit: true,
            };
          }

          switch (claimStatus.valueOrigin) {
            case APPROVED:
            case REISSUED:
            case REJECTED:
            case CANCELLED:
            case ASSESSMENT_IN_PROGRESS:
            case CLARIFICATION_REQUIRED:
              dataState = {
                ...dataState,
                isBlockEdit: true,
                isComment: false,
                isShowSubmit: false,
                availableActions: [],
              };
              break;
            default:
              dataState = {
                ...dataState,
                isBlockEdit: !user?.permission?.edit_case,
                isComment: user?.permission?.comment_case,
              };
              break;
          }
          break;
        case Role.ASSESSOR:
          if (claimStatus.value === ASSESSMENT_IN_PROGRESS) {
            dataState = {
              ...dataState,
              availableActions,
              isShowSubmit: false,
            };
          } else if (claimStatus.value === "ASSESSOR_SUBMITTED_PROVIDER_FORM") {
            dataState = {
              ...dataState,
              availableActions,
              isShowSubmit: false,
            };
          } else {
            // need change here
            dataState = {
              ...dataState,
              availableActions,
              isShowSubmit: true,
            };
          }
          switch (claimStatus.valueOrigin) {
            case APPROVED:
            case REISSUED:
            case REJECTED:
            case CANCELLED:
            case PENDING_MASTER_APPROVAL:
            case PENDING_SUPERVISOR_APPROVAL:
            case PENDING_GE_APPROVAL:
            case PENDING_MASTER_ADVICE:
            case CLARIFICATION_REQUIRED:
              dataState = {
                ...dataState,
                isBlockEdit: true,
                isComment: false,
                isShowSubmit: false,
                availableActions: [],
              };
              break;
            default:
              dataState = {
                ...dataState,
                isBlockEdit: !user?.permission?.edit_case,
                isComment: user?.permission?.comment_case,
              };
              break;
          }
          break;
        default:
          dataState = {
            ...dataState,
            isBlockEdit: true,
            isBlockRemarks: true,
            isComment: false,
            isShowSubmit: true,
            availableActions: [],
          };
          break;
      }

      if (claimStatus.value === PENDING_MASTER_APPROVAL 
        || claimStatus.value === PENDING_SUPERVISOR_APPROVAL 
        || claimStatus.value === ASSESSMENT_IN_PROGRESS) {

          dataState = {
            ...dataState,
            isBlockRemarks: false
          };
      }
    }

    if (
      user?.role === Role.MASTER &&
      claimStatus.valueOrigin !== REJECTED &&
      claimStatus.valueOrigin !== PAC_INITIATED_DOCTOR &&
      claimStatus.valueOrigin !== PAC_INITIATED_PATIENT &&
      claimStatus.valueOrigin !== APPROVED &&
      claimStatus.valueOrigin !== REISSUED
    ) {
      dataState = { ...dataState, isComment: true };
    }

    if (
      user?.role === Role.CUSTOMER_SERVICE &&
      claimStatus.valueOrigin !== REJECTED &&
      claimStatus.valueOrigin !== PAC_INITIATED_DOCTOR &&
      claimStatus.valueOrigin !== PAC_INITIATED_PATIENT &&
      claimStatus.valueOrigin !== APPROVED && 
      claimStatus.valueOrigin !== REISSUED
    ) {
      dataState = { ...dataState, isComment: true };
    }

    if (
      user?.role === Role.PROVIDER_ADMIN &&
      claimStatus.valueOrigin !== REJECTED &&
      claimStatus.valueOrigin !== PAC_INITIATED_DOCTOR &&
      claimStatus.valueOrigin !== PAC_INITIATED_PATIENT &&
      claimStatus.valueOrigin !== APPROVED && 
      claimStatus.valueOrigin !== REISSUED
    ) {
      dataState = { ...dataState, isComment: true };
    }
    if (isHistory) {
      dataState = {
        isBlockEdit: true,
        isBlockRemarks: true,
        isComment: false,
        isShowSubmit: false,
        availableActions: [],
      };
    }

    if (
      (user?.role === Role.ASSESSOR &&
        (((claimStatus?.valueOrigin ===  PENDING_GE_APPROVAL ||
          claimStatus?.valueOrigin ===  PENDING_MASTER_ADVICE ||
          claimStatus?.valueOrigin ===  PENDING_MASTER_APPROVAL) && assignedToCurrentUser) ||
          claimStatus?.valueOrigin === CLARIFICATION_REQUIRED ||
          claimStatus?.valueOrigin === ASSESSOR_TRIGGERED_PAC ||
          claimStatus?.valueOrigin === PATIENT_SUBMITTED_PAC)) ||
      (user?.role === Role.SUPERVISOR &&
        (claimStatus?.valueOrigin === PAC_INITIATED_PATIENT ||
          claimStatus?.valueOrigin === PAC_INITIATED_DOCTOR ||
          claimStatus?.valueOrigin === ASSESSOR_TRIGGERED_PAC ||
          claimStatus?.valueOrigin === PATIENT_SUBMITTED_PAC ||
          claimStatus?.valueOrigin === CLARIFICATION_REQUIRED))
    ) {
      dataState = {
        ...dataState,
        isShowSubmit: true,
        availableActions,
      };
    }

    const shouldAllowCSCancel =
        user?.role === Role.CUSTOMER_SERVICE &&
        [PAC_INITIATED_PATIENT, PAC_INITIATED_DOCTOR, CLARIFICATION_REQUIRED, ASSESSOR_TRIGGERED_PAC].includes(claimStatus.valueOrigin);
      const shouldAllowCancelCase = ![Role.PROVIDER_ADMIN, Role.CUSTOMER_SERVICE].includes(user?.role) ||
          [PAC_INITIATED_PATIENT, PAC_INITIATED_DOCTOR, CLARIFICATION_REQUIRED].includes(claimStatus.valueOrigin);

    if (shouldAllowCancelCase || shouldAllowCSCancel) {
        dataState.availableActions.push(...CANCEL_ACTIONS);
    }
    
    if ([ APPROVED, REISSUED, REJECTED, CANCELLED ].includes(claimStatus.valueOrigin))
      dataState.availableActions = []
    if (claimStatus.valueOrigin != claimStatus.value)
      dataState.isShowSubmit = true

    setState({ ...dataState });
  }, [claimStatus, user, approvalLevel]);

  return state
};

function getAvailableActions(role: Role, approvalLevel: number, originalStatus: string) {
  switch (role) {
    case Role.MASTER:
      return approvalLevel === 3
        ? [...CLAIM_STATUS_MASTER_LEVEL3]
        : [...CLAIM_STATUS_MASTER];

    case Role.ASSESSOR:
    case Role.SUPERVISOR:
      if(originalStatus === CLARIFICATION_REQUIRED) return [
        {
          value: ASSESSMENT_IN_PROGRESS,
          text: "Assessment in Progress",
        }
      ];

      switch (approvalLevel) {
        case 1:
        case 2:
          return [
            ...CLAIM_STATUS,
            ...CLAIM_STATUS_ASSESSOR_LEVEL2,
            ...CLAIMS_STATUS_APPROVAL_REJECT,
          ];
        case 3:
          return [
            ...CLAIM_STATUS,
            ...CLAIM_STATUS_ASSESSOR_LEVEL2,
            ...CLAIM_STATUS_ASSESSOR_LEVEL3,
            ...CLAIMS_STATUS_APPROVAL_REJECT,
          ];
        default:
          return [...CLAIM_STATUS, ...CLAIM_STATUS_ASSESSOR_LEVEL2];
      }
  }

  return [];
}

const CANCEL_ACTIONS = Object
  .entries(CancellationReason)
  .map(([ value, label ]) =>
    ({ value, text: "Cancelled - " + label })
  );